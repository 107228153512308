<template>
  <validation-form ref="form"
                   :key="currentItem.id"
                   v-slot="{ errors, isSubmitting }"
                   class="sidepanel__content"
                   :validation-schema="schemas[activeStep]"
                   @keydown.enter.prevent
                   @submit="validateComponent('submit')"
                   @invalid-submit="onInvalidSubmit">
    <div id="sidepanel-wrapper"
         ref="scrollWrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <h2 class="panel-title">
          {{ $t('sidepanel.card.order.title') }} {{ index + 1 }}/{{ cardOrders.length }}
        </h2>
      </div>
      <div class="sidepanel-body section-card-order">
        <ul class="nav">
          <li class="nav__item">
            <button class="nav__link"
                    :class="{ active: activeStep === 0 }"
                    type="button"
                    @click="validateComponent('toggle-step', 0)">
              {{ $t('order_card.step.owner_infos') }}
            </button>
          </li>
          <li class="nav__item">
            <button class="nav__link"
                    :class="{ active: activeStep === 1 }"
                    type="button"
                    @click="validateComponent('toggle-step', 1)">
              {{ $t('order_card.step.card_settings') }}
            </button>
          </li>
          <li v-if="isPhysicalCard"
              class="nav__item">
            <button class="nav__link"
                    :class="{ active: activeStep === 2 }"
                    type="button"
                    @click="validateComponent('toggle-step', 2)">
              {{ $t('order_card.step.delivery_address') }}
            </button>
          </li>
        </ul>
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('message.warning.form_not_valid')" />
        <component :is="currentStep"
                   v-model:address="currentItem.card.address"
                   v-model:card="currentItem.card"
                   :errors="errors"
                   is-imported-user
                   :invitation="currentItem.card.invitation"
                   hide-user-select
                   @update-card-field="updateCardField"
                   @update-card-settings="updateCardSettings" />
      </div>
    </div>
    <component-sidepanel-footer :key="activeStep">
      <div class="row row--sm-bottom">
        <div class="col col--sm left">
          <div class="btn-group">
            <button class="btn btn--outline"
                    type="button"
                    @click="validateComponent('get-previous-item')">
              <ic-arrow class="ic-arrow-prev ic--20 ic--gray" />
            </button>
            <button class="btn btn--outline"
                    type="button"
                    @click="validateComponent('get-next-item')">
              <ic-arrow next
                        class="ic-arrow-next ic--20 ic--gray" />
            </button>
          </div>
        </div>
        <div class="col col--sm right">
          <div class="form-buttons">
            <button class="btn btn--gray"
                    type="button"

                    @click="appStore.closeSidePanel">
              {{ $t('button.cancel') }}
            </button>
            <button class="btn btn--primary"
                    :disabled="isSubmitting">
              {{ $t('button.validate') }}
            </button>
          </div>
        </div>
      </div>
    </component-sidepanel-footer>
  </validation-form>
</template>

<script>
import modulo from 'just-modulo'
import * as yup from 'yup'

import { isClassicVirtualCard, isOneTimeVirtualCard, isPhysicalCard, isRecurringVirtualCard } from '@/helpers/utils/card'
import { scrollToTop } from '@/helpers/utils/dom'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'

import CardSettings from '@/pages/cards/order/components/CardSettings.vue'
import OwnerInformation from '@/pages/cards/order/components/OwnerInformation.vue'
import DeliveryAddress from '@/pages/cards/order/physical/components/DeliveryAddress.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

export default {
  components: {
    IcArrow,
    ComponentAlert,
    ComponentSidepanelFooter,
    CardSettings,
    DeliveryAddress,
    OwnerInformation
  },

  props: {
    cardOrderId: {
      type: Number,
      required: true
    },

    cardOrders: {
      type: Array,
      required: true
    }
  },

  setup () {
    const appStore = useAppStore()
    const cardStore = useCardStore()

    return { appStore, cardStore }
  },

  data () {
    return {
      currentItem: {},
      activeStep: 0,
      index: 0
    }
  },

  computed: {
    isPhysicalCard () {
      return isPhysicalCard(this.currentItem.card)
    },

    isOneTimeCard () {
      return isOneTimeVirtualCard(this.currentItem.card)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.currentItem.card)
    },

    isVirtualCardClassic () {
      return isClassicVirtualCard(this.currentItem.card)
    },

    schemas () {
      return [
        this.userSchema,
        this.isPhysicalCard ? {} : this.cardSettingsSchema,
        this.isPhysicalCard ? this.addressSchema : {}
      ]
    },

    addressSchema () {
      return yup.object({
        add_company_name: yup.boolean(),
        street: yup.string().required(),
        postcode: yup.string().required(),
        city: yup.string().required(),
        country: yup.string().required()
      })
    },

    userSchema () {
      return yup.object({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        email: yup.string().required(),
        phone: yup.string().validPhoneNumber().nullable()
      })
    },

    cardSettingsSchema () {
      return yup.object({
        initial_amount: this.isVirtualCardClassic ? yup.string() : yup.string().required(),
        expiration: yup.number().required()
      })
    },

    steps () {
      const steps = ['owner-information']
      this.isPhysicalCard
        ? steps.push('card-settings', 'delivery-address')
        : steps.push('card-settings')

      return steps
    },

    currentStep () {
      return this.steps[this.activeStep]
    },

    isFirstStep () {
      return this.activeStep === 0
    },

    isLastStep () {
      return this.activeStep === this.steps.length - 1
    }
  },

  created () {
    this.index = this.cardOrders.indexOf(this.cardOrders.find(value => value.id === this.cardOrderId))
    this.currentItem = this.cardOrders[this.index]
  },

  methods: {
    getPreviousItem () {
      this.index = modulo(this.index - 1, this.cardOrders.length)
      this.currentItem = this.cardOrders[this.index]
      this.activeStep = 0
    },

    getNextItem () {
      this.index = modulo(this.index + 1, this.cardOrders.length)
      this.currentItem = this.cardOrders[this.index]
      this.activeStep = 0
    },

    toggleStep (step) {
      if (this.activeStep !== step) {
        this.activeStep = step
      }
    },

    async validateComponent (type, step = null) {
      const { valid } = await this.$refs.form.validate()
      if (!valid) {
        return this.onInvalidSubmit()
      }

      if (type === 'toggle-step') {
        this.toggleStep(step)
      } else if (type === 'get-previous-item') {
        this.getPreviousItem()
      } else if (type === 'get-next-item') {
        this.getNextItem()
      } else if (type === 'submit') {
        this.onSubmit()
      }
    },

    onInvalidSubmit () {
      scrollToTop(this.$refs.scrollWrapper)
    },

    updateCardSettings (data) {
      this.currentItem.card.settings = data
      this.$bus.emit('card-updated')
    },

    updateCardField (data) {
      Object.keys(data).forEach(key => {
        this.currentItem.card[key] = data[key]
      })
    },

    onSubmit () {
      this.cardStore.editCardOrders(this.cardOrders)
      this.appStore.closeSidePanel()
    }
  }
}
</script>
