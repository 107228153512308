<template>
  <component-loader-spinner v-if="loading"
                            class="c c--vh" />
  <div v-else
       class="sidepanel__content"
       data-cy="periodic-transfers.sidepanel">
    <transition name="sidepanel-double-slide">
      <component-upcoming-transfers v-if="openDoubleSidepanel"
                                    class="sidepanel-double sidepanel-double--lg overflow-scrollbar"
                                    :form-view="false"
                                    :amount="transfer.amount"
                                    :currency="transfer.currency"
                                    :beneficiary-label="transfer.beneficiary.label"
                                    :initial-deleted-deadlines="transfer.deleted_deadlines"
                                    :end-date="transfer.end_date"
                                    :period="transfer.period"
                                    :start-date="transfer.start_date"
                                    :transfer-uuid="transfer.uuid"
                                    @close="hideUpcomingTransfers" />
    </transition>
    <div id="sidepanel-wrapper"
         class="sidepanel-wrapper overflow-scrollbar">
      <div class="sidepanel-header">
        <div class="transaction-summary transaction-summary--no-border">
          <h2 class="transaction-amount">
            {{ formatAmount(transfer.amount, account.currency) }}
          </h2>
          <p class="transaction-name">
            {{ transfer.label }}
          </p>
          <core-badge v-if="transfer.status_label"
                      :size="ECoreBadgeSize.Large"
                      class="transaction-badge"
                      v-bind="getBadgeProps(transfer.status)"
                      :value="transfer.status_label" />
        </div>
      </div>
      <div class="sidepanel-body">
        <div class="sidepanel-details">
          <transaction-user v-if="hasFeature(EFeature.TransferPeriodicDetailInitiator) && transfer.user"
                            :pictures="[transfer.user.picture]"
                            :user-names="[`${transfer.user.first_name} ${transfer.user.last_name}`]"
                            :text="$t('general.initiated_by')" />
          <h3 class="head-title">
            {{ $t('sidepanel.transfer.title') }}
          </h3>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.created_at") }}</span>
            <span class="entry-value right">{{ formatDateText(transfer.created_at, featureParams(EFeature.TransferPeriodicDetailCreationDate).format) }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.periodicity") }}</span>
            <span class="entry-value right">{{ $t(`general.${transfer.period}`) }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.start_date") }}</span>
            <span class="entry-value right">{{ formatDateText(transfer.start_date, featureParams(EFeature.TransferPeriodicDetailStartDate).format) }}</span>
          </div>
          <div class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.end_date") }}</span>
            <span class="entry-value right">{{ endDate }}</span>
          </div>
          <div v-if="transfer.beneficiary.label"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.beneficiary") }}</span>
            <span class="entry-value right">{{ transfer.beneficiary.label }}</span>
          </div>
          <div v-if="transfer.beneficiary.iban"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("general.iban") }}</span>
            <span class="entry-value right">{{ formatIban(transfer.beneficiary.iban) }}</span>
          </div>
          <div v-if="hasFeature(EFeature.TransferCategory)"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ categoryLabel }}</span>
            <div v-tooltip="{ content: tooltipCategoriesLabel, theme: 'tooltip', html: true }"
                 class="entry-value right">
              {{ categoryList }}
            </div>
          </div>
          <div v-if="hasFeature(EFeature.TransferAffectation)"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ affectationLabel }}</span>
            <div v-tooltip="{ content: tooltipAffectationsLabel, theme: 'tooltip', html: true }"
                 class="entry-value right">
              {{ affectationList }}
            </div>
          </div>
          <div v-if="transfer.comment"
               class="sidepanel-details__entry">
            <span class="entry-label text-muted">{{ $t("form.personal_note.label") }}</span>
            <span class="entry-value right">{{ transfer.comment }}</span>
          </div>
        </div>
        <template v-if="hasFeature(EFeature.TransferPeriodicDetailLastPastDeadlines) && transfer.last_past_deadlines.length">
          <hr class="divider divider--fw">
          <div class="sidepanel-details">
            <h3 class="head-title">
              {{ $tc('sidepanel.transfer.last_past_deadlines', transfer.last_past_deadlines.length) }}
            </h3>
          </div>
          <component-table class="table--xs mb-0">
            <tbody>
              <tr v-for="deadline in transfer.last_past_deadlines"
                  :key="deadline.id">
                <td>
                  <core-badge v-bind="getBadgeProps(deadline.status)"
                              :value="deadline.status_label" />
                </td>
                <td class="text-muted nowrap">
                  {{ formatDate(deadline.execution_date) }}
                </td>
                <td class="right">
                  {{ formatAmount(deadline.amount, deadline.currency) }}
                </td>
              </tr>
            </tbody>
          </component-table>
        </template>
      </div>
    </div>
    <component-sidepanel-footer v-if="hasFeature(EFeature.TransferPeriodicUpcomingTransfers)">
      <button type="button"
              data-cy="periodic-transfers.sidepanel.btn-upcoming-transfers"
              class="btn btn--block btn--sm btn--outline"
              @click.prevent="toggleUpcomingTransfers">
        {{ $t('upcoming_transfers.button.manage_to_come') }}
      </button>
    </component-sidepanel-footer>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeSize } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { EFeature, featureParams, hasFeature } from '@/config/features'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDate, formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { formatAmount } from '@/helpers/utils/number'
import { arrayInputLabel, arrayInputTooltip } from '@/helpers/utils/text'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useTransferStore } from '@/stores/transfer'

import ComponentLoaderSpinner from '@/components/LoaderSpinner.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import ComponentTable from '@/components/Table.vue'
import TransactionUser from '@/components/TransactionUser.vue'

import ComponentUpcomingTransfers from './UpcomingTransfers.vue'

export default {
  components: {
    CoreBadge,
    ComponentUpcomingTransfers,
    ComponentLoaderSpinner,
    ComponentTable,
    ComponentSidepanelFooter,
    TransactionUser
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const transferStore = useTransferStore()

    const { account } = storeToRefs(accountStore)

    return { account, appStore, transferStore }
  },

  data () {
    return {
      loading: true,
      openDoubleSidepanel: false,
      transfer: null
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    EFeature () {
      return EFeature
    },

    isAccountOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    endDate () {
      return this.transfer.end_date ? formatDateText(this.transfer.end_date, featureParams(EFeature.TransferPeriodicDetailEndDate).format) : this.$i18n.t('general.undefined')
    },

    categoryLabel () {
      return this.$i18n.tc('form.category.label', this.transfer.categories?.length || 0)
    },

    affectationLabel () {
      return this.$i18n.tc('general.affectation', this.transfer.affectations?.length || 0)
    },

    tooltipCategoriesLabel () {
      return arrayInputTooltip(this.transfer.categories, 1)
    },

    tooltipAffectationsLabel () {
      return arrayInputTooltip(this.transfer.affectations, 1)
    },

    categoryList () {
      return this.transfer.categories?.length
        ? arrayInputLabel(this.transfer.categories, 1)
        : this.$i18n.t('general.none')
    },

    affectationList () {
      return this.transfer.affectations?.length
        ? arrayInputLabel(this.transfer.affectations, 1)
        : this.$i18n.t('general.none')
    }
  },

  async mounted () {
    this.transfer = await this.transferStore.getPermanentTransfer(this.model.uuid)
    this.loading = false

    this.$bus.on('hide-sidepanel', this.hideUpcomingTransfers)
    this.$bus.on('update-deleted-deadlines', this.updateDeletedDeadlines)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.hideUpcomingTransfers)
    this.$bus.off('update-deleted-deadlines', this.updateDeletedDeadlines)
  },

  methods: {
    getBadgeProps,
    featureParams,

    hasFeature,

    formatIban,

    formatAmount,

    formatDate,

    formatDateText,

    hideUpcomingTransfers () {
      this.openDoubleSidepanel = false
    },

    toggleUpcomingTransfers () {
      this.openDoubleSidepanel = !this.openDoubleSidepanel
    },

    updateDeletedDeadlines (dates) {
      this.transfer.deleted_deadlines = dates
    }
  }
}
</script>
