import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { defineStore } from 'pinia'

import axiosClient from '@/api'
import { useAccountStore } from '@/stores/account'

export const useTransferStore = defineStore('transfer', {
  state: () => {
    return {
      transfers: [],
      transfersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      transfersTotalAmount: 0,
      countTransfers: 0,
      advancePayments: [],
      permanentTransfers: [],
      periodicTransfersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      multipleTransfers: null,
      currencies: [],
      iban: {
        street: '',
        bank: '',
        bic: '',
        city: '',
        country: '',
        country_iso: '',
        sepa: true,
        state: '',
        zip: ''
      },
      transferFile: null,
      amount_change: 0,
      amount_fees: 0,
      exchange_rate: 0,
      upcomingTransfersCount: {
        active: 0,
        deleted: 0
      },
      upcomingTransfersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  actions: {
    async getTransfers (state, page, params = {}) {
      const newParams = {
        ...params,
        cancelled: state === 'history',
        origin_method: state === 'salary' ? ['import_payslip', 'advance_payment'] : [],
        page,
        mode: 'collection'
      }

      try {
        const { data, headers } = await axiosClient.get('/transfers', { params: newParams })
        useAccountStore().account.counts.transfers.waiting_validation = parseInt(headers['x-transfer-waiting-validation-count'])
        const collection = this.parseCollection(data, headers)

        this.transfers = collection.items
        this.transfersTotalAmount = collection.totalAmount
        this.transfersPagination = collection.pagination
      } catch (e) {
        console.error(e)
        this.transfers = []
        this.transfersTotalAmount = 0
      }
    },

    async getPermanentTransfers (page, params = {}) {
      let transfers = []

      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/periodic-transfers', { params })
        this.periodicTransfersPagination = this.parsePagination(headers)
        transfers = data
      } catch (e) {
        console.error(e)
      }

      this.permanentTransfers = transfers
    },

    async getTransfersCount (params = {}) {
      try {
        const { data } = await axiosClient.get('/transfers/count', { params })
        return data
      } catch (error) {
        console.error(error)
        return { count: 0, export_delayed: false }
      }
    },

    async getPermanentTransfer (uuid) {
      try {
        const { data } = await axiosClient.get(`/periodic-transfers/${uuid}`)
        return data
      } catch (e) {
        console.error(e)
      }
    },

    async getTransfer (id) {
      try {
        const { data } = await axiosClient.get(`/transfers/${id}`)
        return data
      } catch (e) {
        console.error(e)
      }
    },

    async getCountAdvancePayments () {
      try {
        const { headers } = await axiosClient.head('/transfers/advance_payment')
        return parseInt(headers['x-advance-payments-total-count'])
      } catch (e) {
        console.error(e)
        return 0
      }
    },

    async getAdvancePayments (params = {}) {
      try {
        const { data } = await axiosClient.get('/transfers/advance_payment', { params })
        if (params && params.beneficiaries) {
          data.forEach(elem => { this.advancePayments.push(elem) })
        } else {
          this.advancePayments = data
        }
      } catch (e) {
        console.error(e)
      }
    },

    async getUpcomingTransfers (params = {}) {
      try {
        const { data, headers } = await axiosClient.get('periodic-transfers/upcoming', { params })
        this.upcomingTransfersPagination = this.parsePagination(headers)
        if (params.page === 1) {
          this.upcomingTransfersCount = {
            active: parseInt(headers['x-periodic-transfer-active-count'] || '0'),
            deleted: parseInt(headers['x-periodic-transfer-delete-count'] || '0')
          }
        }
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async cancelDate (uuid, date) {
      try {
        await axiosClient.post(`periodic-transfers/${uuid}/upcoming`, { date })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    async restoreDate (uuid, date) {
      try {
        await axiosClient.delete(`periodic-transfers/${uuid}/upcoming`, { data: { date } })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },

    updateUpcomingTransfersCount (active, deleted) {
      this.upcomingTransfersCount = {
        active,
        deleted
      }
    },

    setAdvancePayments (data) {
      this.advancePayments = data
    },

    async saveTransfer (transfer) {
      const { documents, ...transferData } = transfer

      try {
        if (!transferData.period) {
          const payload = new FormData()

          if (documents?.length) {
            documents.forEach(document => { payload.append('documents[]', document) })
          }

          payload.append('jsonBody', JSON.stringify(transferData))

          const { data } = await axiosClient.post('/transfers', payload)
          return data
        } else {
          const { data } = await axiosClient.post('/periodic-transfers', transferData)
          return data
        }
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async togglePermanentTransfers (transfer) {
      transfer.enabled = !transfer.enabled
      try {
        await axiosClient.put(`/periodic-transfers/${transfer.uuid}`, { enabled: transfer.enabled })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateTransfer (transfer) {
      const { documents, ...transferData } = transfer

      try {
        if (!transferData.period) {
          const payload = new FormData()

          if (documents?.length) {
            documents.forEach(document => { payload.append('documents[]', document) })
          }

          payload.append('jsonBody', JSON.stringify(transferData))

          await axiosClient.put(`transfers/${transferData.id}`, payload)
        } else {
          await axiosClient.put(`periodic-transfers/${transferData.uuid}`, transferData)
        }
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async validateAllTransfers () {
      try {
        await axiosClient.put('/transfers/batch/validate')
        return true
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async validateTransfer (transfer) {
      try {
        const { data } = await axiosClient.put(`/transfers/${transfer.id}/validate`, { validation_token: transfer.validation_token })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async cancelTransfer (id) {
      try {
        await axiosClient.delete(`transfers/${id}`)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async updateAmountChange (transfer) {
      if (!transfer.amount) return

      try {
        const { data } = await axiosClient.get('/transfers/fees', {
          params: { currency: transfer.currency, amount: transfer.amount }
        })
        this.amount_change = data.converted_amount
        this.amount_fees = data.estimated_fees
        this.exchange_rate = data.exchange_rate
      } catch (e) {
        console.error(e)
      }
    },

    async getMultipleTransfers (files, sepa) {
      this.$reset()

      const payload = new FormData()

      files.filter(f => f instanceof File).forEach(file => { payload.append('files[]', file) })

      if (sepa) {
        payload.append('jsonBody', JSON.stringify({ sepa: true }))
      }

      try {
        const { data } = await axiosClient.post('/transfers/import', payload)
        this.multipleTransfers = data
        if (this.multipleTransfers.valid && this.multipleTransfers.valid.length !== 0) {
          for (let i = 0; i < this.multipleTransfers.valid.length; i++) {
            if (dayjs(this.multipleTransfers.valid[i].transfer.execution_date).isBefore(dayjs().format())) {
              this.multipleTransfers.valid[i].transfer.execution_date = dayjs().format('YYYY-MM-DD')
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    },

    async validateIban (iban) {
      this.$reset()
      try {
        const { data } = await axiosClient.get('/iban/validate', { params: { iban }, doNotShowBanner: true })
        this.updateIban(data)
        return data
      } catch (error) {
        console.error(error)
        return false
      }
    },

    updateIban (iban) {
      if (typeof iban.sepa === 'boolean') {
        for (const key in iban) {
          if (Object.prototype.hasOwnProperty.call(iban, key) && iban[key] !== null) {
            this.iban[key] = iban[key]
          }
        }
      } else {
        this.iban.sepa = false
      }
    },

    setIsSepa (value) {
      this.iban.sepa = value
    },

    async sendMultipleTransfers (values) {
      const payload = new FormData()
      const multiple = {
        transfers: [],
        is_advance_payment: values.isAdvancePayment,
        is_payslip: values.isPayslip,
        export: values.export
      }
      if (values.isPayslip) {
        multiple.send_payslip = values.send_payslip
      }

      values.transfers.forEach((value, i) => {
        const item = cloneDeep(value)
        item.transfer.execution_date = dayjs(item.transfer.execution_date).format('YYYY-MM-DD')
        item.transfer.beneficiary_id = item.beneficiary.id

        if (item.transfer.document) {
          item.transfer.temporary_file = item.transfer.document.id
        }

        delete item.transfer.beneficiary

        if (item.transfer.document instanceof File) {
          payload.append(`document[${i}]`, item.transfer.document)
        }

        item.transfer.document = true
        multiple.transfers.push(item.transfer)
      })

      payload.append('jsonBody', JSON.stringify(multiple))

      try {
        await axiosClient.post('/transfers/batch', payload)
        if (values.isAdvancePayment) {
          const accountStore = useAccountStore()
          if (accountStore.account.detail && values.export.export_recipient !== accountStore.account.detail.payroll_manager_email) {
            accountStore.account.detail.payroll_manager_email = values.export.export_recipient
          }

          if (accountStore.account.detail && values.export.send_export !== accountStore.account.detail.advance_payment_export) {
            accountStore.account.detail.advance_payment_export = values.export.send_export
          }

          if (!accountStore.account.detail) {
            accountStore.account.detail = {
              payroll_manager_email: values.export.export_recipient,
              advance_payment_export: values.export.send_export
            }
          }
        }
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getCurrencies () {
      try {
        const { data } = await axiosClient.get('/currencies')
        this.currencies = data
      } catch (error) {
        console.error(error)
      }
    },

    async analyzeTransferDocument (document, abortSignal) {
      const payload = new FormData()

      payload.append('files', document)

      try {
        const { data } = await axiosClient.post('/transfers/document/analyze', payload, { signal: abortSignal })
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async wirecheck (key) {
      try {
        const { data } = await axiosClient.get(`/transfers/check/${key}`, { doNotShowBanner: true })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    }
  }
})
