<template>
  <div class="limits-per-period">
    <limit-item v-for="(period, index) in periods"
                :key="index"
                :data-cy="`limits-per-period.${period}`"
                :enabled="value[period].enabled"
                :invalid-amount-warning="invalidAmountWarning(period, index, focused[period])"
                :label="$t(`card.limit.option.${period}`)"
                :limit="value[period].limit"
                :disabled="disabled"
                :placeholder="placeholder"
                :display-currency="displayCurrency"
                @update-focus="newValue => focused[period] = newValue"
                @update-enabled="newValue => updateLimitSettings(period, { ...value[period], enabled: newValue })"
                @update-limit="limit => updateLimitSettings(period, { ...value[period], limit: parseInt(limit) })" />
  </div>
</template>

<script>
import LimitItem from './LimitItem.vue'

export default {
  components: {
    LimitItem
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: Object,
      required: true
    },

    displayCurrency: {
      type: Boolean,
      default: true
    },

    itemClass: {
      type: String,
      default: 'form__fieldset__block'
    },

    placeholder: {
      type: Boolean,
      default: true
    },

    periods: {
      type: Array,
      default: () => [
        'daily',
        'weekly',
        'monthly',
        'annually'
      ]
    }
  },

  emits: ['update-limit-settings'],

  data () {
    return {
      focused: Object.fromEntries(this.periods.map(period => [period, false]))
    }
  },

  methods: {
    invalidAmountWarning (selectedPeriod, selectedIndex, focused) {
      const period = this.periods.find((period, index) => (!focused && index > selectedIndex && this.value[period].enabled && this.value[period].limit < this.value[selectedPeriod].limit))

      if (period) {
        if (this.displayCurrency) {
          return this.$t('tooltip.invalid_amount_period', { period: this.$t(`card.limit.option.${period}`) })
        }
        return this.$t('tooltip.invalid_limit_period', { period: this.$t(`card.limit.option.${period}`) })
      }
      return null
    },

    updateLimitSettings (period, value) {
      this.focused[period] = false
      this.$emit('update-limit-settings', { period, value })
    }
  }
}
</script>

<style lang="stylus" scoped>
.limits-per-period
  padding .5rem 0
  .limit-item
    height 7rem
</style>
