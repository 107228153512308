<template>
  <section class="section section-payslip-wire">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <router-link class="btn-link btn-back"
                       :to="{ name: 'transfers-create-multiple' }">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </router-link>
          {{ isPayslip ? $t('placeholder.multiple_transfers.payslips.title') : $t('placeholder.multiple_transfers.sepa.title') }}
        </h1>
      </div>
    </div>
    <div v-if="loading"
         class="section__loader c c--vh">
      <loader-spinner />
    </div>
    <div v-else
         class="section__content">
      <validation-form class="form form--centered form--w1000 form-wiretransfer"
                       :validation-schema="schema"
                       @submit="submitAllTransfers">
        <div>
          <component-alert v-if="error"
                           type="error"
                           :message="$t('message.warning.form_not_valid')" />
          <component-alert v-else
                           type="success"
                           :message="$tc('form.multiple_transfers.success', numberOfTransfers)"
                           :show-api-messages="false" />
        </div>

        <fieldset class="form__fieldset">
          <fieldset-title :title="$t('form.transfer_informations.title')"
                          show-number />
          <div class="form__fieldset__group">
            <div class="form__row row row--sm-bottom">
              <div class="form-group col col--sm-7">
                <label class="label input__label">{{ $t('form.debit_account.title') }}</label>
                <component-dropdown dropdown-width="100%"
                                    disabled
                                    label="account"
                                    :model="account.uuid">
                  <template #trigger>
                    <div class="select select-account">
                      <div class="form-control form-control--noborder">
                        <span>{{ account.label || account?.company?.name }}</span>
                      </div>
                      <span class="select-account__balance text-green">{{ formatAmount(account.balance, account.currency) }}</span>
                    </div>
                  </template>
                </component-dropdown>
              </div>
              <component-datepicker v-if="isPayslip"
                                    v-model="execution_date"
                                    name="execution_date"
                                    :disabled-days="disabled"
                                    :label="$t('form.transfer_details.date_label')"
                                    :language="$i18n.locale"
                                    format="dd/MM/yyyy"
                                    wrapper-class="form-group col col--sm-5"
                                    input-class="form-control form-control--noborder"
                                    monday-first
                                    :readonly="false"
                                    :placeholder="$t('form.date.placeholder')" />
            </div>
          </div>
        </fieldset>
        <fieldset class="form__fieldset">
          <fieldset-title :title="$t(`form.${values.length > 1 ? 'credit_accounts' : 'credit_account'}.title`)"
                          show-number />
          <div class="bordered-box">
            <table class="table table--hover">
              <thead class="bordered-header">
                <tr>
                  <th />
                  <th>{{ $t('table.beneficiary') }}</th>
                  <th class="col-action center">
                    {{ $t('table.notification') }}
                  </th>
                  <th class="col-action center">
                    {{ isPayslip ? $t('table.payslip') : $tc('general.proof', 1) }}
                  </th>
                  <th v-if="hasAdvancePayments"
                      class="col col--sm right payslip-amount advance-payment">
                    {{ $t('table.net_salary') }}
                    <span v-tooltip="{ content: $t('tooltip.for_your_information'), theme: 'tooltip' }">
                      <ic-info outline
                               class="ic ic-info ic--gray ic--12" />
                    </span>
                  </th>
                  <th v-if="hasAdvancePayments"
                      class="col col--sm right payslip-amount">
                    {{ $t('table.advance') }}
                  </th>
                  <th v-if="hasAdvancePayments"
                      colspan="3"
                      class="col col--sm right payslip-amount">
                    {{ $t('table.left_to_pay') }}
                  </th>
                  <th v-if="!hasAdvancePayments"
                      colspan="5"
                      class="right payslip-amount">
                    {{ $t('table.amount') }}
                  </th>
                  <th v-if="hasWarning" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in values"
                    :key="index"
                    :class="{ 'is-link': !isUnknownBeneficiary(index) }">
                  <td class="col-checkbox">
                    <ic-error v-if="isUnknownBeneficiary(index)"
                              v-tooltip="{ content: $t('tooltip.beneficiary_notfound'), theme: 'tooltip' }"
                              class="ic ic--18 ic--warning" />
                    <label v-else
                           class="checkbox">
                      <validation-field :model-value="value.transfer.checked"
                                        :name="`checked[${index}]`">
                        <input v-model="value.transfer.checked"
                               type="checkbox">
                      </validation-field>
                      <span class="checkbox__icon" />
                    </label>
                  </td>
                  <td @click="onClick(value.id)">
                    {{ value.beneficiary.label }} <br>
                    <span class="text-muted">{{ formattedIban(value) }}</span>
                    <span v-if="formattedIban(value) && isUnknownBeneficiary(index)">- </span>
                    <span v-if="isUnknownBeneficiary(index)"
                          class="text-secondary is-link"
                          @click.prevent.stop="onAddBeneficiary(index)">{{ $t("button.add_the_beneficiary") }}</span>
                  </td>
                  <td class="center"
                      @click="onClick(value.id)">
                    <button v-if="!isUnknownBeneficiary(index)"
                            v-tooltip="{ content: getTooltipMessageNotifications(index), theme: 'tooltip' }"
                            class="btn-link btn-link--document"
                            @click.prevent.stop="onClickNotification(index)">
                      <template v-if="value.beneficiary.email">
                        <ic-mail v-if="!value.transfer.send_notification"
                                 class="ic ic--24 ic--off" />
                        <ic-mail-success v-else
                                         class="ic ic--dark ic--32" />
                      </template>
                      <ic-mail-error v-else-if="!value.beneficiary.email"
                                     class="ic ic--24 ic--gray" />
                    </button>
                  </td>
                  <td class="center"
                      @click="onClick(value.id)">
                    <button v-if="!isUnknownBeneficiary(index)"
                            v-tooltip="{ content: getTooltipMessageDocument(index), theme: 'tooltip' }"
                            class="btn-link btn-link--document"
                            @click.prevent.stop="value.transfer.document ? showFile(value.transfer.document) : appStore.showDropzoneModal($tc('title.attach_file'), value.transfer, { url: '/documents', maxFiles: 1 })">
                      <ic-document v-if="value.transfer.document"
                                   class="ic ic--gray" />
                      <ic-attachment v-else
                                     class="ic ic--gray" />
                    </button>
                  </td>
                  <td v-if="hasAdvancePayments"
                      class="right payslip-amount"
                      @click="onClick(value.id)">
                    <span v-if="value.transfer.net_salary_amount">
                      {{ formatAmount(value.transfer.net_salary_amount, account.currency) }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                  <td v-if="hasAdvancePayments"
                      class="right payslip-amount"
                      @click="onClick(value.id)">
                    <span v-if="value.transfer.advance_payment_amount">
                      {{ formatAmount(value.transfer.advance_payment_amount, account.currency) }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                  <td :colspan="hasAdvancePayments ? 3 : 5"
                      class="right payslip-amount"
                      @click="onClick(value.id)">
                    <strong>{{ formatAmount(value.transfer.amount, account.currency) }}
                    </strong>
                  </td>
                  <td v-if="hasWarning">
                    <span v-if="value.transfer.warning"
                          v-tooltip="{ content: value.transfer.warning, theme: 'tooltip' }">
                      <ic-warning outline
                                  class="ic ic--warning ic--20" />
                    </span>
                  </td>
                </tr>
                <tr class="summary-transfer">
                  <td :colspan="6">
                    <strong class="ml-1">
                      {{ getTotalLabel }}
                    </strong>
                  </td>
                  <td v-if="totalAmount"
                      :colspan="3"
                      class="right payslip-amount">
                    <strong>{{ formatAmount(totalAmount, account.currency) }}</strong>
                  </td>
                  <td v-if="hasWarning" />
                </tr>
                <tr v-if="totalTaxAmount"
                    class="summary-taxes-amount">
                  <td :colspan="6">
                    <span v-tooltip="{ content: $t('tooltip.for_your_information'), theme: 'tooltip' }"
                          class="ic-helper">
                      <ic-info outline
                               class="ic ic--gray" />
                    </span>
                    <span>{{ $t('form.multiple_transfers.tax_amount') }}</span>
                  </td>
                  <td :colspan="3"
                      class="right payslip-amount">
                    {{ formatAmount(totalTaxAmount, account.currency) }}
                  </td>
                  <td v-if="hasWarning" />
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
        <div v-if="isPayslip"
             class="bordered-box bordered-box--padding">
          <core-toggle-switch v-model:model-value="send_payslip"
                              class="send-payslip"
                              :label="$t('form.multiple_transfers.send_payslip')" />
        </div>
        <component-alert v-if="isAmountTooHigh"
                         :type="'warning'"
                         :message="$tc('form.multiple_transfers.warning.not_enough_funds', numberOfSelectedTransfers)"
                         :show-api-messages="false" />
        <div class="form-buttons">
          <router-link class="btn btn--gray"
                       :to="{ name: 'transfers' }">
            {{ $t('button.cancel') }}
          </router-link>
          <button type="submit"
                  class="btn btn--primary"
                  :disabled="isAmountTooHigh || !numberOfSelectedTransfers">
            {{ $t('button.validate') }}
          </button>
        </div>
      </validation-form>
    </div>
  </section>
</template>

<script>
import { CoreToggleSwitch } from '@common/core-ui'
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useTransferStore } from '@/stores/transfer'

import EditMultipleTransfer from '@/pages/transfers/sidepanel/EditMultipleTransfer.vue'
import SidepanelNewBeneficiary from '@/pages/transfers/sidepanel/NewBeneficiary.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import Wait from '@/components/modals/Wait.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import IcError from '@/components/svg/icons/ic-error.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcMail from '@/components/svg/icons/ic-mail.vue'
import IcMailError from '@/components/svg/icons/ic-mail-error.vue'
import IcMailSuccess from '@/components/svg/icons/ic-mail-success.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'

export default {
  components: {
    ComponentDropdown,
    LoaderSpinner,
    IcAttachment,
    IcDocument,
    IcMailError,
    IcMailSuccess,
    IcMail,
    IcInfo,
    IcWarning,
    IcError,
    IcArrow,
    ComponentAlert,
    ComponentDatepicker,
    FieldsetTitle,
    CoreToggleSwitch
  },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const apiStore = useApiStore()
    const appStore = useAppStore()
    const transferStore = useTransferStore()

    const { account } = storeToRefs(accountStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { multipleTransfers } = storeToRefs(transferStore)

    return {
      accountStore,
      appStore,
      multipleTransfers,
      transferStore,

      account,
      apiError
    }
  },

  data () {
    return {
      loading: true,
      values: [],
      disabled: {
        to: new Date()
      },

      send_payslip: true,
      execution_date: new Date(),
      error: false
    }
  },

  computed: {
    schema () {
      return yup.object({
        execution_date: this.isPayslip ? yup.date().required() : null,
        checked: yup.array().compact().min(1)
      })
    },

    isPayslip () {
      return this.type === 'payslips'
    },

    numberOfTransfers () {
      return this.values.length
    },

    selectedTransfers () {
      return this.values.filter(value => value.transfer.checked)
    },

    numberOfSelectedTransfers () {
      return this.selectedTransfers.length
    },

    getTotalLabel () {
      return this.$i18n.tc('form.multiple_transfers.total', this.numberOfSelectedTransfers)
    },

    hasAdvancePayments () {
      return this.values.find(value => value.transfer.advance_payment_amount)
    },

    hasWarning () {
      return this.values.find(value => value.transfer.warning)
    },

    totalAmount () {
      return this.selectedTransfers.reduce((total, select) => total + select.transfer.amount, 0)
    },

    totalTaxAmount () {
      return this.selectedTransfers.reduce((total, select) => total + select.transfer.tax, 0)
    },

    isAmountTooHigh () {
      return this.totalAmount > this.account.balance
    }
  },

  watch: {
    apiError: function () {
      if (!this.appStore.sidePanel.active && this.apiError?.code === 422) {
        this.error = true
        this.$bus.emit('scrollToTop')
      }
    },

    execution_date: function () {
      for (let i = 0; i < this.values.length; i++) {
        this.values[i].transfer.execution_date = this.execution_date
      }
    }
  },

  async created () {
    if (!this.multipleTransfers) {
      return await this.$router.push({ name: 'transfers-create-multiple' })
    }

    this.values = Array.prototype.concat.apply([], [this.multipleTransfers.valid, this.multipleTransfers.invalid.filter(value => value.beneficiary.id === null && value.beneficiary.label)])
    for (let i = 0; i < this.values.length; i++) {
      this.values[i].id = i
    }

    if (this.isPayslip) {
      this.execution_date = this.values[0].transfer.execution_date
    }

    this.loading = false
  },

  mounted () {
    this.$bus.on('on-edit-transfers-success', this.editTransfers)
    this.$bus.on('beneficiary-save-success', this.onBeneficiarySaved)
    this.$bus.on('beneficiary-update-success', this.onBeneficiarySaved)
    this.$bus.on('delete-local-document', this.deleteFile)
    this.$bus.on('account-switched', this.goBackToList)
    this.$bus.on('on-file-attached', this.onFileUpdated)
  },

  beforeUnmount () {
    this.$bus.off('on-edit-transfers-success', this.editTransfers)
    this.$bus.off('beneficiary-save-success', this.onBeneficiarySaved)
    this.$bus.off('beneficiary-update-success', this.onBeneficiarySaved)
    this.$bus.off('delete-local-document', this.deleteFile)
    this.$bus.off('account-switched', this.goBackToList)
    this.$bus.on('on-file-attached', this.onFileUpdated)
  },

  methods: {
    formatAmount,

    showFile (file) {
      this.appStore.showFileViewer({
        content: [file],
        canEdit: true,
        displayFullScreen: true
      })
    },

    async submitAllTransfers () {
      this.error = false
      this.appStore.showModal(
        Wait,
        {
          title: this.$i18n.t('modal.multiple_transfers.wait.label'),
          content: this.$i18n.tc('modal.multiple_transfers.wait.content', this.numberOfSelectedTransfers)
        },
        {
          wrapperClass: 'modal--xs modal-wait',
          blockLeaveBackground: true
        }
      )
      const success = await this.transferStore.sendMultipleTransfers({ transfers: this.selectedTransfers, isPayslip: this.isPayslip, send_payslip: this.send_payslip })
      if (success) this.onCreateSuccess()
    },

    onCreateSuccess () {
      showToastSuccess(this.$i18n.t('message.success.transfer.create'))
      this.goBackToList()
    },

    onClickNotification (index) {
      this.values[index].beneficiary.email ? this.toggleNotification(index) : this.onClick(index, true)
    },

    toggleNotification (index) {
      this.values[index].transfer.send_notification = !this.values[index].transfer.send_notification
    },

    onFileUpdated () {
      this.$forceUpdate()
    },

    onClick (index, setEmail = false) {
      const value = this.values.find(value => value.id === index)
      if (value.beneficiary !== null && value.beneficiary.id !== null) {
        this.appStore.showSidePanel(EditMultipleTransfer, { initialIndex: index, initialValues: this.values, setEmail }, { wrapperClass: 'sidepanel--xl', backgroundOverlay: true })
      }
    },

    onAddBeneficiary (index) {
      const value = this.values.find(value => value.id === index)
      this.appStore.showSidePanel(
        SidepanelNewBeneficiary,
        { beneficiaryInitial: value.beneficiary, enableNoSepa: false },
        { wrapperClass: 'sidepanel--xl', backgroundOverlay: true, closeButton: false })
    },

    editTransfers (newValues) {
      newValues.forEach(newValue => {
        this.values = this.values.map(value => value.id === newValue.id ? newValue : value)
      })
    },

    getTooltipMessageDocument (index) {
      return this.values[index].transfer.document ? this.$i18n.tc('tooltip.show_file') : this.$i18n.t('tooltip.attach_file')
    },

    getTooltipMessageNotifications (index) {
      if (this.values[index].beneficiary.email) {
        return this.values[index].transfer.send_notification ? this.$i18n.t('tooltip.notification.on') : this.$i18n.t('tooltip.notification.off')
      }
      return this.$i18n.t('tooltip.add_email')
    },

    isUnknownBeneficiary (index) {
      return this.values[index].beneficiary.id === null
    },

    onBeneficiarySaved (response) {
      this.values.forEach(value => {
        if (value.beneficiary.id === null &&
            (value.beneficiary.label === response.label || value.beneficiary.iban === response.iban)) {
          value.beneficiary = response
          value.transfer.beneficiary = response
          if (!value.transfer.warning) {
            value.transfer.checked = true
          }
        }
      })
    },

    formattedIban (value) {
      if (value.beneficiary.account_number_formatted) {
        return value.beneficiary.account_number_formatted
      } else if (value.beneficiary.iban) {
        return formatIban(value.beneficiary.iban)
      }
    },

    deleteFile (url) {
      const value = this.values.find(value => value.transfer.document?.url === url)
      if (value) {
        value.transfer.document = null
      }
      this.onFileUpdated()
    },

    goBackToList () {
      this.$router.push({ name: 'transfers' })
    }
  }
}
</script>
