import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { showToastSuccess } from '@/helpers/utils/notification'
import { globalTranslation } from '@/locales'

export const useStatementStore = defineStore('statement', {
  state: () => {
    return {
      statements: [],
      dispatch: [],
      dispatchPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      }
    }
  },

  actions: {
    async getStatements (params = {}) {
      let statements = []

      try {
        const { data } = await axiosClient.get('/statements', { params })
        data.forEach(function (statement) { statement.selected = false })
        statements = data
      } catch (error) {
        console.error(error)
      }

      this.statements = statements
    },

    async getStatementsDispatch (page) {
      let dispatch = []

      try {
        const { data, headers } = await axiosClient.get('/statement-dispatch', { params: { page } })
        this.dispatchPagination = this.parsePagination(headers)

        dispatch = data
      } catch (error) {
        console.error(error)
      }

      this.dispatch = dispatch
    },

    async getStatementsYears (params = {}) {
      try {
        const { data } = await axiosClient.get('/statements/years', { params })
        return data
      } catch (error) {
        console.error(error)
      }

      return []
    },

    async postStatementDispatch (payload) {
      try {
        await axiosClient.post('/statement-dispatch', payload)
        bus.emit('dispatch-save-success')
      } catch (error) {
        console.error(error)
      }
    },

    async editStatementDispatch (payload) {
      try {
        const { data } = await axiosClient.put(`/statement-dispatch/${payload.id}`, payload)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getNumberOfStatements (params = {}) {
      try {
        const { headers } = await axiosClient.head('/statements', { params })
        return this.parsePagination(headers).totalItems
      } catch (error) {
        console.error(error)
      }

      return 0
    },

    async downloadAllStatements () {
      try {
        const { data } = await axiosClient.get('/statements/download/all')
        return data.message
      } catch (error) {
        console.error(error)
      }

      return null
    },

    async sendByEmail (id, email, type = null) {
      let url = `/statements/${id}/send`
      if (type === 'annual') {
        url += '?type=annual_fees'
      } else if (type === 'all') {
        url += '/all'
      }

      try {
        await axiosClient.post(url, { email })
        showToastSuccess(globalTranslation('message.success.share.statement', { email }))
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
})
