<template>
  <div>
    <h4 v-if="title"
        class="body--extra-large center mb-4">
      {{ title }}
    </h4>
    <div class="block-options">
      <block-options-item v-for="(item, index) in cardInfos"
                          :key="index"
                          :title="item.title"
                          :content="item.content"
                          :data-cy="item.dataCy"
                          hide-chevron
                          @click="emit('update:role', item.value)">
        <image-card v-bind="item.iconProps" />
      </block-options-item>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { useAccountStore } from '@/stores/account'

import BlockOptionsItem from '@/components/BlockOptionsItem.vue'
import ImageCard from '@/components/svg/img/card.vue'

const props = defineProps<{
  physicalCard: boolean
  title?: string
}>()

const emit = defineEmits([
  'update:role'
])

const { t } = useI18n()

const accountStore = useAccountStore()
const { account } = storeToRefs(accountStore)

const cardInfos = computed(() => {
  const company = account.value?.company.name
  const routes = props.physicalCard
    ? [
        {
          value: 'owner',
          title: t('prompt.cards.order.choice.me'),
          dataCy: 'cards.create.user.me',
          content: t('order_card.choice.choice_me', { company }),
          iconProps: { }
        },
        {
          value: 'user',
          title: t('prompt.cards.order.choice.employee'),
          dataCy: 'cards.create.user.employee',
          content: t('order_card.choice.choice_employee', { company }),
          iconProps: { user: true }
        }
      ]
    : [
        {
          value: 'owner',
          title: t('prompt.cards.order.choice.me'),
          dataCy: 'cards.create.user.me',
          content: t('order_card.choice.virtual.choice_me', { company }),
          iconProps: { }
        },
        {
          value: 'user',
          title: t('prompt.cards.order.choice.employee'),
          dataCy: 'cards.create.user.employee',
          content: t('order_card.choice.virtual.choice_employee', { company }),
          iconProps: { user: true }
        }
      ]

  if (hasFeature(EFeature.CardCreateMultiple)) {
    routes.push({
      value: 'multiple',
      title: t('prompt.cards.order.choice.multiple'),
      dataCy: 'cards.create.user.multiple',
      content: t('order_card.choice.order_multiple', { company }),
      iconProps: { multiple: true }
    })
  }

  return routes
})
</script>
