<template>
  <section data-cy="dashboard.container"
           class="section section-dashboard">
    <div class="dashboard">
      <dashboard-main-block :dashboard="dashboard"
                            :loading="loading" />
      <component :is="block.component"
                 v-for="(block, i) in dashboardBlocks"
                 :key="i"
                 v-bind="block.props"
                 :loading="loading" />
    </div>
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, featureParams, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { useAccountStore } from '@/stores/account'
import { useOperationStore } from '@/stores/operation'

import DashboardCategoryExpenses from '@/pages/dashboard/components/DashboardCategoryExpenses.vue'
import DashboardCounts from '@/pages/dashboard/components/DashboardCounts.vue'
import DashboardStatements from '@/pages/dashboard/components/DashboardStatements.vue'
import DashboardUsersExpenses from '@/pages/dashboard/components/DashboardUsersExpenses.vue'

import DashboardMainBlock from './components/DashboardMainBlock.vue'
import DashboardOperations from './components/DashboardOperations.vue'

export default {
  name: 'PageDashboard',

  components: {
    DashboardCategoryExpenses,
    DashboardMainBlock,
    DashboardOperations,
    DashboardCounts
  },

  setup () {
    const accountStore = useAccountStore()
    const operationStore = useOperationStore()

    const { isDeferredAccount } = storeToRefs(accountStore)
    const { dashboard } = storeToRefs(operationStore)

    return { dashboard, isDeferredAccount, operationStore }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    dashboardBlocks () {
      return featureParams(EFeature.DashboardBlockList)?.blocks?.map(name => {
        if (name === 'last_incomes') {
          return {
            component: DashboardOperations,
            props: { operations: this.dashboard.last_operations_positive, isRevenue: true }
          }
        }

        if (name === 'last_expenses') {
          return {
            component: DashboardOperations,
            props: { operations: this.dashboard.last_operations_negative, isRevenue: false }
          }
        }

        if (name === 'last_operations') {
          return {
            component: DashboardOperations,
            props: { operations: this.dashboard.last_operations }
          }
        }

        if (name === 'counts') {
          return {
            component: DashboardCounts,
            props: { dashboard: this.dashboard }
          }
        }

        if (name === 'category_expenses') {
          return {
            component: DashboardCategoryExpenses,
            props: { expenses: this.dashboard.expenses_by_categories }
          }
        }

        if (name === 'users_expenses') {
          return {
            component: DashboardUsersExpenses,
            props: { users: this.dashboard.expenses_by_users }
          }
        }

        if (name === 'last_statements') {
          return {
            component: DashboardStatements,
            props: { statements: this.dashboard.last_statements }
          }
        }

        return null
      }).filter(item => item) || []
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
    this.$bus.on('operation-update-success', this.reloadData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
    this.$bus.off('operation-update-success', this.reloadData)
  },

  methods: {
    async initData (showLoader = true) {
      this.loading = showLoader

      const items = [
        (hasFeature(EFeature.DashboardLastExpensesBlock) || hasFeature(EFeature.DashboardLastIncomesBlock)) ? 'last_operations_separated' : null,
        hasFeature(EFeature.DashboardLastOperationsBlock) ? 'last_operations' : null,
        hasFeature(EFeature.DashboardCountsBlock) ? 'counts' : null,
        hasFeature(EFeature.DashboardCategoryExpensesBlock) ? 'expenses_by_categories' : null,
        hasFeature(EFeature.DashboardUsersExpensesBlock) ? 'expenses_by_users' : null,
        this.isDeferredAccount || hasPermission(permissions.dashboardCardsHolderRead) ? 'transaction_amount_monthly_limit' : null,
        hasFeature(EFeature.DashboardLastStatementsBlock) ? 'last_statements' : null
      ].filter(item => item)

      await this.operationStore.refreshDashboard(items)
      this.loading = false
    },

    reloadData () {
      this.initData(false)
    }
  }
}
</script>

<style lang="stylus">
.section-dashboard
  padding 0 !important

  + .footer
    line-height 2.1rem
    border-top 1px solid $colorLightGray

.dashboard
  display flex
  flex-wrap wrap
  min-width (120rem - $sidebarWidth)

  &__block
    position relative
    display flex
    flex-direction column
    width 50%
    height 50vh
    min-height 40rem
    transition background-color 0.3s ease

    &--row
      flex-direction row
      > div
        flex 1
        display flex
        flex-direction column
        align-items stretch
        > .dashboard__block
          width 100%
          min-height 14rem

    &--dark
      background-color $colorDarkGray
      color $colorWhite

    &--mid
      background-color $colorMidGray
      color $colorWhite

    &--light
      background-color $colorBG
      color $colorMidGray

    &--white
      background-color $colorWhite
      color $colorMidGray

    .number-label
      white-space nowrap
      font-size 6rem
      line-height 1
      letter-spacing 0
      margin auto
      +min-screen($lg-screen)
        font-size 7rem
      +min-screen($xxl-screen)
        font-size 8rem

      &__dec
        font-size 0.72em

    .sub-label
      position absolute
      bottom clamp(3.4rem, 4.2vh, 5rem)
      left 0
      width 100%
      text-align center
      font-size 1.3rem
      font-weight 500
      text-transform uppercase
      line-height 1
      transition color 0.3s ease

  a.dashboard__block:hover
    background-color $sidebarBG
    .sub-label
      color $white

  .loader-spinner
    margin auto !important

// TABLE BLOCKS
.dashboard__block
  &--table
    padding $dashboard-block-padding-top $dashboard-block-padding-right

    .empty-text
      font-size 1.8rem
      color alpha($colorShinyGray, 0.6)
      margin auto

  &__heading
    display flex
    justify-content space-between
    align-items center
    margin-bottom 2rem

    .title
      margin-bottom 0
      padding-left .7rem
      font-size 2.8rem
      font-weight normal
      color $colorDarkGray

    .view-more
      margin-top 1.3rem
      font-size $fontSizeBase
      color $colorShinyGray
      &:hover
        color $colorMidGray

.list-transaction
  width 100%
  padding 0
  list-style none

  > li
    padding clamp(1.6rem, calc(1.6rem + 0.042 * (100vh - 930px)), 2.8rem) 1rem
    display flex
    align-items center
    cursor pointer

    &:hover
      background-color $table-bg-hover

    &:not(:first-child)
      border-top 1px solid $colorLightGray

    > span
      overflow hidden
      white-space nowrap
      text-overflow ellipsis

    .category-icon
      width 32px
      height 32px
      display flex
      align-items center
      justify-content center
      background #eef1f3
      border-radius 8px

    .category-icon
    .picture
      margin-right 18px
      margin-bottom -5px
      margin-top -5px

    .date
      padding-right 1.5rem
      color $colorShinyGray

    .name
      padding-right 1.5rem
      flex 1

    .count
      padding-right 1.5rem

    .amount
      padding-right 1.5rem
      font-weight 500

      &--credit
        color $colorGreen

      &--debit
        color $colorRed

    .attachment
      display flex
      .btn-attachment
        margin auto
        display flex
        justify-content center
        align-items center
        width 2rem
        opacity 0.7
        &:hover
          opacity 1
</style>
