<template>
  <section class="section section-creditcards section-ecards">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.cards') }}
        </h1>
        <CoreBadge v-if="hasOnlyVirtualCards"
                   :theme="ECoreBadgeTheme.VirtualCard"
                   :value="$tc('general.virtual_f', numberOfResults)"
                   class="badge--title" />

        <tabs v-else
              is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <list-actions :physical-card="physicalCard"
                      @lock-all-cards="lockAllCards"
                      @unlock-all-cards="onUnlockAllCards"
                      @export-cards="exportCards" />
      </div>
    </div>
    <component-filters :list-filter="cardsListFilters"
                       :card-group="physicalCard ? 'physical' : 'virtual'"
                       :number-of-results="numberOfResults"
                       @filters="filters" />
    <router-view :key="$route.name"
                 :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { CoreBadge, ECoreBadgeTheme } from '@common/core-ui'
import { storeToRefs } from 'pinia'

import { downloadFileFromUrl } from '@/helpers/utils'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useCardStore } from '@/stores/card'
import { ECardStatusGroup } from '@/types/card.d'
import { EFilter } from '@/types/filter.d'

import ComponentFilters from '@/components/Filter.vue'
import Tabs from '@/components/Tabs.vue'

import ListActions from './components/ListActions.vue'
import LockAllCards from './modal/LockAllCards.vue'

export default {
  name: 'PageCards',

  components: {
    CoreBadge,
    Tabs,
    ListActions,
    ComponentFilters
  },

  props: {
    physicalCard: {
      type: Boolean,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const cardStore = useCardStore()

    const { account, physicalCardTypes } = storeToRefs(accountStore)

    return { account, appStore, cardStore, physicalCardTypes }
  },

  data () {
    return {
      filter: {
        expiration_date: null,
        search: null,
        status: [],
        type: ''
      },

      numberOfResults: null
    }
  },

  computed: {
    ECoreBadgeTheme () {
      return ECoreBadgeTheme
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.physical_cards'),
          route: { name: 'cards-physical' },
          active: this.physicalCard
        },
        {
          label: this.$i18n.t('button.virtual_cards'),
          route: { name: 'cards-virtual' },
          active: !this.physicalCard,
          dot: this.account.counts.virtual_cards.waiting_validation
        }
      ]
    },

    hasOnlyVirtualCards () {
      return !this.hasPermission(this.$permissions.cardsPhysicalRead) &&
        this.hasPermission([this.$permissions.cardsVirtualRead, this.$permissions.cardsVirtualRequest])
    },

    cardsListFilters () {
      return [
        EFilter.Search,
        EFilter.Expiration,
        EFilter.Status,
        this.physicalCardTypes.length > 1 ? EFilter.CardType : null
      ]
    }
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        expiration_date: null,
        search: null,
        status: [],
        type: ''
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        expiration_date: filters.expiration_date,
        search: filters.search,
        status: filters.status,
        type: filters.type
      }
    },

    lockAllCards (grp) {
      this.appStore.showModal(LockAllCards, { group: grp }, { wrapperClass: 'modal--sm' })
    },

    onUnlockAllCards (grp) {
      this.cardStore.unlockAllCards(grp)
    },

    exportCards () {
      const params = {
        status_group: [ECardStatusGroup.Active, ECardStatusGroup.Inactive, ECardStatusGroup.ToValidate, ECardStatusGroup.Pending]
      }
      downloadFileFromUrl('/cards/export', params)
    }
  }
}
</script>
