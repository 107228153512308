<template>
  <section class="section section-users">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t('title.users') }}
        </h1>
        <tabs is-section
              :data="sections" />
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters />
          <component-popover popover-class="popover-actions-menu popover--pull-right"
                             trigger="hover">
            <template #trigger>
              <button class="btn btn--icon btn--white">
                <i class="btn-icon">
                  <ic-settings class="ic ic--gray" />
                </i>
              </button>
            </template>
            <div v-if="hasMultipleInvitationFeature"
                 class="popover__item"
                 @click="$router.push({ name: 'users-import' })">
              <div class="popover__item__icon">
                <ic-import class="ic ic--16 ic--gray" />
              </div>
              <div>{{ $t('button.import_users') }}</div>
            </div>
            <div class="popover__item"
                 @click="exportUsers">
              <div class="popover__item__icon">
                <ic-export class="ic ic--gray" />
              </div>
              <div>{{ $t('button.export_all_users') }}</div>
            </div>
          </component-popover>
          <router-link v-if="hasInvitationsWritePermission"
                       class="btn btn--primary"
                       :to="{ name: 'user-invite' }">
            {{ $t('button.invite_user') }}
          </router-link>
        </div>
      </div>
    </div>
    <component-filters :list-filter="[EFilter.Search]"
                       :number-of-results="numberOfResults"
                       @filters="filters" />
    <tabs :data="segments" />
    <router-view :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { downloadFileFromUrl } from '@/helpers/utils'
import { useInvitationStore } from '@/stores/invitation'
import { EFilter } from '@/types/filter.d'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcExport from '@/components/svg/icons/ic-export.vue'
import IcImport from '@/components/svg/icons/ic-import.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'PageUsers',

  components: {
    IcSettings,
    IcImport,
    IcExport,
    ButtonFilters,
    Tabs,
    ComponentFilters,
    ComponentPopover
  },

  setup () {
    const invitationStore = useInvitationStore()

    const { numberOfPendingInvitations } = storeToRefs(invitationStore)

    return { numberOfPendingInvitations }
  },

  data () {
    return {
      numberOfResults: null,
      filter: {
        search: null
      }
    }
  },

  computed: {
    EFilter () {
      return EFilter
    },

    sections () {
      return [
        {
          label: this.$i18n.t('button.users'),
          route: { name: 'users' },
          active: true,
          visible: true
        },
        {
          label: this.$i18n.t('button.teams'),
          route: { name: 'teams' },
          active: false,
          visible: hasFeature(EFeature.Team)
        }
      ].filter(route => route.visible)
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.actives_m'),
          route: { name: 'users' },
          visible: true
        },
        {
          label: this.$i18n.t('button.pending'),
          route: { name: 'users-pending' },
          badgeValue: this.numberOfPendingInvitations,
          visible: this.numberOfPendingInvitations
        },
        {
          label: this.$i18n.t('button.revoked'),
          route: { name: 'users-revoked' },
          visible: true
        }
      ].filter(route => route.visible)
    },

    hasUsersWritePermission () {
      return this.hasPermission(this.$permissions.usersWrite)
    },

    hasInvitationsWritePermission () {
      return this.hasPermission(this.$permissions.invitationsWrite)
    },

    hasMultipleInvitationFeature () {
      return hasFeature(EFeature.InvitationCreationMutiple)
    }
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    initData () {
      this.numberOfResults = null
      this.filter = {
        search: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search
      }
    },

    exportUsers () {
      downloadFileFromUrl('/users/export')
    }
  }
}
</script>
