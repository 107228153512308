export enum EEventAffectation {
  Delete = 'delete-affectation',
}

export enum EEventCategory {
  Delete = 'delete-category',
}

export enum EEventDropdown {
  NewItemAdded = 'on-new-item-added'
}

export type TEvents = {
  [EEventCategory.Delete]: number,
  [EEventAffectation.Delete]: number,
  [EEventDropdown.NewItemAdded]: unknown
}
