import { cloneDeep } from 'lodash'

import axiosClient from '@/api'
import { decryptPin, encryptPin, encryptWithPublicKey, gcmDecrypt, getRandomBytes } from '@/helpers/utils/cipher'

async function getCardPublicKey (cardUuid: string|null = null) {
  try {
    const { data } = await axiosClient.get('/cards/public-key', { params: { uuid: cardUuid } })

    return data?.public_key || null
  } catch (error) {
    console.error(error)
    return null
  }
}

async function getEncryptedSessionKey (sessionKey: string, cardUuid: string|null = null, label: string|null = null) {
  const publicKey = await getCardPublicKey(cardUuid)
  if (!publicKey) return null

  return encryptWithPublicKey(sessionKey, publicKey, label)
}

async function getWorldlineConvertedData () {
  try {
    const { data } = await axiosClient.get('/cards/converted-data')

    return data || null
  } catch (error) {
    return null
  }
}

async function getWorldlineCardConvertedId (cardUuid: string) {
  try {
    const { data } = await axiosClient.get(`/cards/${cardUuid}/converted-data`)

    return data?.converted_id || null
  } catch (error) {
    return null
  }
}

export async function getWorldlineCardPin (cardUuid: string) {
  try {
    const sessionKey = getRandomBytes()
    const encryptedSessionKey = await getEncryptedSessionKey(sessionKey, cardUuid)
    if (!encryptedSessionKey) return null
    const { data } = await axiosClient.get(`/cards/${cardUuid}/pin`, { params: { session_key: encryptedSessionKey } })

    return decryptPin(data.pin, data.pin_decryption_params.converted_id, sessionKey)
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getWorldlineCardSecretInformations (cardUuid: string) {
  try {
    const sessionKey = getRandomBytes()
    const encryptedSessionKey = await getEncryptedSessionKey(sessionKey, cardUuid, 'CVV')
    if (!encryptedSessionKey) return null

    const { data } = await axiosClient.get(`/cards/${cardUuid}/digits`, { params: { session_key: encryptedSessionKey } })

    const cvv = gcmDecrypt(
      data.cvv_number,
      sessionKey,
      data.cvv_decryption_params.initialization_vector,
      data.cvv_decryption_params.authentication_tag
    )

    return {
      pan: data.pan,
      cvv,
      expiration_date: data.expiration_date
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export async function getWorldlineUpdateCardPinPayload (uuid: string, payload: { pin: string, pin_confirm: string, session_key: string }) {
  const cardPayload = cloneDeep(payload)
  const sessionKey = getRandomBytes()
  const encryptedSessionKey = await getEncryptedSessionKey(sessionKey, uuid)
  const convertedId = await getWorldlineCardConvertedId(uuid)
  if (!encryptedSessionKey || !convertedId) throw new Error('missing data')
  cardPayload.session_key = encryptedSessionKey
  const randomHex = getRandomBytes(8)
  cardPayload.pin = encryptPin(cardPayload.pin, convertedId, sessionKey, randomHex)
  cardPayload.pin_confirm = encryptPin(cardPayload.pin_confirm, convertedId, sessionKey, randomHex)
  return cardPayload
}

export async function getWorldlineOrderCardPayload (payload: { pin: string, session_key: string, request_id: string }) {
  const cardPayload = cloneDeep(payload)
  const convertedData = await getWorldlineConvertedData()
  const sessionKey = getRandomBytes()
  const encryptedSessionKey = await getEncryptedSessionKey(sessionKey)
  if (!encryptedSessionKey || !convertedData?.converted_id || !convertedData?.request_id) throw new Error('missing data')
  cardPayload.session_key = encryptedSessionKey
  cardPayload.request_id = convertedData.request_id
  cardPayload.pin = encryptPin(cardPayload.pin, convertedData.converted_id, sessionKey)
  return cardPayload
}
