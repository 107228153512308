import { useAnalyticStore } from '@/stores/analytics'
import { ECookieStatus } from '@/types/analytics.d'

async function loadLogRocket () {
  if (import.meta.env.VITE_LOGROCKET_ACTIVE === 'true' && useAnalyticStore().cookies.analytics === ECookieStatus.CONSENTED) {
    const { default: LogRocket } = await import('logrocket')
    return LogRocket
  }
  return null
}

export async function initLogRocketInvitation () {
  const LogRocket = await loadLogRocket()
  if (LogRocket) {
    LogRocket.init('managerone/client-subuser-register', {
      console: {
        shouldAggregateConsoleErrors: true
      },
      network: {
        requestSanitizer: request => {
          if (request.body) {
            request.body = null
          }
          return request
        }
      }
    })
  }
}

export async function initLogRocketFollowup (uuid: string, name: string, email: string) {
  const LogRocket = await loadLogRocket()
  if (LogRocket) {
    LogRocket.init('managerone/client-followup', {
      console: {
        shouldAggregateConsoleErrors: true
      }
    })

    LogRocket.identify(uuid, {
      name,
      email
    })
  }
}

export async function trackLogRocket (uuid: string, name: string, email: string) {
  const LogRocket = await loadLogRocket()
  if (LogRocket) {
    LogRocket.track('registered')
    LogRocket.identify(uuid, {
      name,
      email
    })
    setTimeout(() => {
      LogRocket.uninstall()
    }, 30000)
  }
}

export async function uninstallLogRocket () {
  const LogRocket = await loadLogRocket()
  if (LogRocket) {
    LogRocket.uninstall()
  }
}
