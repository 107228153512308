<template>
  <fieldset v-if="hasSettingField('limit_transaction')"
            data-cy="cards.settings.section.limit-transaction"
            class="form__fieldset">
    <group-title :title="$t('cards_settings.limit_transaction.title')" />
    <fieldset-block class="limit-block">
      <limits-per-period v-if="hasSettingField('limit_transaction')"
                         :placeholder="false"
                         :value="settings.limit_transaction"
                         :disabled="disabled"
                         :display-currency="false"
                         item-class="bordered-box__item"
                         data-cy="cards.settings.limit_transaction"
                         @update-limit-settings="limit => updateLimitSettings('limit_transaction', limit.period, limit.value)" />
    </fieldset-block>
  </fieldset>
</template>

<script>
import { merge } from 'lodash'

import FieldsetBlock from '@/components/FieldsetBlock.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import LimitsPerPeriod from '@/components/limits/LimitsPerPeriod.vue'

export default {
  components: {
    FieldsetBlock,
    LimitsPerPeriod,
    GroupTitle
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    }
  },

  emits: ['update-card-settings'],

  data () {
    return {
      periods: ['daily', 'weekly', 'monthly', 'annually']
    }
  },

  methods: {
    hasSettingField (key) {
      return Object.prototype.hasOwnProperty.call(this.settings, key)
    },

    updateLimitSettings (key, period, value) {
      const settings = merge(this.settings[key], { [period]: value })
      this.$emit('update-card-settings', {
        [key]: settings
      })
    }
  }
}
</script>

<style lang="stylus">
.limit-block
  .limits-per-period
    .limit-item
      height 7.7rem
</style>
