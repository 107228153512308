<template>
  <validation-form ref="generateCardForm"
                   v-slot="{ errors, meta, isSubmitting }"
                   :validation-schema="schemas[activeStep]"
                   @keydown.enter.prevent
                   @submit="onSubmit"
                   @invalid-submit="onInvalidSubmit">
    <div class="section__content">
      <component-steps :steps="[$t('order_card.step.owner_infos'), $t('order_card.step.card_settings'), $t('order_card.step.card_summary')]"
                       :active-step="activeStep" />
      <div class="form form--w1000 form--centered">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('message.warning.form_not_valid')"
                         :wrapper-class="''" />
        <component-alert v-else-if="displayVirtualCardFees"
                         type="info"
                         :message="cardFeesLabel"
                         data-cy="cards.create.form.fees"
                         :show-api-messages="false" />
        <keep-alive>
          <component :is="currentStep"
                     v-model:card="card"
                     :errors="errors"
                     :hide-user-select="isOwner"
                     :is-user-owner="isOwner"
                     :invitation="card.invitation"
                     @disable-submit="disableSubmit"
                     @is-new-user="toggleIsNewUser"
                     @update-card-settings="updateCardSettings"
                     @update-card-field="updateCardField"
                     @user-selected="toggleUserSelected" />
        </keep-alive>
        <i18n-t v-if="isLastStep"
                tag="div"
                class="form-terms"
                keypath="form.cards.order.terms.label"
                scope="global">
          <template #button_label>
            {{ buttonLabel }}
          </template>
          <template #general_conditions>
            <a class="terms-link"
               :href="links.STATIC.CARDS.CGU_VIRTUAL"
               target="_blank">{{ $t('prompt.cards.legal_informations.cgu_link') }}</a>
          </template>
          <template #information_notice_assist>
            <i18n-t tag="span"
                    keypath="prompt.cards.legal_informations.assistance"
                    scope="global">
              <template #link>
                <a class="terms-link"
                   :href="links.STATIC.VISA.ASSISTANCE"
                   target="_blank">{{ $t('prompt.cards.legal_informations.assistance_link') }}</a>
              </template>
            </i18n-t>
          </template>
          <template #information_notice_insurance>
            <i18n-t tag="span"
                    keypath="prompt.cards.legal_informations.insurance"
                    scope="global">
              <template #link>
                <a class="terms-link"
                   :href="links.STATIC.VISA.INSURANCE"
                   target="_blank">{{ $t('prompt.cards.legal_informations.insurance_link') }}</a>
              </template>
            </i18n-t>
          </template>
        </i18n-t>
        <div class="form-buttons">
          <button class="btn btn--gray"
                  @click.prevent.stop="previousStep">
            {{ isFirstStep ? $t('button.cancel') : $t('button.previous') }}
          </button>
          <button class="btn btn--primary"
                  :disabled="!meta.valid || isSubmitting || isSubmitToDisable"
                  data-cy="cards.create.form.submit"
                  @click.prevent.stop="onSubmit">
            {{ buttonLabel }}
          </button>
        </div>
      </div>
    </div>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { EFeature, hasFeature } from '@/config/features'
import links from '@/config/links'
import {
  isClassicVirtualCard,
  isOneTimeVirtualCard,
  isRecurringVirtualCard
} from '@/helpers/utils/card'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useCardStore } from '@/stores/card'

import BankValidationRequest from '@/pages/cards/modal/BankValidationRequest.vue'
import CardSettings from '@/pages/cards/order/components/CardSettings.vue'
import OwnerInformation from '@/pages/cards/order/components/OwnerInformation.vue'

import ComponentAlert from '@/components/Alert.vue'
import ComponentSteps from '@/components/Steps.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

import CardSummary from './components/CardSummary.vue'

export default {
  components: {
    ComponentAlert,
    ComponentSteps,
    IcArrow,
    OwnerInformation,
    CardSettings,
    CardSummary
  },

  props: {
    role: {
      type: String,
      required: true
    },

    type: {
      type: String,
      default: 'card'
    }
  },

  emits: ['previous-step'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const apiStore = useApiStore()
    const authStore = useAuthStore()
    const cardStore = useCardStore()

    const { account, virtualCardTypes } = storeToRefs(accountStore)
    const { cards } = storeToRefs(cardStore)
    const { loading: apiLoading, error: apiError } = storeToRefs(apiStore)
    const { user } = storeToRefs(authStore)

    return {
      cardStore,

      account,
      appStore,
      apiError,
      apiLoading,
      cards,
      user,
      virtualCardTypes
    }
  },

  data () {
    return {
      hasUserName: false,
      editShippingAddress: false,
      card: {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        picture: this.user.picture,
        user_uuid: null,
        expiration_date: null,
        months_before_expiration: null,
        label: null,
        reason: null,
        fees: null,
        settings: {
          initial_amount: '',
          authorization_range: {
            monday: {
              enabled: true,
              start: null,
              end: null
            },

            tuesday: {
              enabled: true,
              start: null,
              end: null
            },

            wednesday: {
              enabled: true,
              start: null,
              end: null
            },

            thursday: {
              enabled: true,
              start: null,
              end: null
            },

            friday: {
              enabled: true,
              start: null,
              end: null
            },

            saturday: {
              enabled: true,
              start: null,
              end: null
            },

            sunday: {
              enabled: true,
              start: null,
              end: null
            }
          },

          authorization_categories: [],
          country_holidays: this.account.settings.default_card_country_holidays,
          non_working_day: true,
          nfc: true,
          distance_selling: true,
          magnetic_strip: true,
          fx: true,
          proof_required: false,
          proof_required_delay: null,
          proof_required_delay_label: '',
          display_limit: true,
          mobile_payment: true,
          payment: {
            daily: {
              enabled: false,
              limit: 0
            },

            weekly: {
              enabled: false,
              limit: 0
            },

            monthly: {
              enabled: false,
              limit: 0
            },

            annually: {
              enabled: false,
              limit: 0
            }
          },

          payment_transaction: {
            enabled: false,
            limit: 0
          }
        },

        invitation: {
          email: '',
          role: 'employee',
          role_label: '',
          company: this.account.company.name,
          affectations: []
        },

        periodicity: 'monthly'
      },

      userSelectorIsOpen: false,
      userInput: '',
      isNewUser: false,
      choice: null,
      userSelected: null,

      hasMissingInfos: false,
      steps: [
        'owner-information',
        'card-settings',
        'card-summary'
      ],

      activeStep: 0,
      submitDisabled: false,
      accept: false,
      links,
      loadingCardCost: true
    }
  },

  computed: {
    isCardRequest () {
      return this.hasPermission(this.$permissions.cardsVirtualRequest)
    },

    buttonLabel () {
      return this.isLastStep
        ? (this.isCardRequest ? this.$i18n.t('button.request_the_ecard') : this.$i18n.t('button.generate_the_ecard'))
        : this.$i18n.t('button.next')
    },

    isSubmitToDisable () {
      return this.submitDisabled
    },

    currentStep () {
      return this.steps[this.activeStep]
    },

    isFirstStep () {
      return this.activeStep === 0
    },

    isLastStep () {
      return this.activeStep === this.steps.length - 1
    },

    isOneTimeCard () {
      return isOneTimeVirtualCard(this.card)
    },

    isRecurringCard () {
      return isRecurringVirtualCard(this.card)
    },

    isVirtualCardClassic () {
      return isClassicVirtualCard(this.card)
    },

    userSchema () {
      if (this.userSelected) {
        return yup.object({
          first_name: yup.string().required(),
          last_name: yup.string().required()
        })
      }

      if (this.isNewUser) {
        return yup.object({
          first_name: yup.string().required(),
          last_name: yup.string().required(),
          email: yup.string().required(),
          phone: yup.string().validPhoneNumber().nullable()
        })
      }

      return {}
    },

    cardSettingsSchema () {
      return yup.object({
        initial_amount: this.isVirtualCardClassic ? yup.string() : yup.string().required(),
        expiration: yup.number().required()
      })
    },

    schemas () {
      return [
        this.userSchema,
        this.cardSettingsSchema,
        {}
      ]
    },

    cardFeesLabel () {
      if (this.card.fees === null || this.card.fees === 0) return null
      return `${this.$i18n.t('prompt.cards.generate.cost.label')} ${this.$i18n.t('prompt.cards.generate.cost.pay', { price: formatAmount(this.card.fees) })}`
    },

    isOwner () {
      return this.role === 'owner'
    },

    hasVirtualCardFeesPermission () {
      return this.hasPermission(this.$permissions.cardsVirtualFeesRead)
    },

    displayVirtualCardFees () {
      return this.isFirstStep && !this.loadingCardCost && this.cardFeesLabel && this.hasVirtualCardFeesPermission
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.generateCardForm.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    this.card.type = this.type

    if (this.isOneTimeCard) { // remove required proof option
      delete this.card.settings.proof_required
      delete this.card.settings.payment
      delete this.card.settings.payment_transaction
    }

    if (hasFeature(EFeature.CardAutomaticResetInitialAmount)) {
      this.card.settings.automatic_reset_initial_amount = true
    }

    this.fetchCardFees()
  },

  methods: {
    formatCard () {
      const card = {
        type: this.card.type,
        phone: this.card.phone,
        label: this.card.label,
        metadata: {
          reason: this.card.reason
        },

        months_before_expiration: this.card.months_before_expiration,
        settings: { ...this.card.settings }
      }

      if (this.userSelected || this.isNewUser) {
        card.user_uuid = this.card.user_uuid
      }

      if (this.isNewUser) {
        card.first_name = this.card.first_name
        card.last_name = this.card.last_name
        card.invitation = this.card.invitation
      }

      if (this.isRecurringCard) {
        for (const period in card.settings.payment) {
          if (period === this.card.periodicity) {
            card.settings.payment[period].enabled = true
            card.settings.payment[period].limit = card.settings.initial_amount
          } else {
            card.settings.payment[period].enabled = false
            card.settings.payment[period].limit = null
          }
        }
        delete card.settings.initial_amount
      }

      return card
    },

    async fetchCardFees () {
      if (!this.hasVirtualCardFeesPermission) return
      this.loadingCardCost = true
      this.card.fees = await this.cardStore.getCardOrderFees(this.type)
      this.loadingCardCost = false
    },

    onInvalidSubmit () {
      return this.scrollTopHandler()
    },

    async onSubmit () {
      const card = this.formatCard()

      if (this.isLastStep) {
        await this.orderCard(card)
      } else {
        this.activeStep += 1
        this.scrollTopHandler()
      }
    },

    async orderCard (card) {
      this.disableSubmit(true)

      const orderedCard = await this.cardStore.orderCard(card, this.account.card_partner)
      return orderedCard ? this.onOrderSuccess(orderedCard) : this.onOrderFailed()
    },

    onOrderSuccess (orderedCard) {
      if (orderedCard.status === 'to_validate_by_bank') {
        return this.$router.push({ name: 'cards-virtual-to-validate' }).then(() => {
          this.appStore.showModal(BankValidationRequest)
        })
      }

      if (orderedCard.status === 'to_validate') {
        showToastSuccess(this.$t('message.success.card.to_validate'))
        return this.$router.push({ name: 'cards-virtual-to-validate' })
      }

      showToastSuccess(this.$t('message.success.card.generate'))
      return this.$router.push({ name: 'cards-detail', params: { uuid: orderedCard.uuid } })
    },

    onOrderFailed () {
      this.disableSubmit(false)
    },

    previousStep () {
      if (this.isFirstStep) {
        return this.$emit('previous-step')
      }

      this.activeStep -= 1
      this.scrollTopHandler()
    },

    disableSubmit (value) {
      this.submitDisabled = value
    },

    scrollTopHandler () {
      this.$bus.emit('scrollToTop')
    },

    toggleIsNewUser (value) {
      this.isNewUser = value
    },

    toggleUserSelected (value) {
      this.userSelected = value
    },

    updateCardSettings (data) {
      this.card.settings = data
      this.$bus.emit('card-updated')
    },

    updateCardField (data) {
      Object.keys(data).forEach(key => {
        this.card[key] = data[key]
      })
    }
  }
}
</script>
