import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'

import { features } from './config'

export enum EFeature {
  App,
  LoginAnimations,
  LoginSignupLink,
  LoginConfigureScaModal,
  DashboardBlockList,
  DashboardLastIncomesBlock,
  DashboardLastExpensesBlock,
  DashboardLastOperationsBlock,
  DashboardCountsBlock,
  DashboardCategoryExpensesBlock,
  DashboardCategoryExpensesChartBlock,
  DashboardUsersExpensesBlock,
  DashboardLastStatementsBlock,
  OperationCard,
  SectionAccountOverview,
  SectionOperation,
  SectionTransfer,
  SectionRequest,
  SectionDebit,
  SectionCardPhysical,
  SectionCardVirtual,
  SectionCard,
  SectionCredit,
  SectionRefund,
  SectionMileage,
  SectionStatement,
  SectionAccounting,
  SectionTreasury,
  SectionLoyalty,
  SectionUser,
  SectionInbox,
  Inbox,
  Team,
  AccountOverviewContract,
  AccountOverviewEstimatedInterestEarned,
  AccountOverviewTaxType,
  AccountOverviewAnniversaryDate,
  AccountCardControlRule,
  AccountCardControlRuleEdition,
  AccountCheckRemittanceSlip,
  OperationDetailChargeBack,
  OperationDetailChargeBackEdition,
  OperationDetailValueDate,
  OperationDetailAccountingDate,
  OperationDetailSettlementDate,
  OperationDetailVat,
  OperationDetailVatEdition,
  OperationExport,
  OperationIncomingTransfer,
  Transfer,
  TransferBatchValidation,
  TransferFilter,
  TransferSegmentCancelled,
  TransferAction,
  TransferEdition,
  TransferCancellation,
  TransferDocument,
  TransferDocumentUpload,
  TransferCategory,
  TransferCategoryEdition,
  TransferCategoryDetail,
  TransferAffectation,
  TransferAffectationEdition,
  TransferComment,
  TransferCommentEdition,
  TransferDetailInitiator,
  TransferDetailCreationDate,
  TransferDetailExecutionDate,
  TransferDetailExpenseDate,
  TransferDetailExpenseDateEdition,
  TransferDetailApprovalDetail,
  TransferDetailCertificateDownload,
  TransferDetailValidation,
  TransferDetailCancellation,
  TransferPeriodic,
  TransferPeriodicAction,
  TransferPeriodicEdition,
  TransferPeriodicCancellation,
  TransferPeriodicSuspension,
  TransferPeriodicUpcomingTransfers,
  TransferPeriodicDetailInitiator,
  TransferPeriodicDetailCreationDate,
  TransferPeriodicDetailStartDate,
  TransferPeriodicDetailEndDate,
  TransferPeriodicDetailLastPastDeadlines,
  TransferCreation,
  TransferAmountEdition,
  TransferLabelEdition,
  TransferCreationType,
  TransferCreationDelayed,
  TransferCreationPeriodic,
  TransferCreationModeFees,
  TransferCreationMultiple,
  TransferCreationMultipleSepa,
  TransferCreationMultiplePayslips,
  TransferCreationMultipleAdvancedPayments,
  TransferAdvancedPayment,
  TransferApprovalFlow,
  Beneficiary,
  BeneficiaryInternational,
  CreditDocument,
  SmartCard,
  SmartCardEdition,
  Sponsorship,
  AccountRIB,
  AccountCompany,
  AccountCompanyCreation,
  AccountCompanyAccountClosing,
  AccountService,
  CardBlockFooter,
  CardPhysicalEdition,
  CardVirtualEdition,
  CardVirtualRequest,
  CardActivation,
  CardBlock,
  CardCancelAutomaticDeletion,
  CardLock,
  CardPin,
  CardPinEdition,
  CardPinMailer,
  CardRequestIncrease,
  CardSettings,
  CardSettingsEdition,
  CardDetailWithdrawalAlwaysVisible,
  CardWebWallet,
  CardCreateMultiple,
  CardAutomaticResetInitialAmount,
  CardControlRule,
  CardControlRuleEdition,
  CardDefaultSettings,
  CardCreateCustomPin,
  InvitationCreationMutiple,
  Mileage,
  UserIdentityProofRequired,
  UserInvitationMandate,
  FooterContact,
  FooterCookies,
  FooterLegal,
  FooterTerms,
  FooterPricing
}

type ConditionList = {
  [key in EFeature]?: (item?: object | null) => boolean
}

type ParamList = {
  [key in EFeature]?: () => ({
    blocks?: string[]
    filters?: string[]
    format?: string
    periods?: string[]
    physicalTypes?: string[]
    physicalOrderTypes?: string[]
  })
}

const conditions: ConditionList = {
  // App
  [EFeature.App]: () => features.app.enabled,

  // Sections
  [EFeature.SectionAccountOverview]: () => features.accountOverview.enabled,
  [EFeature.SectionOperation]: () => features.operation.enabled,
  [EFeature.SectionTransfer]: () => features.transfer.enabled,
  [EFeature.SectionRequest]: () => features.request.enabled,
  [EFeature.SectionDebit]: () => features.debit.enabled,
  [EFeature.SectionCardPhysical]: () => features.card.physical.enabled,
  [EFeature.SectionCardVirtual]: () => features.card.virtual.enabled,
  [EFeature.SectionCard]: () => hasFeature(EFeature.SectionCardPhysical) || hasFeature(EFeature.SectionCardVirtual),
  [EFeature.SectionCredit]: () => features.credit.enabled,
  [EFeature.SectionRefund]: () => features.refund.enabled,
  [EFeature.SectionMileage]: () => features.mileage.enabled,
  [EFeature.SectionStatement]: () => features.statement.enabled,
  [EFeature.SectionAccounting]: () => features.accounting.enabled,
  [EFeature.SectionTreasury]: () => features.treasury.enabled,
  [EFeature.SectionLoyalty]: () => features.loyalty.enabled,
  [EFeature.SectionUser]: () => features.user.enabled,
  [EFeature.SectionInbox]: () => features.inbox.enabled,

  // Login
  [EFeature.LoginAnimations]: () => features.login.loginAnimations.enabled,
  [EFeature.LoginSignupLink]: () => features.signup.linkOnLogin.enabled,
  [EFeature.LoginConfigureScaModal]: () => features.login.configureScaModal.enabled,

  // Account
  [EFeature.AccountRIB]: () => features.account.rib.enabled && hasPermission(permissions.accountRibRead),
  [EFeature.AccountCompany]: () => features.account.company.enabled,
  [EFeature.AccountCompanyCreation]: () => features.signup.companyCreation.enabled,
  [EFeature.AccountCompanyAccountClosing]: item => features.account.company.account.closing.enabled && hasPermission(permissions.accountOwner, item),
  [EFeature.AccountService]: () => features.account.service.enabled && hasPermission(permissions.accountOwner),
  [EFeature.AccountCardControlRule]: () => features.card.controlRule.enabled && hasPermission(permissions.accountRulesRead),
  [EFeature.AccountCardControlRuleEdition]: () => features.card.controlRule.enabled && hasPermission(permissions.accountRulesWrite),
  [EFeature.AccountCheckRemittanceSlip]: () => features.account.checkRemittanceSlip.enabled && hasPermission(permissions.checkRemittanceSlipRead),

  // Header
  [EFeature.Sponsorship]: () => features.account.sponsorship.enabled && hasPermission(permissions.accountOwner),
  [EFeature.Inbox]: () => hasFeature(EFeature.SectionInbox) && hasPermission(permissions.messagesRead),

  // Footer
  [EFeature.FooterContact]: () => features.footer.contact.enabled,
  [EFeature.FooterCookies]: () => features.footer.cookies.enabled,
  [EFeature.FooterLegal]: () => features.footer.legal.enabled,
  [EFeature.FooterTerms]: () => features.footer.terms.enabled,
  [EFeature.FooterPricing]: () => features.footer.pricing.enabled,

  // Dashboard
  [EFeature.DashboardLastIncomesBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('last_incomes'),
  [EFeature.DashboardLastExpensesBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('last_expenses'),
  [EFeature.DashboardLastOperationsBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('last_operations'),
  [EFeature.DashboardCountsBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('counts'),
  [EFeature.DashboardCategoryExpensesBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('category_expenses'),
  [EFeature.DashboardCategoryExpensesChartBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('category_expenses_chart'),
  [EFeature.DashboardUsersExpensesBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('users_expenses'),
  [EFeature.DashboardLastStatementsBlock]: () => !!featureParams(EFeature.DashboardBlockList)?.blocks?.includes('last_statements'),

  // Account Overview
  [EFeature.AccountOverviewContract]: () => features.accountOverview.contract.enabled,
  [EFeature.AccountOverviewEstimatedInterestEarned]: () => features.accountOverview.estimated_interest_earned.enabled,
  [EFeature.AccountOverviewTaxType]: () => features.accountOverview.tax_type.enabled,
  [EFeature.AccountOverviewAnniversaryDate]: () => features.accountOverview.anniversary_date.enabled,

  // Operations
  [EFeature.OperationCard]: () => hasPermission(permissions.operationsCardsRead),
  [EFeature.OperationDetailChargeBack]: item => hasPermission(permissions.operationsChargeBackRead, item),
  [EFeature.OperationDetailChargeBackEdition]: item => hasPermission(permissions.operationsChargeBackWrite, item),
  [EFeature.OperationDetailValueDate]: () => features.operation.detail.valueDate.enabled,
  [EFeature.OperationDetailAccountingDate]: () => features.operation.detail.accountingDate.enabled,
  [EFeature.OperationDetailSettlementDate]: () => features.operation.detail.settlementDate.enabled,
  [EFeature.OperationDetailVat]: item => features.operation.detail.vat.enabled && hasPermission(permissions.operationsVatRead, item),
  [EFeature.OperationDetailVatEdition]: item => features.operation.detail.vat.edition.enabled && hasPermission(permissions.operationsVatWrite, item),
  [EFeature.OperationExport]: () => hasPermission(permissions.operationsExport),
  [EFeature.OperationIncomingTransfer]: () => features.operation.incomingTransfer.enabled && hasPermission(permissions.operationsIncomingTransfersRead),

  // Transfers
  [EFeature.Transfer]: () => hasFeature(EFeature.SectionTransfer) && hasPermission(permissions.transfersRead),
  [EFeature.TransferBatchValidation]: () => features.transfer.batchValidation.enabled && hasPermission(permissions.transfersBatchValidate),
  [EFeature.TransferFilter]: item => !!features.transfer.filter.length && hasPermission(permissions.transfersRead, item),
  [EFeature.TransferSegmentCancelled]: () => features.transfer.segment.cancelled.enabled,
  [EFeature.TransferAction]: () => features.transfer.segment.all.table.action.enabled && hasPermission(permissions.transfersWrite),
  [EFeature.TransferEdition]: () => features.transfer.segment.all.table.action.edition.enabled && hasPermission(permissions.transfersWrite),
  [EFeature.TransferCancellation]: () => features.transfer.segment.all.table.action.cancellation.enabled && hasPermission(permissions.transfersWrite),
  [EFeature.TransferDocument]: item => features.transfer.unique.document.enabled && hasPermission(permissions.operationsDocumentsRead, item),
  [EFeature.TransferDocumentUpload]: item => hasFeature(EFeature.TransferDocument) && features.transfer.unique.document.upload.enabled && hasPermission(permissions.operationsDocumentsWrite, item),
  [EFeature.TransferCategory]: () => features.transfer.unique.category.enabled,
  [EFeature.TransferCategoryEdition]: () => hasFeature(EFeature.TransferCategory) && features.transfer.unique.category.edition.enabled,
  [EFeature.TransferCategoryDetail]: () => hasFeature(EFeature.TransferCategory) && features.transfer.unique.category.enabled && features.transfer.unique.category.detail.enabled,
  [EFeature.TransferAffectation]: () => features.transfer.unique.affectation.enabled,
  [EFeature.TransferAffectationEdition]: () => features.transfer.unique.affectation.edition.enabled && hasPermission(permissions.transfersAffectationsRead) && hasPermission(permissions.transfersAffectationsWrite),
  [EFeature.TransferComment]: () => features.transfer.unique.comment.enabled,
  [EFeature.TransferCommentEdition]: () => features.transfer.unique.comment.edition.enabled,
  [EFeature.TransferDetailInitiator]: () => features.transfer.segment.all.detail.initiator.enabled,
  [EFeature.TransferDetailCreationDate]: () => features.transfer.segment.all.detail.creationDate.enabled,
  [EFeature.TransferDetailExecutionDate]: () => features.transfer.segment.all.detail.executionDate.enabled,
  [EFeature.TransferDetailExpenseDate]: () => features.transfer.segment.all.detail.expenseDate.enabled,
  [EFeature.TransferDetailExpenseDateEdition]: () => features.transfer.segment.all.detail.expenseDate.edition.enabled,
  [EFeature.TransferDetailApprovalDetail]: () => hasFeature(EFeature.SectionRequest) && features.transfer.segment.all.detail.approvalDetail.enabled,
  [EFeature.TransferDetailCertificateDownload]: () => features.transfer.segment.all.detail.certificateDownload.enabled,
  [EFeature.TransferDetailValidation]: item => features.transfer.segment.all.detail.action.validation.enabled && hasPermission(permissions.transfersValidate, item),
  [EFeature.TransferDetailCancellation]: () => features.transfer.segment.all.detail.action.cancellation.enabled,
  [EFeature.TransferPeriodic]: () => features.transfer.periodic.enabled,
  [EFeature.TransferPeriodicAction]: () => hasFeature(EFeature.TransferPeriodicEdition) || hasFeature(EFeature.TransferPeriodicCancellation) || hasFeature(EFeature.TransferPeriodicSuspension),
  [EFeature.TransferPeriodicEdition]: () => features.transfer.segment.periodic.table.action.edition.enabled && hasPermission(permissions.transfersWrite),
  [EFeature.TransferPeriodicCancellation]: () => features.transfer.segment.periodic.table.action.cancellation.enabled && hasPermission(permissions.transfersWrite),
  [EFeature.TransferPeriodicSuspension]: () => features.transfer.segment.periodic.table.action.suspension.enabled && hasPermission(permissions.transfersWrite),
  [EFeature.TransferPeriodicUpcomingTransfers]: () => features.transfer.periodic.upcomingTransfers.enabled,
  [EFeature.TransferPeriodicDetailInitiator]: () => features.transfer.segment.periodic.detail.initiator.enabled,
  [EFeature.TransferPeriodicDetailLastPastDeadlines]: () => features.transfer.segment.periodic.detail.lastPastDeadlines.enabled,
  [EFeature.TransferCreation]: item => features.transfer.creation.enabled && hasPermission(permissions.transfersWrite, item),
  [EFeature.TransferAmountEdition]: () => features.transfer.creation.unique.amount.edition.enabled,
  [EFeature.TransferLabelEdition]: () => features.transfer.creation.unique.label.edition.enabled,
  [EFeature.TransferCreationType]: () => hasFeature(EFeature.TransferCreationDelayed) || hasFeature(EFeature.TransferPeriodic),
  [EFeature.TransferCreationDelayed]: () => features.transfer.creation.unique.delayed.enabled,
  [EFeature.TransferCreationModeFees]: () => features.transfer.creation.unique.international.modeFees.enabled,
  [EFeature.TransferCreationMultiple]: () => hasFeature(EFeature.TransferCreationMultipleSepa) || hasFeature(EFeature.TransferCreationMultiplePayslips) || hasFeature(EFeature.TransferCreationMultipleAdvancedPayments),
  [EFeature.TransferCreationMultipleSepa]: () => features.transfer.creation.multiple.sepa.enabled,
  [EFeature.TransferCreationMultiplePayslips]: () => features.transfer.creation.multiple.payslips.enabled,
  [EFeature.TransferCreationMultipleAdvancedPayments]: () => features.transfer.advancedPayment.enabled && hasPermission(permissions.transfersAdvancePaymentWrite),
  [EFeature.TransferAdvancedPayment]: () => features.transfer.advancedPayment.enabled && hasPermission(permissions.transfersAdvancePaymentRead),
  [EFeature.TransferApprovalFlow]: () => hasFeature(EFeature.SectionRequest) && hasPermission(permissions.transfersApprovalFlowRead),

  // Beneficiaries
  [EFeature.Beneficiary]: item => features.beneficiary.enabled && hasPermission(permissions.beneficiariesRead, item),
  [EFeature.BeneficiaryInternational]: () => features.beneficiary.international.enabled,

  // Cards
  [EFeature.CardBlockFooter]: () => hasFeature(EFeature.SectionCard) && hasPermission([permissions.cardsVirtualRead, permissions.cardsPhysicalRead]),
  [EFeature.CardPhysicalEdition]: () => features.card.physical.edition.enabled && hasPermission(permissions.cardsPhysicalWrite),
  [EFeature.CardVirtualEdition]: () => features.card.virtual.edition.enabled && hasPermission(permissions.cardsVirtualWrite),
  [EFeature.CardActivation]: item => features.card.physical.activation.enabled && hasPermission(permissions.cardsActivate, item),
  [EFeature.CardBlock]: item => features.card.block.enabled && hasPermission(permissions.cardsBlock, item),
  [EFeature.CardCancelAutomaticDeletion]: item => features.card.virtual.cancelAutomaticDeletion.enabled && hasPermission(permissions.cardsCancelAutomaticDeletion, item),
  [EFeature.CardLock]: item => features.card.lock.enabled && hasPermission(permissions.cardsLock, item),
  [EFeature.CardPin]: item => features.card.pin.read.enabled && hasPermission(permissions.cardsPinRead, item),
  [EFeature.CardPinEdition]: item => features.card.pin.edition.enabled && hasPermission(permissions.cardsPinWrite, item),
  [EFeature.CardPinMailer]: () => features.card.pin.mailer.enabled,
  [EFeature.CardRequestIncrease]: item => features.card.requestIncrease.enabled && hasPermission(permissions.cardsRequestIncrease, item),
  [EFeature.CardSettings]: item => features.card.virtual.settings.read.enabled && hasPermission(permissions.cardsSettingsRead, item),
  [EFeature.CardSettingsEdition]: item => features.card.virtual.settings.edition.enabled && hasPermission(permissions.cardsSettingsWrite, item),
  [EFeature.CardDetailWithdrawalAlwaysVisible]: () => features.card.physical.detail.withdrawal.alwaysVisible.enabled,
  [EFeature.CardWebWallet]: item => features.card.webWallet.enabled && hasPermission(permissions.cardsWebWallet, item),
  [EFeature.CardCreateMultiple]: () => features.card.create.multiple.enabled && hasPermission(permissions.cardsCreateMultiple),
  [EFeature.CardAutomaticResetInitialAmount]: () => features.card.automatic_reset_initial_amount.enabled,
  [EFeature.CardControlRule]: () => features.card.controlRule.enabled && hasPermission(permissions.cardsRulesRead),
  [EFeature.CardControlRuleEdition]: () => features.card.controlRule.enabled && hasPermission(permissions.cardsRulesWrite),
  [EFeature.CardDefaultSettings]: () => features.card.defaultSettings.enabled,
  [EFeature.SmartCard]: item => features.card.physical.smart_card.enabled && hasPermission(permissions.cardsPhysicalSmartCardRead, item),
  [EFeature.SmartCardEdition]: item => features.card.physical.smart_card.enabled && hasPermission(permissions.cardsPhysicalSmartCardWrite, item),
  [EFeature.CardCreateCustomPin]: () => features.card.create.customPin.enabled,
  [EFeature.CardVirtualRequest]: () => features.card.virtual.request.enabled,

  // Credits
  [EFeature.CreditDocument]: () => features.credit.document.enabled,

  // Mileages
  [EFeature.Mileage]: item => hasFeature(EFeature.SectionMileage) && hasPermission(permissions.mileagesRead, item),

  // Users
  [EFeature.Team]: () => features.team.enabled && hasPermission(permissions.teamsRead),
  [EFeature.UserIdentityProofRequired]: () => features.user.identity_proof_required,

  // Invitations
  [EFeature.InvitationCreationMutiple]: () => features.invitation.multiple.enabled && hasPermission(permissions.invitationsCreateMultiple),
  [EFeature.UserInvitationMandate]: () => features.invitation.mandate.enabled
}

const params : ParamList = {
  [EFeature.DashboardBlockList]: () => ({ blocks: hasPermission(permissions.dashboardRead) ? features.dashboard.blocks.owner : hasPermission(permissions.dashboardCardsHolderRead) ? features.dashboard.blocks.cardHolder : [] }),
  [EFeature.TransferFilter]: () => ({ filters: features.transfer.filter }),
  [EFeature.TransferDetailCreationDate]: () => ({ format: features.transfer.segment.all.detail.creationDate.format }),
  [EFeature.TransferDetailExecutionDate]: () => ({ format: features.transfer.segment.all.detail.executionDate.format }),
  [EFeature.TransferDetailExpenseDate]: () => ({ format: features.transfer.segment.all.detail.expenseDate.format }),
  [EFeature.TransferPeriodicDetailCreationDate]: () => ({ format: features.transfer.segment.periodic.detail.creationDate.format }),
  [EFeature.TransferPeriodicDetailStartDate]: () => ({ format: features.transfer.segment.periodic.detail.startDate.format }),
  [EFeature.TransferPeriodicDetailEndDate]: () => ({ format: features.transfer.segment.periodic.detail.endDate.format }),
  [EFeature.TransferCreationPeriodic]: () => ({ periods: features.transfer.creation.unique.periodic.periods })
}

export function hasFeature (feature: EFeature, item: object | null = null) {
  if (!conditions[feature]) {
    console.error(`missing feature ${EFeature[feature]}`)
    return false
  }
  return conditions[feature]?.(item) || false
}

export function featureParams (feature: EFeature) {
  if (!params[feature]) {
    console.error(`missing feature params ${EFeature[feature]}`)
    return null
  }
  return params[feature]?.() || null
}
