import { EFeature, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import FullPage from '@/containers/FullPage.vue'
import LoginContainer from '@/containers/Login.vue'
import Section from '@/containers/Section.vue'
import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'

import Account from '@/pages/account/Account.vue'
import AccountCheckRemittance from '@/pages/account/AccountCheckRemittance.vue'
import accountRIB from '@/pages/account/AccountRIB.vue'
import accountServices from '@/pages/account/AccountServices.vue'
import accountSponsorship from '@/pages/account/AccountSponsorship.vue'
import AccountCompanies from '@/pages/account/companies/AccountCompanies.vue'
import BankAccountsTab from '@/pages/account/companies/components/BankAccountsTab.vue'
import InformationsTab from '@/pages/account/companies/components/InformationsTab.vue'
import SubAccountsList from '@/pages/account/companies/components/SubAccountsList.vue'
import CreateAdditionalAccountForm from '@/pages/account/create-sub-account/create-additional-account/CreateAdditionalAccountForm.vue'
import CreateAdditionalAccountPlaceholder from '@/pages/account/create-sub-account/create-additional-account/CreateAdditionalAccountPlaceholder.vue'
import CreateTermForm from '@/pages/account/create-sub-account/create-term/CreateTermForm.vue'
import CreateTermPlaceholder from '@/pages/account/create-sub-account/create-term/CreateTermPlaceholder.vue'
import CreateAdditionalAccount from '@/pages/account/create-sub-account/CreateAdditionalAccount.vue'
import CreateCapitalGain from '@/pages/account/create-sub-account/CreateCapitalGain.vue'
import CreateCheck from '@/pages/account/create-sub-account/CreateCheck.vue'
import CreateTerm from '@/pages/account/create-sub-account/CreateTerm.vue'
import CreateSubAccount from '@/pages/account/CreateSubAccount.vue'
import accountInfo from '@/pages/account/infos/AccountInfo.vue'
import BankDetails from '@/pages/account/infos/components/BankDetails.vue'
import UserDetails from '@/pages/account/infos/components/UserDetails.vue'
import VehicleDetails from '@/pages/account/infos/components/Vehicle.vue'
import AccountSettings from '@/pages/account/preferences/AccountSettings.vue'
import AccountControlRule from '@/pages/account/preferences/components/AccountControlRule.vue'
import MenuPreferences from '@/pages/account/preferences/components/MenuPreferences.vue'
import NotificationsPreferences from '@/pages/account/preferences/components/NotificationsPreferences.vue'
import ConfigureToken from '@/pages/account/sca/components/ConfigureToken.vue'
import scaConfigure from '@/pages/account/sca/ScaConfigure.vue'
import scaList from '@/pages/account/sca/ScaList.vue'
import AccountOverview from '@/pages/account-overview/AccountOverview.vue'
import Accounting from '@/pages/accounting/Accounting.vue'
import AccountingAccounts from '@/pages/accounting/components/AccountingAccounts.vue'
import AccountingCategories from '@/pages/accounting/components/AccountingCategories.vue'
import BeneficiariesImport from '@/pages/beneficiaries/BeneficiariesImport.vue'
import BeneficiaryList from '@/pages/beneficiaries/BeneficiaryList.vue'
import BeneficiaryLists from '@/pages/beneficiaries/BeneficiaryLists.vue'
import CreateBeneficiary from '@/pages/beneficiaries/CreateBeneficiary.vue'
import CardRequestSettings from '@/pages/cards/CardRequestSettings.vue'
import Cards from '@/pages/cards/Cards.vue'
import CardsListTabs from '@/pages/cards/CardsListTabs.vue'
import CardsList from '@/pages/cards/components/CardsList.vue'
import General from '@/pages/cards/details/General.vue'
import Settings from '@/pages/cards/details/Settings.vue'
import MoneCardDetail from '@/pages/cards/MoneCardDetail.vue'
import OperationsToJustifyList from '@/pages/cards/OperationsToJustifyList.vue'
import OrderCardSteps from '@/pages/cards/order/physical/OrderCardSteps.vue'
import GenerateCardSteps from '@/pages/cards/order/virtual/GenerateCardSteps.vue'
import OrderMultipleCards from '@/pages/cards/OrderMultipleCards.vue'
import CreditsList from '@/pages/credits/components/CreditsList.vue'
import Credits from '@/pages/credits/Credits.vue'
import Dashboard from '@/pages/dashboard/Dashboard.vue'
import DebitsList from '@/pages/debits/components/DebitsList.vue'
import MandatesList from '@/pages/debits/components/MandatesList.vue'
import Debits from '@/pages/debits/Debits.vue'
import Mandates from '@/pages/debits/Mandates.vue'
import DocumentCheck from '@/pages/documents/Check.vue'
import ExpenseRequestsTable from '@/pages/expense_requests/components/ExpenseRequestsTable.vue'
import ExpenseRequests from '@/pages/expense_requests/ExpenseRequests.vue'
import ExpensesList from '@/pages/expenses/components/ExpensesList.vue'
import Expenses from '@/pages/expenses/Expenses.vue'
import CapitalGainFollowup from '@/pages/follow/CapitalGain.vue'
import FollowOpening from '@/pages/follow/Opening.vue'
import TermAccountOpening from '@/pages/follow/TermAccountOpening.vue'
import Inbox from '@/pages/inbox/Inbox.vue'
import CreateAccount from '@/pages/invitation/components/CreateAccount.vue'
import ExistingAccount from '@/pages/invitation/components/ExistingAccount.vue'
import ModeChoice from '@/pages/invitation/components/ModeChoice.vue'
import Invitation from '@/pages/invitation/Invitation.vue'
import Forgot from '@/pages/login/Forgot.vue'
import Login from '@/pages/login/LoginForm.vue'
import LogoutInactivity from '@/pages/login/LogoutInactivity.vue'
import PasswordUpdate from '@/pages/login/PasswordUpdate.vue'
import CampaignList from '@/pages/loyalty/components/CampaignList.vue'
import RewardList from '@/pages/loyalty/components/RewardList.vue'
import LoyaltySection from '@/pages/loyalty/LoyaltySection.vue'
import MileagesListTable from '@/pages/mileage/components/ListTable.vue'
import UsersListTable from '@/pages/mileage/components/UsersListTable.vue'
import CreateMileage from '@/pages/mileage/CreateMileage.vue'
import MileagesList from '@/pages/mileage/MileagesList.vue'
import MileageUsersList from '@/pages/mileage/MileageUsersList.vue'
import IncomingTransfersTable from '@/pages/operations/components/IncomingTransfersTable.vue'
import OperationsTable from '@/pages/operations/components/OperationsTable.vue'
import IncomingTransfersList from '@/pages/operations/IncomingTransfersList.vue'
import OperationsClaimed from '@/pages/operations/OperationsClaimed.vue'
import OperationsList from '@/pages/operations/OperationsList.vue'
import RefundsList from '@/pages/refund/components/RefundsList.vue'
import CreateRefund from '@/pages/refund/CreateRefund.vue'
import Refunds from '@/pages/refund/Refunds.vue'
import RestrictedAccess from '@/pages/RestrictedAccess.vue'
import AnnualFees from '@/pages/statements/components/AnnualFees.vue'
import StatementsTable from '@/pages/statements/components/StatementsTable.vue'
import StatementDispatch from '@/pages/statements/StatementDispatch.vue'
import Statements from '@/pages/statements/Statements.vue'
import CreateTeam from '@/pages/teams/CreateTeam.vue'
import TeamsList from '@/pages/teams/TeamsList.vue'
import AdvancePayments from '@/pages/transfers/AdvancePayments.vue'
import TransfersList from '@/pages/transfers/components/TransfersList.vue'
import TransfersListPeriodic from '@/pages/transfers/components/TransfersListPeriodic.vue'
import CreateMultipleTransfer from '@/pages/transfers/CreateMultipleTransfer.vue'
import CreateTransfer from '@/pages/transfers/CreateTransfer.vue'
import MultipleTransferList from '@/pages/transfers/MultipleTransferList.vue'
import Transfers from '@/pages/transfers/Transfers.vue'
import treasury from '@/pages/treasury/Treasury.vue'
import UsersList from '@/pages/users/components/UsersList.vue'
import UserInvite from '@/pages/users/UserInvite.vue'
import Users from '@/pages/users/Users.vue'
import UsersImport from '@/pages/users/UsersImport.vue'
import WirecheckForm from '@/pages/wirecheck/Form.vue'
import WirecheckResult from '@/pages/wirecheck/Result.vue'

let routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: async (to, from, next) => {
      if (useAuthStore().isTokenExpired) {
        next({ name: 'login' })
      } else {
        next({ name: 'dashboard' })
      }
    }
  },

  {
    path: '/login',
    component: LoginContainer,
    props: { animationType: 'login' },
    meta: {
      isResponsive: !hasFeature(EFeature.App)
    },
    children: [
      {
        path: '',
        component: Login,
        name: 'login'
      }
    ]
  },

  {
    path: '/dashboard',
    component: Section,
    props: { hasHeader: false },
    meta: {
      requiresAuth: true,
      permissions: [permissions.dashboardRead, permissions.dashboardCardsHolderRead],
      menu: {
        name: 'dashboard',
        icon: 'dashboard'
      }
    },
    children: [
      {
        path: '',
        component: Dashboard,
        name: 'dashboard',
        meta: {
          permissions: [permissions.dashboardRead, permissions.dashboardCardsHolderRead]
        }
      }
    ]
  },

  {
    path: '/overview',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.accountOverview],
      menu: {
        name: 'operations',
        icon: 'synthese'
      }
    },
    children: [
      {
        path: '',
        component: AccountOverview,
        name: 'account-overview',
        meta: {
          permissions: [permissions.accountOverview]
        }
      }
    ]
  },

  {
    path: '/operations',
    component: Section,
    meta: {
      isResponsive: !hasFeature(EFeature.App),
      requiresAuth: true,
      permissions: [permissions.operationsRead, permissions.operationsCardsRead],
      disabled: !hasFeature(EFeature.SectionOperation),
      menu: {
        name: 'operations',
        icon: 'synthese'
      }
    },
    children: [
      {
        path: '',
        component: OperationsList,
        children: [
          {
            path: '',
            component: OperationsTable,
            name: 'operations',
            props: route => ({ type: 'checking-account', page: parseInt(route.query.page || '1') }),
            meta: {
              isResponsive: !hasFeature(EFeature.App),
              permissions: [permissions.operationsRead]
            }
          },
          {
            path: 'cards',
            component: OperationsTable,
            name: 'operations-cards',
            props: route => ({ type: 'cards', page: parseInt(route.query.page || '1') }),
            meta: {
              isResponsive: !hasFeature(EFeature.App),
              permissions: [permissions.operationsCardsRead]
            }
          }
        ]
      },
      {
        path: 'incoming-transfers',
        component: IncomingTransfersList,
        children: [
          {
            path: '',
            component: IncomingTransfersTable,
            name: 'incoming-transfers',
            props: route => ({ page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.operationsIncomingTransfersRead]
            }
          }
        ]
      },
      {
        path: 'claimed',
        component: OperationsClaimed,
        meta: {
          permissions: [permissions.operationsRead]
        },
        children: [
          {
            path: '',
            component: OperationsTable,
            name: 'operations-claimed',
            props: route => ({ isClaimed: true, type: 'cards', page: parseInt(route.query.page || '1'), emptyPlaceholderLabel: 'placeholder.no_operation_to_justify.title', filter: {} }),
            meta: {
              permissions: [permissions.operationsRead, permissions.operationsCardsRead]
            }
          }
        ]
      }
    ]
  },

  {
    path: '/transfers',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.transfersRead],
      disabled: !hasFeature(EFeature.SectionTransfer),
      menu: {
        name: 'transfers',
        icon: 'transfers'
      }
    },
    children: [
      {
        path: '',
        component: Transfers,
        children: [
          {
            path: '',
            component: TransfersList,
            name: 'transfers',
            props: route => ({ state: 'all', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.transfersRead]
            }
          },
          {
            path: 'periodic',
            component: TransfersListPeriodic,
            name: 'transfers-periodic',
            props: route => ({ page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.transfersRead]
            }
          },
          {
            path: 'history',
            component: TransfersList,
            name: 'transfers-history',
            props: route => ({ state: 'history', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.transfersRead]
            }
          },
          {
            path: 'salary',
            beforeEnter: (to, from, next) => {
              useAccountStore().displaySalaryTab ? next() : next({ name: 'transfers' })
            },
            component: TransfersList,
            name: 'transfers-salary',
            props: route => ({ state: 'salary', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.transfersAdvancePaymentRead]
            }
          }
        ]
      },
      {
        path: 'edit/:id',
        component: CreateTransfer,
        name: 'transfers-edit',
        props: true,
        meta: {
          permissions: [permissions.transfersWrite]
        }
      },
      {
        path: 'periodic/edit/:id',
        component: CreateTransfer,
        name: 'transfers-periodic-edit',
        props: route => ({ id: route.params.id, isPeriodic: true }),
        meta: {
          permissions: [permissions.transfersWrite]
        }
      },
      {
        path: 'create',
        name: 'transfers-create',
        component: CreateTransfer,
        meta: {
          permissions: [permissions.transfersWrite]
        }
      },
      {
        path: 'create/multiple',
        component: CreateMultipleTransfer,
        name: 'transfers-create-multiple',
        meta: {
          permissions: [permissions.transfersWrite]
        }
      },
      {
        path: 'create/payslips/:type(payslips|sepa)',
        component: MultipleTransferList,
        name: 'transfers-edit-multiple',
        props: true,
        meta: {
          permissions: [permissions.transfersWrite]
        }
      },
      {
        path: 'create/advance-payments',
        component: AdvancePayments,
        name: 'advance-payments',
        meta: {
          permissions: [permissions.transfersAdvancePaymentWrite]
        }
      },
      {
        path: 'beneficiary-lists',
        name: 'beneficiary-lists',
        component: BeneficiaryLists,
        meta: {
          permissions: [permissions.beneficiariesRead]
        }
      },
      {
        path: 'beneficiary-lists/:id',
        name: 'beneficiary-list',
        component: BeneficiaryList,
        props: true,
        meta: {
          permissions: [permissions.beneficiariesRead]
        }
      },
      {
        path: '/beneficiaries/edit/:id',
        name: 'beneficiaries-edit',
        component: CreateBeneficiary,
        props: true,
        meta: {
          permissions: [permissions.beneficiariesWrite]
        }
      },
      {
        path: 'beneficiaries/create',
        name: 'beneficiaries-create',
        component: CreateBeneficiary,
        props: route => ({ listId: route.query.listId }),
        meta: {
          permissions: [permissions.beneficiariesWrite]
        }
      },
      {
        path: 'beneficiaries/import',
        name: 'beneficiaries-import',
        component: BeneficiariesImport,
        props: route => ({ listId: route.query.listId }),
        meta: {
          permissions: [permissions.beneficiariesWrite]
        }
      }
    ]
  },

  {
    path: '/requests',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.expenseRequestsItemsRead],
      disabled: !hasFeature(EFeature.SectionRequest),
      menu: {
        name: 'requests',
        icon: 'requests'
      }
    },
    children: [
      {
        path: '',
        component: ExpenseRequests,
        children: [
          {
            path: '',
            component: ExpenseRequestsTable,
            name: 'requests',
            props: route => ({ state: 'pending', page: parseInt(route.query.page || '1') })
          },
          {
            path: 'done',
            component: ExpenseRequestsTable,
            name: 'requests-done',
            props: route => ({ state: 'done', page: parseInt(route.query.page || '1') })
          }
        ]
      }
    ]
  },

  {
    path: '/debits',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.debitsRead],
      disabled: !hasFeature(EFeature.SectionDebit),
      menu: {
        name: 'debits',
        icon: 'debits'
      }
    },
    children: [
      {
        path: '',
        component: Debits,
        children: [
          {
            path: '',
            component: DebitsList,
            name: 'debits',
            props: route => ({ state: 'to_come', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.debitsRead]
            }
          },
          {
            path: 'history',
            component: DebitsList,
            name: 'debits-done',
            props: route => ({ state: 'done', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.debitsRead]
            }
          },
          {
            path: 'rejected',
            component: DebitsList,
            name: 'debits-rejected',
            props: route => ({ state: 'rejected', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.debitsRead]
            }
          }
        ]
      },
      {
        path: 'mandates',
        component: Mandates,
        children: [
          {
            path: '',
            component: MandatesList,
            name: 'mandates',
            props: route => ({ page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.debitsRead]
            }
          }
        ]
      }
    ]
  },

  {
    path: '/cards',
    component: Section,
    meta: {
      isResponsive: !hasFeature(EFeature.App),
      requiresAuth: true,
      permissions: [permissions.cardsPhysicalRead, permissions.cardsVirtualRead, permissions.cardsVirtualRequest],
      disabled: !hasFeature(EFeature.SectionCard),
      menu: {
        name: 'cards',
        icon: 'cards'
      }
    },
    children: [
      {
        path: '',
        name: 'cards',
        beforeEnter: (to, from, next) => {
          const perms = useAccountStore().account?.permissions
          if (!perms) return

          perms.some(i => permissions.cardsPhysicalRead === i)
            ? next({ name: 'cards-physical' })
            : next({ name: 'cards-virtual' })
        }
      },
      {
        path: 'physical',
        component: Cards,
        props: { physicalCard: true },
        children: [
          {
            path: '',
            component: CardsListTabs,
            props: { physicalCard: true },
            meta: {
              permissions: [permissions.cardsPhysicalRead]
            },
            children: [
              {
                path: '',
                component: CardsList,
                name: 'cards-physical',
                props: route => ({ state: 'done', page: parseInt(route.query.page || '1') }),
                meta: {
                  permissions: [permissions.cardsPhysicalRead]
                }
              },
              {
                path: 'validation',
                component: CardsList,
                name: 'cards-physical-to-validate',
                props: route => ({ state: 'to_validate', page: parseInt(route.query.page || '1') }),
                meta: {
                  isResponsive: false,
                  permissions: [permissions.cardsPhysicalRead]
                }
              },
              {
                path: 'history',
                component: CardsList,
                name: 'cards-physical-history',
                props: route => ({ state: 'history', page: parseInt(route.query.page || '1') }),
                meta: {
                  permissions: [permissions.cardsPhysicalRead]
                }
              }
            ]
          }
        ]
      },
      {
        path: 'virtual',
        component: Cards,
        props: { physicalCard: false },
        children: [
          {
            path: '',
            component: CardsListTabs,
            props: { physicalCard: false },
            meta: {
              permissions: [permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
            },
            children: [
              {
                path: '',
                component: CardsList,
                name: 'cards-virtual',
                props: route => ({ state: 'done', page: parseInt(route.query.page || '1') }),
                meta: {
                  permissions: [permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
                }
              },
              {
                path: 'validation',
                component: CardsList,
                name: 'cards-virtual-to-validate',
                props: route => ({ state: 'to_validate', page: parseInt(route.query.page || '1') }),
                meta: {
                  isResponsive: false,
                  permissions: [permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
                }
              },
              {
                path: 'history',
                component: CardsList,
                name: 'cards-virtual-history',
                props: route => ({ state: 'history', page: parseInt(route.query.page || '1') }),
                meta: {
                  permissions: [permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
                }
              }
            ]
          }
        ]
      },
      {
        path: 'order',
        children: [
          {
            path: '',
            component: OrderCardSteps,
            name: 'order-physical-card',
            meta: {
              permissions: [permissions.cardsPhysicalCreate]
            }
          },
          {
            path: 'multiple',
            component: OrderMultipleCards,
            name: 'order-multiple-cards',
            meta: {
              permissions: [permissions.cardsCreateMultiple]
            }
          }
        ]
      },
      {
        path: 'virtual/generate',
        meta: {
          permissions: [permissions.cardsVirtualCreate, permissions.cardsVirtualRequest]
        },
        beforeEnter: (to, from, next) => {
          const virtualCardLength = useAccountStore().virtualCardTypes.length
          if (virtualCardLength === 0) {
            next(from)
          } else {
            next()
          }
        },
        children: [
          {
            path: '',
            component: GenerateCardSteps,
            name: 'generate-virtual-card'
          }
        ]
      },
      {
        path: ':uuid',
        component: MoneCardDetail,
        props: true,
        children: [
          {
            path: '',
            component: General,
            name: 'cards-detail',
            meta: {
              permissions: [permissions.cardsPhysicalRead, permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
            }
          },
          {
            path: 'operations',
            component: OperationsTable,
            name: 'cards-detail-operations',
            props: route => ({ type: 'cards', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.cardsPhysicalRead, permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
            }
          },
          {
            path: 'settings',
            component: Settings,
            name: 'cards-detail-settings',
            meta: {
              isResponsive: false,
              permissions: [permissions.cardsPhysicalWrite, permissions.cardsVirtualWrite, permissions.cardsPhysicalRead]
            }
          },
          {
            path: 'statements',
            component: StatementsTable,
            name: 'cards-detail-statements',
            props: { isCard: true },
            meta: {
              isResponsive: false,
              permissions: [permissions.statementsCardsRead]
            }
          }
        ]
      },
      {
        path: ':uuid/operations-to-justify',
        component: OperationsToJustifyList,
        name: 'operations-to-justify',
        props: route => ({ uuid: route.params.uuid, page: parseInt(route.query.page || '1') }),
        meta: {
          permissions: [permissions.cardsPhysicalRead, permissions.cardsVirtualRead, permissions.cardsVirtualRequest]
        }
      },
      {
        path: 'virtual/request-settings/:uuid',
        component: CardRequestSettings,
        name: 'virtual-card-request-settings',
        props: true,
        meta: {
          permissions: [permissions.cardsVirtualWrite]
        }
      },
      {
        path: 'physical/request-settings/:uuid',
        component: CardRequestSettings,
        name: 'physical-card-request-settings',
        props: true,
        meta: {
          permissions: [permissions.cardsPhysicalWrite]
        }
      }
    ]
  },

  {
    path: '/credits',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.creditsRead],
      disabled: !hasFeature(EFeature.SectionCredit),
      menu: {
        name: 'credits',
        icon: 'credits'
      }
    },
    children: [
      {
        path: '',
        component: Credits,
        children: [
          {
            path: '',
            component: CreditsList,
            name: 'credits',
            props: route => ({ state: 'to_come', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.creditsRead]
            }
          },
          {
            path: 'history',
            component: CreditsList,
            name: 'credits-done',
            props: route => ({ state: 'done', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.creditsRead]
            }
          }
        ]
      }
    ]
  },

  {
    path: '/refunds',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.refundsRead],
      disabled: !hasFeature(EFeature.SectionRefund),
      menu: {
        name: 'refunds',
        icon: 'refunds'
      }
    },
    children: [
      {
        path: '',
        component: Refunds,
        children: [
          {
            path: '',
            component: RefundsList,
            name: 'refunds',
            props: route => ({ state: 'pending', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.refundsRead]
            }
          },
          {
            path: 'history',
            component: RefundsList,
            name: 'refunds-done',
            props: route => ({ state: 'done', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.refundsRead]
            }
          }
        ]
      },
      {
        path: 'create',
        component: CreateRefund,
        name: 'refund-create',
        meta: {
          permissions: [permissions.refundsWrite]
        }
      },
      {
        path: ':id',
        component: CreateRefund,
        name: 'refund-edit',
        props: true,
        meta: {
          permissions: [permissions.refundsWrite]
        }
      }
    ]
  },

  {
    path: '/mileages',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.mileagesRead],
      disabled: !hasFeature(EFeature.SectionMileage),
      menu: {
        name: 'mileages',
        icon: 'mileages'
      }
    },
    children: [
      {
        path: '',
        component: MileagesList,
        children: [
          {
            path: '',
            component: MileagesListTable,
            name: 'mileages',
            props: route => ({ state: 'pending', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.mileagesRead]
            }
          },
          {
            path: 'history',
            component: MileagesListTable,
            name: 'mileages-done',
            props: route => ({ state: 'done', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.mileagesRead]
            }
          }
        ]
      },
      {
        path: 'users',
        component: MileageUsersList,
        children: [
          {
            path: '',
            component: UsersListTable,
            name: 'mileage-users',
            props: route => ({ page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.accountOwner]
            }
          }
        ]
      },
      {
        path: 'create',
        component: CreateMileage,
        name: 'mileage-create',
        meta: {
          permissions: [permissions.mileagesWrite]
        }
      },
      {
        path: 'edit/:id',
        component: CreateMileage,
        name: 'mileage-edit',
        props: true,
        meta: {
          permissions: [permissions.mileagesWrite]
        }
      }
    ]
  },

  {
    path: '/statements',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.statementsRead],
      disabled: !hasFeature(EFeature.SectionStatement),
      menu: {
        name: 'statements',
        icon: 'statements'
      }
    },
    children: [
      {
        path: '',
        component: Statements,
        children: [
          {
            path: '',
            component: StatementsTable,
            name: 'statements',
            meta: {
              permissions: [permissions.statementsRead]
            }
          },
          {
            path: 'physical-cards',
            component: StatementsTable,
            name: 'statements-cards-physical',
            props: { isCard: true },
            meta: {
              permissions: [permissions.statementsCardsRead]
            }
          },
          {
            path: 'virtual-cards',
            component: StatementsTable,
            name: 'statements-cards-virtual',
            props: { isCard: true },
            meta: {
              permissions: [permissions.statementsCardsRead]
            }
          },
          {
            path: 'annual-fees',
            component: AnnualFees,
            name: 'statements-annual-fees',
            meta: {
              permissions: [permissions.statementsRead]
            }
          }
        ]
      },
      {
        path: 'dispatch',
        component: StatementDispatch,
        name: 'statements-dispatch',
        props: route => ({ page: parseInt(route.query.page || '1') }),
        meta: {
          permissions: [permissions.statementsDispatchRead]
        }
      }
    ]
  },

  {
    path: '/accounting',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.accountingRead, permissions.accountingExpensesRead],
      disabled: !hasFeature(EFeature.SectionAccounting),
      menu: {
        name: 'accounting',
        icon: 'accounting'
      }
    },
    children: [
      {
        path: '',
        component: Expenses,
        children: [
          {
            path: '',
            component: ExpensesList,
            name: 'expenses',
            beforeEnter: (to, from, next) => {
              const perms = useAccountStore().account?.permissions
              if (!perms) return

              perms.some(i => permissions.accountingExpensesRead === i)
                ? next()
                : next({ name: 'accounting' })
            },
            props: route => ({ state: 'pending', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.accountingExpensesRead]
            }
          },
          {
            path: 'treated',
            component: ExpensesList,
            name: 'expenses-done',
            props: route => ({ state: 'treated', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.accountingExpensesRead]
            }
          }
        ]
      },
      {
        path: 'chart',
        component: Accounting,
        children: [
          {
            path: '',
            component: AccountingCategories,
            name: 'accounting',
            meta: {
              permissions: [permissions.accountingRead]
            }
          }, {
            path: 'accounts',
            component: AccountingAccounts,
            name: 'accounting-accounts',
            meta: {
              permissions: [permissions.accountingRead]
            }
          }
        ]
      }
    ]
  },

  {
    path: '/cash-flow',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.treasuryRead],
      disabled: !hasFeature(EFeature.SectionTreasury),
      menu: {
        name: 'treasury',
        icon: 'treasury'
      }
    },
    children: [
      {
        path: '',
        component: treasury
      }
    ]
  },

  {
    path: '/loyalty',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.loyaltyRead],
      menu: {
        name: 'loyalty',
        icon: 'loyalty'
      }
    },
    children: [
      {
        path: '',
        component: LoyaltySection,
        children: [
          {
            path: '',
            component: RewardList,
            props: route => ({ page: parseInt(route.query.page || '1') }),
            name: 'loyalty-rewards'
          },
          {
            path: 'campaigns',
            component: CampaignList,
            props: route => ({ page: parseInt(route.query.page || '1') }),
            name: 'loyalty-campaigns'
          }
        ]
      }
    ]
  },

  {
    path: '/users',
    component: Section,
    meta: {
      requiresAuth: true,
      permissions: [permissions.usersRead, permissions.teamsRead],
      disabled: !hasFeature(EFeature.SectionUser),
      menu: {
        name: 'users',
        icon: 'users'
      }
    },
    children: [
      {
        path: '',
        component: Users,
        meta: {
          permissions: [permissions.usersRead]
        },
        children: [
          {
            path: '',
            component: UsersList,
            name: 'users',
            props: route => ({ state: 'active', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.usersRead]
            }
          },
          {
            path: 'invites',
            component: UsersList,
            name: 'users-pending',
            props: route => ({ state: 'pending', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.invitationsRead]
            }
          },
          {
            path: 'revoked',
            component: UsersList,
            name: 'users-revoked',
            props: route => ({ state: 'revoked', page: parseInt(route.query.page || '1') }),
            meta: {
              permissions: [permissions.usersRead]
            }
          }
        ]
      },
      {
        path: 'teams',
        component: TeamsList,
        name: 'teams',
        props: route => ({ page: parseInt(route.query.page || '1') }),
        meta: {
          permissions: [permissions.teamsRead]
        }
      },
      {
        path: 'teams/create',
        component: CreateTeam,
        name: 'team-create',
        meta: {
          permissions: [permissions.teamsWrite]
        }
      },
      {
        path: 'teams/:id',
        component: CreateTeam,
        name: 'team-edit',
        props: true,
        meta: {
          permissions: [permissions.teamsRead]
        }
      },
      {
        path: 'invite',
        component: UserInvite,
        props: { mode: 'create' },
        name: 'user-invite',
        meta: {
          permissions: [permissions.invitationsWrite]
        }
      },
      {
        path: 'invite/:id',
        component: UserInvite,
        name: 'user-invite-edit',
        props: route => ({ mode: 'edit-invitation', id: route.params.id }),
        meta: {
          permissions: [permissions.invitationsRead, permissions.invitationsWrite]
        }
      },
      {
        path: 'edit/:id',
        component: UserInvite,
        name: 'user-edit',
        props: route => ({ mode: 'edit-user', id: route.params.id }),
        meta: {
          permissions: [permissions.usersRead]
        }
      },
      {
        path: 'import',
        component: UsersImport,
        name: 'users-import',
        meta: {
          permissions: [permissions.invitationsCreateMultiple]
        }
      }
    ]
  },

  {
    path: '/inbox',
    component: Section,
    meta: {
      requiresAuth: true,
      disabled: !hasFeature(EFeature.SectionInbox)
    },
    children: [
      {
        path: '',
        component: Inbox,
        name: 'inbox',
        props: route => ({ page: parseInt(route.query.page || '1') }),
        meta: {
          permissions: [permissions.messagesRead]
        }
      }
    ]
  },

  {
    path: '/account',
    component: Section,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: Account,
        name: 'account'
      },
      {
        path: 'create',
        component: CreateSubAccount,
        name: 'create-sub-account',
        meta: {
          permissions: [
            permissions.accountSequestreAccountCreate,
            permissions.accountCapitalGainCreate,
            permissions.accountSubAccountCreate,
            permissions.accountTermAccountCreate
          ]
        }
      },
      {
        path: 'create/capital-gain',
        component: CreateCapitalGain,
        name: 'create-capital-gain-account',
        meta: {
          permissions: [permissions.accountCapitalGainCreate]
        }
      },
      {
        path: 'create/check',
        component: CreateCheck,
        name: 'create-checks-account',
        meta: {
          permissions: [permissions.accountSequestreAccountCreate]
        }
      },
      {
        path: 'create/additional-account',
        component: CreateAdditionalAccount,
        meta: {
          permissions: [permissions.accountSubAccountCreate]
        },
        children: [
          {
            path: '',
            component: CreateAdditionalAccountPlaceholder,
            name: 'create-additional-account'
          },
          {
            path: 'form',
            component: CreateAdditionalAccountForm,
            name: 'create-additional-account-form'
          }
        ]
      },
      {
        path: 'create/term',
        component: CreateTerm,
        meta: {
          permissions: [permissions.accountTermAccountCreate]
        },
        children: [
          {
            path: '',
            component: CreateTermPlaceholder,
            name: 'create-term-account'
          },
          {
            path: 'form',
            component: CreateTermForm,
            name: 'create-term-account-form'
          }
        ]
      },
      {
        path: 'sca',
        component: scaList,
        name: 'account-sca',
        meta: {
          isResponsive: !hasFeature(EFeature.App),
          scaView: true
        }
      },
      {
        path: 'sca/configure',
        component: scaConfigure,
        name: 'account-sca-configure',
        meta: {
          isResponsive: !hasFeature(EFeature.App),
          scaView: true
        }
      },
      {
        path: 'sca/configure/:type(app|email|sms)',
        component: ConfigureToken,
        name: 'account-sca-configure-token',
        props: true,
        meta: {
          isResponsive: !hasFeature(EFeature.App),
          scaView: true
        }
      },
      {
        path: 'info',
        component: accountInfo,
        children: [
          {
            path: '',
            component: UserDetails,
            name: 'account-info',
            meta: {
              isResponsive: !hasFeature(EFeature.App)
            }
          },
          {
            path: 'bank',
            component: BankDetails,
            name: 'account-info-bank-details',
            meta: {
              permissions: [permissions.accountBeneficiaryRibWrite]
            }
          },
          {
            path: 'vehicle',
            component: VehicleDetails,
            name: 'account-info-vehicle'
          }
        ]
      },
      {
        path: 'check-remittance',
        component: AccountCheckRemittance,
        name: 'account-check-remittance',
        meta: {
          permissions: [permissions.checkRemittanceSlipRead]
        }
      },
      {
        path: 'company',
        component: AccountCompanies,
        name: 'account-company',
        props: route => ({ page: parseInt(route.query.page || '1') })
      },
      {
        path: 'company/:id',
        component: SubAccountsList,
        props: route => ({ id: parseInt(route.params.id) }),
        children: [
          {
            path: '',
            component: BankAccountsTab,
            name: 'account-company-subaccounts',
            props: route => ({ page: parseInt(route.query.page || '1') })
          },
          {
            path: 'info',
            component: InformationsTab,
            name: 'account-company-info'
          }
        ]
      },
      {
        path: 'settings',
        component: AccountSettings,
        name: 'account-settings',
        children: [
          {
            path: 'notifications',
            name: 'account-settings-notifications',
            component: NotificationsPreferences
          },
          {
            path: 'preferences',
            name: 'account-settings-preferences',
            component: MenuPreferences,
            beforeEnter: (to, from, next) => {
              useAccountStore().displayMenuPreference
                ? next()
                : next({ name: 'account-settings-notifications' })
            }
          },
          {
            path: 'control-rule',
            name: 'account-settings-control-rule',
            component: AccountControlRule,
            meta: {
              permissions: [permissions.accountRulesRead]
            }
          }
        ]
      },
      {
        path: 'rib-iban',
        component: accountRIB,
        name: 'account-rib',
        meta: {
          permissions: [permissions.accountRibRead]
        }
      },
      {
        path: 'sponsorship',
        component: accountSponsorship,
        name: 'sponsorship',
        meta: {
          permissions: [permissions.accountOwner]
        }
      },
      {
        path: 'services',
        component: accountServices,
        name: 'account-services'
      }
    ]
  },

  {
    path: '/follow-opening',
    component: Section,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: FollowOpening,
        name: 'follow-opening'
      }
    ]
  },

  {
    path: '/capital-gain',
    component: Section,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: CapitalGainFollowup,
        name: 'capital-gain'
      }
    ]
  },

  {
    path: '/term-account',
    component: Section,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: TermAccountOpening,
        name: 'term-account-opening'
      }
    ]
  },

  {
    path: '/restricted-access',
    component: Section,
    meta: {
      requiresAuth: true,
      isResponsive: !hasFeature(EFeature.App)
    },
    children: [
      {
        path: '',
        component: RestrictedAccess,
        name: 'restricted-access'
      }
    ]
  },

  {
    path: '/forgot',
    component: LoginContainer,
    props: { animationType: 'forgot' },
    meta: {
      isResponsive: !hasFeature(EFeature.App),
      requiresAuth: false
    },
    children: [
      {
        path: '',
        component: Forgot,
        name: 'forgot'
      }
    ]
  },

  {
    path: '/password/update',
    component: LoginContainer,
    props: { animationType: 'password-update' },
    meta: {
      requiresAuth: false,
      isResponsive: true
    },
    children: [
      {
        path: '',
        component: PasswordUpdate,
        name: 'password-update',
        props: route => ({ token: route.query.token })
      }
    ]
  },

  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      next({ name: 'login' })
    }
  },

  {
    path: '/logout-inactivity',
    component: LoginContainer,
    props: { animationType: 'logout-inactivity' },
    meta: {
      isResponsive: true
    },
    children: [
      {
        path: '',
        component: LogoutInactivity,
        name: 'logout-inactivity'
      }
    ]
  },

  {
    path: '/invitation',
    component: FullPage,
    meta: {
      isResponsive: true
    },
    children: [
      {
        path: '',
        component: Invitation,
        props: route => ({ token: route.query.token }),
        children: [
          {
            path: '',
            component: ModeChoice,
            name: 'invitation'
          },
          {
            path: 'create',
            component: CreateAccount,
            name: 'invitation-create-account'
          },
          {
            path: 'login',
            component: ExistingAccount,
            name: 'invitation-existing-account'
          }
        ]
      }
    ]
  },

  {
    path: '/wirecheck',
    component: FullPage,
    meta: {
      noCookie: true,
      isResponsive: true
    },
    children: [
      {
        path: '',
        component: WirecheckForm,
        name: 'wirecheck'
      },
      {
        path: ':id',
        component: WirecheckResult,
        name: 'wirecheck-detail',
        props: true
      }
    ]
  },

  {
    path: '/check/:uuid/:signature',
    component: FullPage,
    meta: {
      noCookie: true,
      isResponsive: true
    },
    children: [
      {
        path: '',
        component: DocumentCheck,
        name: 'document-check',
        props: true
      }
    ]
  }
]

if (import.meta.env.DEV) {
  routes.push(
    {
      path: '/_/design',
      component: () => import('@/components/svg/icons.vue')
    }
  )
}

routes.push(
  {
    path: '/*',
    name: '404',
    beforeEnter: (to, from, next) => {
      next({ name: 'dashboard' })
    }
  }
)

const fillParentPermissions = (routes, parentPermissions = []) => {
  routes.forEach(route => {
    if (parentPermissions.length) {
      if (!route.meta) route.meta = {}
      route.meta.parentPermissions = parentPermissions
    }
    if (route.children) {
      route.children = fillParentPermissions(route.children, route.meta?.permissions ? [...parentPermissions, route.meta?.permissions] : parentPermissions)
    }
  })
  return routes
}
routes = fillParentPermissions(routes, [])

const removeDisabledRoutes = routes => {
  routes = routes.filter(route => !route.meta?.disabled)
  routes.forEach(route => {
    if (route.children) {
      route.children = removeDisabledRoutes(route.children)
    }
  })
  return routes
}
routes = removeDisabledRoutes(routes)

export default routes
