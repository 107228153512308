<template>
  <div>
    <h4 v-if="title"
        class="body--extra-large center mb-4">
      {{ title }}
    </h4>
    <div class="block-options"
         :class="{ 'block-options--two-columns': !physicalCard }">
      <block-options-item v-for="(type, index) in cardOrderTypes"
                          :key="index"
                          :title="type.title"
                          :content="type.content"
                          :data-cy="type.dataCy"
                          :hide-chevron="physicalCard"
                          @click="emit('update:type', type.value)">
        <card-item v-if="physicalCard"
                   :card="type.cardItemProps"
                   small-card
                   show-sim
                   :native-click="false"
                   :show-digits="false"
                   :show-cvv="false" />
        <image-card v-else-if="type.iconProps"
                    v-bind="type.iconProps" />
      </block-options-item>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { kebabCase } from 'lodash'
import { storeToRefs } from 'pinia'

import { getCardTypeLabel, getImageCardIconName } from '@/helpers/utils/card'
import { useAccountStore } from '@/stores/account'
import { TCardType } from '@/types/card.d'

import CardItem from '@/pages/cards/components/CardItem.vue'

import BlockOptionsItem from '@/components/BlockOptionsItem.vue'
import ImageCard from '@/components/svg/img/card.vue'

const accountStore = useAccountStore()
const { physicalCardTypes, virtualCardTypes } = storeToRefs(accountStore)

const props = defineProps<{
  title?: string
  physicalCard: boolean
  role: string
}>()

const emit = defineEmits([
  'update:type'
])

const { t } = useI18n()

const cardOrderTypes = computed(() => {
  return props.physicalCard
    ? physicalCardTypes.value?.map(cardType => getPhysicalCardInfo(cardType))
    : virtualCardTypes.value?.map(cardType => getVirtualCardInfo(cardType))
})

function getPhysicalCardInfo (cardType: TCardType) {
  return {
    value: cardType,
    title: getCardTypeLabel({ type: cardType }),
    dataCy: `cards.create.user.${kebabCase(cardType)}`,
    content: t(`order_card.choice.type.${cardType}.content`),
    cardItemProps: { type: cardType, subtype: 'marqeta', status: 'active' }
  }
}

function getVirtualCardInfo (cardType: TCardType) {
  return {
    value: cardType,
    title: getCardTypeLabel({ type: cardType }),
    dataCy: `cards.create.virtual.${kebabCase(cardType)}`,
    content: t(`generate_ecard.choice.${cardType}.content`),
    iconProps: { [getImageCardIconName({ type: cardType })]: true }
  }
}
</script>
