<template>
  <div class="sidepanel__content"
       data-cy="transfers.sidepanel">
    <transition name="fade-delay">
      <file-viewer v-if="displayFileViewer"
                   class="side-fileviewer sidepanel-double sidepanel-double--fileviewer"
                   @close="closeFileViewer" />
    </transition>
    <loader-spinner v-if="!modelForm"
                    class="c c--vh" />
    <template v-else>
      <div id="sidepanel-wrapper"
           class="sidepanel-wrapper overflow-scrollbar">
        <div class="sidepanel-header">
          <div class="transaction-summary transaction-summary--no-border">
            <h2 class="transaction-amount">
              {{ formatAmount(modelForm.amount, modelForm.currency) }}
            </h2>
            <p v-if="hasFx"
               class="fx-currency text-muted">
              {{ formatAmount(modelForm.fx_amount, modelForm.fx_currency, { currencyDisplay: 'code' }) }}
            </p>
            <p class="transaction-name">
              {{ modelForm.operation.label }}
            </p>
            <core-badge v-if="modelForm.status_label"
                        :size="ECoreBadgeSize.Large"
                        class="transaction-badge"
                        v-bind="getBadgeProps(modelForm.status)"
                        :value="modelForm.status_label" />
            <div v-if="isProofButtonVisible"
                 class="document-actions-wrapper">
              <button type="button"
                      class="btn btn-proofs"
                      :class="proofButton.btnClass"
                      @click="showFile">
                <span class="btn-icon btn-icon--left">
                  <component :is="proofButton.icon"
                             class="ic ic--24 ic--gray" />
                </span>
                <span class="btn__label">{{ proofLabel }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="sidepanel-body">
          <div class="sidepanel-details">
            <transaction-user v-if="hasFeature(EFeature.TransferDetailInitiator) && modelForm.user"
                              :pictures="[modelForm.user.picture]"
                              :user-names="[`${modelForm.user.first_name} ${modelForm.user.last_name}`]"
                              :text="$t('general.initiated_by')" />
            <h3 class="head-title">
              {{ $t('sidepanel.transfer.title') }}
            </h3>
            <div v-if="hasFeature(EFeature.TransferDetailCreationDate)"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.created_at") }}</span>
              <span class="entry-value right">{{ formatDateText(modelForm.created_at, featureParams(EFeature.TransferDetailCreationDate).format) }}</span>
            </div>
            <div v-if="hasFeature(EFeature.TransferDetailExecutionDate) && modelForm.execution_date"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.execution_date") }}</span>
              <span class="entry-value right">{{ formatDateText(modelForm.execution_date, featureParams(EFeature.TransferDetailExecutionDate).format) }}</span>
            </div>
            <div v-if="modelForm.beneficiary.label"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.beneficiary") }}</span>
              <span class="entry-value right">{{ model.beneficiary.label }}</span>
            </div>
            <div v-if="modelForm.beneficiary.iban"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.iban") }}</span>
              <span class="entry-value right">{{ formatIban(model.beneficiary.iban) }}</span>
            </div>
            <div v-if="displayFxAmount(modelForm)"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.fx_transfer") }}</span>
              <span class="entry-value right">{{ formatAmount(modelForm.fx_amount, modelForm.fx_currency, { currencyDisplay: 'code' }) }}</span>
            </div>
            <div v-if="displayFxFees(modelForm)"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.fees") }}</span>
              <span class="entry-value right">{{ formatAmount(modelForm.fx_fees, account.currency) }}</span>
            </div>
            <div v-if="isInternational(modelForm) && modelForm.mode_fees"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("general.mode_fees") }}</span>
              <div class="entry-value right">
                <span>{{ modeFeesInfo }}</span>
              </div>
            </div>
            <div v-if="hasFeature(EFeature.TransferDetailExpenseDate) && isRefund && modelForm.operation.expense_date"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ $t("form.expense_date.label") }}</span>
              <span class="entry-value right">
                <component-datepicker v-if="hasFeature(EFeature.TransferDetailExpenseDateEdition)"
                                      v-model="modelForm.operation.expense_date"
                                      name="expense_date"
                                      :language="$i18n.locale"
                                      :format="'dd/MM/yyyy'"
                                      :wrapper-class="'datepicker--right select select--inline select--rtl'"
                                      :input-class="'form-control form-control--noborder right'"
                                      :monday-first="true"
                                      :readonly="false"
                                      :placeholder="$t('form.date.placeholder')"
                                      @update:model-value="onExpenseDateChanged" />
                <span v-else
                      class="entry-value right">{{ formatDateText(modelForm.operation.expense_date, featureParams(EFeature.TransferDetailExpenseDate).format) }}</span>
              </span>
            </div>
            <div v-if="hasFeature(EFeature.TransferCategory)"
                 class="sidepanel-details__entry">
              <span class="entry-label text-muted">{{ categoryLabel }}</span>
              <div class="entry-value right">
                <div v-if="!hasFeature(EFeature.TransferCategoryEdition) && !hasCategory">
                  {{ $t('general.none') }}
                </div>
                <div v-else
                     class="select select--inline select--rtl"
                     :class="{ 'select--disabled': !hasFeature(EFeature.TransferCategoryEdition) }">
                  <component-dropdown label="categories"
                                      :dropdown-width="35.4"
                                      multiselect
                                      search
                                      :can-add-item="hasPermission($permissions.categoriesWrite)"
                                      async-url="/categories"
                                      :async-url-params="{ filter: 'transfer' }"
                                      has-icon
                                      :disabled="!hasFeature(EFeature.TransferCategoryEdition)"
                                      :model="modelForm.operation.detail.categories"
                                      @select="onCategoriesSelected"
                                      @new-item="onNewItemAdded">
                    <template #trigger="slotProps">
                      <div v-tooltip="{ content: slotProps.showDropdown ? false : tooltipCategoriesLabel, theme: 'tooltip', html: true }"
                           class="form-control"
                           :class="{ 'text-muted': !hasCategory }">
                        {{ categoryList }}
                      </div>
                    </template>
                  </component-dropdown>
                </div>
              </div>
            </div>
            <category-detail-fields v-if="hasFeature(EFeature.TransferCategoryDetail)"
                                    :operation="modelForm.operation"
                                    @change="onCategoryDetailChange" />
            <sidepanel-affectations v-if="canReadAffectations"
                                    :can-edit-affectations="canEditAffectations"
                                    :can-write-affectations="canWriteAffectations"
                                    :affectations="modelForm.operation.affectations"
                                    @affectations-selected="onAffectationsSelected"
                                    @new-affectation-added="onNewAffectationAdded" />
            <template v-if="hasFeature(EFeature.TransferComment)">
              <hr class="divider divider--fw">
              <h3 class="head-title">
                {{ $t('form.personal_note.label') }}
              </h3>
              <div class="sidepanel-details__entry">
                <input-note v-model="modelForm.operation.comment"
                            :placeholder="$t('form.personal_note.placeholder')"
                            :disabled="!hasFeature(EFeature.TransferCommentEdition)" />
              </div>
            </template>
            <template v-if="hasFeature(EFeature.TransferDetailApprovalDetail) && modelForm.approvers?.length">
              <hr class="divider divider--fw">
              <approvers-list class="mb-4"
                              data-cy="expense-requests.detail.approvers-list.done"
                              :approvers="modelForm.approvers" />
            </template>
          </div>
        </div>
      </div>
      <component-sidepanel-footer v-if="didDetailsChange || (canDownloadCertificate || canValidateTransfer || isCancellable)">
        <transition name="fade"
                    mode="out-in">
          <component-button v-if="didDetailsChange"
                            :label="$t('button.save')"
                            wrapper-class="btn btn--block btn--sm btn--primary"
                            @click="saveOperation" />
          <div v-else>
            <a v-if="canDownloadCertificate"
               href="#"
               class="btn btn--block btn--sm btn--outline"
               data-cy="transfer-detail.download-certificate"
               @click.prevent.stop="downloadFileFromUrl(modelForm.certificate.url)">
              {{ $t("button.transfer_certificate") }}
            </a>
            <div v-if="canValidateTransfer"
                 class="btn-grouped">
              <button type="button"
                      class="btn btn--sm btn--gray"
                      :disabled="loading"
                      @click.prevent="cancel">
                {{ $t("button.refuse") }}
              </button>
              <button type="button"
                      class="btn btn--sm btn--primary"
                      :disabled="loading"
                      @click.prevent="validate">
                {{ $t("button.validate") }}
              </button>
            </div>
            <button v-else-if="isCancellable"
                    type="button"
                    class="btn btn--block btn--sm btn--outline-red"
                    @click.prevent="cancel">
              {{ $t("button.transfer_cancel") }}
            </button>
          </div>
        </transition>
      </component-sidepanel-footer>
    </template>
  </div>
</template>

<script>
import { CoreBadge, ECoreBadgeSize } from '@common/core-ui'
import dayjs from 'dayjs'
import { cloneDeep, isEqual } from 'lodash'
import { storeToRefs } from 'pinia'

import { EFeature, featureParams, hasFeature } from '@/config/features'
import { downloadFileFromUrl } from '@/helpers/utils'
import { getBadgeProps } from '@/helpers/utils/badge'
import { formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { formatAmount } from '@/helpers/utils/number'
import { arrayInputLabel, arrayInputTooltip } from '@/helpers/utils/text'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useInvitationStore } from '@/stores/invitation'
import { useOperationStore } from '@/stores/operation'
import { useTransferStore } from '@/stores/transfer'

import ApproversList from '@/pages/expense_requests/components/ApproversList.vue'

import ComponentButton from '@/components/Button.vue'
import CategoryDetailFields from '@/components/CategoryDetailFields.vue'
import ComponentDatepicker from '@/components/Datepicker.vue'
import ComponentDropdown from '@/components/Dropdown.vue'
import FileViewer from '@/components/FileViewer.vue'
import InputNote from '@/components/InputNote.vue'
import LoaderSpinner from '@/components/LoaderSpinner.vue'
import SidepanelAffectations from '@/components/SidepanelAffectations.vue'
import ComponentSidepanelFooter from '@/components/SidepanelFooter.vue'
import IcAttachment from '@/components/svg/icons/ic-attachment.vue'
import IcDocument from '@/components/svg/icons/ic-document.vue'
import TransactionUser from '@/components/TransactionUser.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    CoreBadge,
    ApproversList,
    InputNote,
    ComponentButton,
    TransactionUser,
    ComponentDropdown,
    ComponentDatepicker,
    ComponentSidepanelFooter,
    CategoryDetailFields,
    FileViewer,
    UserAvatar,
    LoaderSpinner,
    SidepanelAffectations
  },

  props: {
    id: {
      type: Number,
      required: true
    },

    showHeader: {
      type: Boolean,
      default: true
    }
  },

  emits: ['dropped-files-upload'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const invitationStore = useInvitationStore()
    const operationStore = useOperationStore()
    const transferStore = useTransferStore()

    const { account } = storeToRefs(accountStore)
    const { sidePanel } = storeToRefs(appStore)
    const { user } = storeToRefs(authStore)

    return { account, sidePanel, appStore, user, invitationStore, operationStore, transferStore }
  },

  data () {
    return {
      displayFileViewer: false,
      loading: false,
      model: null,
      modelForm: null,
      showHeaderBool: false
    }
  },

  computed: {
    ECoreBadgeSize () {
      return ECoreBadgeSize
    },

    EFeature () {
      return EFeature
    },

    didDetailsChange () {
      return !isEqual({
        detail: this.modelForm.operation.detail,
        expense_date: this.modelForm.operation.expense_date,
        comment: this.modelForm.operation.comment || '',
        affectations: this.modelForm.operation.affectations

      }, {
        detail: this.model.operation.detail,
        expense_date: this.model.operation.expense_date,
        comment: this.model.operation.comment || '',
        affectations: this.model.operation.affectations
      })
    },

    hasDocument () {
      return this.model.operation.documents.length
    },

    isCancellable () {
      return hasFeature(EFeature.TransferDetailCancellation) && (this.modelForm.cancellable || this.canValidateTransfer) && (this.isAccountOwner || this.isTransferOwner)
    },

    isAccountOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    isTransferOwner () {
      return this.modelForm.user && this.user.uuid === this.modelForm.user.uuid
    },

    canUploadFile () {
      return hasFeature(EFeature.TransferDocumentUpload)
    },

    canShowFile () {
      return hasFeature(EFeature.TransferDocument) && this.hasDocument
    },

    isProofButtonVisible () {
      return hasFeature(EFeature.TransferDocument) && (this.canUploadFile || this.canShowFile) && this.showHeader
    },

    canDownloadCertificate () {
      return hasFeature(EFeature.TransferDetailCertificateDownload) && this.modelForm.certificate
    },

    canValidateTransfer () {
      return hasFeature(EFeature.TransferDetailValidation, this.modelForm)
    },

    canEditAffectations () {
      return hasFeature(EFeature.TransferAffectationEdition)
    },

    canReadAffectations () {
      return this.hasPermission(this.$permissions.transfersAffectationsRead)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    hasCategory () {
      return this.modelForm.operation.detail?.categories?.length
    },

    categoryLabel () {
      return this.$i18n.tc('form.category.label', this.modelForm.operation.detail?.categories?.length || 0)
    },

    tooltipCategoriesLabel () {
      return arrayInputTooltip(this.modelForm.operation.detail.categories, 1)
    },

    categoryList () {
      return this.hasCategory
        ? arrayInputLabel(this.modelForm.operation.detail.categories, 1)
        : this.$i18n.t('form.common.select')
    },

    hasFx () {
      return this.modelForm.fx_amount && this.modelForm.fx_currency
    },

    isRefund () {
      return this.modelForm.type === 'refund'
    },

    proofButton () {
      return this.hasDocument
        ? { icon: IcDocument, btnClass: 'btn--outline btn-document' }
        : {
            icon: IcAttachment,
            btnClass: 'btn--outline btn-attachment'
          }
    },

    proofLabel () {
      return this.hasDocument ? this.$i18n.tc('button.show_proofs', this.model.operation.documents.length) : this.$i18n.t('button.attach_proof')
    },

    modeFeesInfo () {
      return this.$i18n.tc(`form.transfer_details.mode_fees.${this.modelForm.mode_fees.toLowerCase()}.label`, 2)
    }
  },

  watch: {
    showHeader (value) {
      this.showHeaderBool = value
    }
  },

  async created () {
    this.model = await this.transferStore.getTransfer(this.id)
    this.modelForm = cloneDeep(this.model)
    this.showHeaderBool = this.showHeader
    this.$bus.emit('drag-and-drop-ready')

    if (this.sidePanel.initShowFile) this.showFile()
  },

  mounted () {
    this.$bus.on('hide-sidepanel', this.closeFileViewer)
    this.$bus.on('on-files-dropped', this.onFilesDropped)
    this.$bus.on('on-upload-success', this.onDocumentChange)
    this.$bus.on('delete-category', this.onDeleteCategory)
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
  },

  beforeUnmount () {
    this.$bus.off('hide-sidepanel', this.closeFileViewer)
    this.$bus.off('on-files-dropped', this.onFilesDropped)
    this.$bus.off('on-upload-success', this.onDocumentChange)
    this.$bus.off('delete-category', this.onDeleteCategory)
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
  },

  methods: {
    getBadgeProps,
    featureParams,

    hasFeature,

    downloadFileFromUrl,

    formatIban,

    formatAmount,

    formatDateText,

    closeFileViewer () {
      this.showHeaderBool = true
      this.displayFileViewer = false
    },

    showFile () {
      if (this.hasDocument) {
        this.appStore.showFileViewer({
          content: this.model.operation.documents,
          isMultiDoc: true,
          canEdit: this.canUploadFile,
          operationId: this.model.operation.uuid,
          documentsUrl: `/operations/${this.model.operation.uuid}/documents`,
          dataModel: this.model
        })
        this.displayFileViewer = true
      } else {
        this.appStore.showDropzoneModal(this.$i18n.tc('title.attach_file', 2), this.model.operation, { url: `/operations/${this.model.operation.uuid}/documents`, canAnalyzeFile: true })
      }
    },

    async cancel () {
      this.loading = true
      const success = await this.transferStore.cancelTransfer(this.modelForm.id)
      this.loading = false
      if (success) {
        this.appStore.closeSidePanel()
        this.$bus.emit('transfers-cancel')
      }
    },

    async validate () {
      this.loading = true
      const data = await this.transferStore.validateTransfer(this.modelForm)
      this.loading = false
      if (data) {
        this.$bus.emit('transfer-validate-success')
        this.appStore.closeSidePanel()
      }
    },

    isTransferEuro (transfer) {
      return transfer.fx_currency && transfer.fx_currency.iso === 'EUR'
    },

    isInternational (transfer) {
      return !transfer.beneficiary.is_sepa
    },

    displayFxAmount (transfer) {
      return !this.isTransferEuro(transfer) && this.isInternational(transfer) && transfer.fx_currency !== null
    },

    displayFxFees (transfer) {
      return this.isInternational(transfer) && transfer.fx_currency !== null
    },

    async onNewItemAdded (itemLabel) {
      const newCategory = await this.operationStore.addCategory(itemLabel)
      if (newCategory) {
        this.modelForm.operation.detail.categories.push(newCategory)
        this.$bus.emit('on-new-item-added', newCategory)
      }
    },

    onDeleteCategory (itemId) {
      const index = this.modelForm.operation.detail.categories.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.modelForm.operation.detail.categories.splice(index, 1)
      }
    },

    onCategoriesSelected (value) {
      this.modelForm.operation.detail.categories = [...value]
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.modelForm.operation.affectations.push(newAffectation)
        this.$bus.emit('on-new-item-added', newAffectation)
      }
    },

    onDeleteAffectation (itemId) {
      const index = this.modelForm.operation.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.modelForm.operation.affectations.splice(index, 1)
      }
    },

    onDocumentChange () {
      this.$bus.emit('operation-update-success')
    },

    onAffectationsSelected (value) {
      this.modelForm.operation.affectations = [...value]
    },

    onServiceSelected (service) {
      this.modelForm.operation.detail.service = service.name
    },

    onHalfBoardIncludedSelected (value) {
      this.modelForm.operation.detail.half_board_included = value
    },

    onFuelSelected (fuel) {
      this.modelForm.operation.detail.fuel = fuel
    },

    async saveOperation () {
      const detail = {
        categories: this.modelForm.operation.detail.categories,
        guest: this.modelForm.operation.detail.guest,
        nights: this.modelForm.operation.detail.nights,
        service: this.modelForm.operation.detail.service,
        charge_back: this.modelForm.operation.detail.charge_back,
        fuel: this.modelForm.operation.detail.fuel?.value,
        liters: this.modelForm.operation.detail.liters,
        mileage: this.modelForm.operation.detail.mileage,
        half_board_included: this.modelForm.operation.detail.half_board_included
      }

      const operation = await this.operationStore.updateOperationDetails(this.modelForm.operation.uuid, {
        expense_date: this.modelForm.operation.expense_date,
        comment: this.modelForm.operation.comment,
        affectations: this.modelForm.operation.affectations,
        detail
      })

      if (operation) {
        this.model.operation = operation
        this.modelForm = cloneDeep(this.model)
      }
    },

    onExpenseDateChanged () {
      this.modelForm.operation.expense_date = dayjs(this.modelForm.operation.expense_date).format('YYYY-MM-DD')
    },

    async onFilesDropped (files) {
      const documents = await this.operationStore.sendDocuments(this.modelForm.operation.uuid, files)
      this.$emit('dropped-files-upload')

      if (documents) {
        this.model.operation.documents = [...documents]
        this.showFile(this.model.operation.documents.length - files.length)
        this.$bus.emit('on-upload-success', { documents })
      }
    },

    onCategoryDetailChange (field, value) {
      if (field === 'fuel') {
        this.onFuelSelected(value)
      } else if (field === 'service') {
        this.onServiceSelected(value)
      } else if (field === 'half_board_included') {
        this.onHalfBoardIncludedSelected(value)
      } else {
        this.modelForm.operation.detail[field] = value
      }
    }
  }
}
</script>
