<template>
  <section class="section section-transfers">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          {{ $t("title.transfers") }}
        </h1>
      </div>
      <div class="section__heading__right">
        <div class="section-actions">
          <button-filters v-if="hasFeature(EFeature.TransferFilter)" />
          <component-popover v-if="isSalaries || isTransfers"
                             popover-class="popover-actions-menu popover--pull-right"
                             trigger="hover">
            <template #trigger>
              <button class="btn btn--icon btn--white">
                <i class="btn-icon">
                  <ic-settings class="ic ic--gray" />
                </i>
              </button>
            </template>
            <template v-if="isSalaries">
              <div class="popover__item"
                   @click="showExportPanel('payslip')">
                <div>{{ $t('button.export_payslips') }}</div>
              </div>
              <div class="popover__item"
                   @click="showExportPanel('advancePayment')">
                <div>{{ $t('button.export_advance_payments') }}</div>
              </div>
            </template>
            <div v-else
                 class="popover__item"
                 @click="showExportPanel('transfers')">
              <div>{{ $t('button.export_transfers') }}</div>
            </div>
          </component-popover>
          <router-link v-if="hasFeature(EFeature.Beneficiary)"
                       class="btn btn--secondary"
                       :to="{ name: 'beneficiary-lists' }">
            {{ $t("button.beneficiaries_manage") }}
          </router-link>
          <router-link v-if="hasFeature(EFeature.TransferCreation)"
                       class="btn btn--primary"
                       data-cy="transfers.create"
                       :to="{ name: 'transfers-create' }">
            {{ $t("button.new_transfer") }}
          </router-link>
        </div>
      </div>
    </div>
    <component-filters v-if="hasFeature(EFeature.TransferFilter)"
                       :list-filter="featureParams(EFeature.TransferFilter).filters"
                       :number-of-results="numberOfResults"
                       :total-amount="isPeriodicTransfers ? 0 : transfersTotalAmount"
                       @filters="filters" />
    <tabs :data="segments" />
    <router-view :key="$route.name"
                 :filter="filter"
                 @pagination-total-items="onPaginationTotalItems" />
  </section>
</template>

<script>
import { storeToRefs } from 'pinia'

import { EFeature, featureParams, hasFeature } from '@/config/features'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useTransferStore } from '@/stores/transfer'

import OperationsExport from '@/pages/operations/sidepanel/OperationsExport.vue'

import ButtonFilters from '@/components/ButtonFilters.vue'
import ComponentFilters from '@/components/Filter.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcSettings from '@/components/svg/icons/ic-settings.vue'
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'PageTransfers',

  components: {
    IcSettings,
    ButtonFilters,
    ComponentFilters,
    ComponentPopover,
    Tabs
  },

  setup () {
    const appStore = useAppStore()
    const accountStore = useAccountStore()
    const transferStore = useTransferStore()

    const { displaySalaryTab } = storeToRefs(accountStore)
    const { transfersTotalAmount } = storeToRefs(transferStore)

    return { appStore, displaySalaryTab, transferStore, transfersTotalAmount }
  },

  data () {
    return {
      types: {},
      filter: {
        search: null,
        from: null,
        to: null,
        beneficiary_id: null,
        expense_category: null,
        is_sepa: null
      },

      numberOfResults: 0
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    segments () {
      return [
        {
          label: this.$i18n.t('button.all'),
          route: { name: 'transfers' },
          visible: true
        },
        {
          label: this.$i18n.t('button.recurrents'),
          route: { name: 'transfers-periodic' },
          visible: hasFeature(EFeature.TransferPeriodic)
        },
        {
          label: this.$i18n.t('button.salary'),
          route: { name: 'transfers-salary' },
          visible: this.displaySalaryTab
        },
        {
          label: this.$i18n.t('button.cancelled'),
          route: { name: 'transfers-history' },
          visible: hasFeature(EFeature.TransferSegmentCancelled)
        }
      ].filter(route => route.visible)
    },

    isFiltered () {
      return !!(
        this.filter.search ||
        this.filter.from ||
        this.filter.to ||
        this.filter.beneficiary_id ||
        this.filter.expense_category ||
        this.filter.is_sepa
      )
    },

    isSalaries () {
      return this.$route.name === 'transfers-salary'
    },

    isPeriodicTransfers () {
      return this.$route.name === 'transfers-periodic'
    },

    isTransfers () {
      return this.$route.name === 'transfers'
    }
  },

  created () {
    this.initData()
  },

  mounted () {
    this.$bus.on('account-switched', this.initData)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.initData)
  },

  methods: {
    featureParams,

    hasFeature,

    async initData () {
      this.numberOfResults = 0
      this.filter = {
        search: null,
        from: null,
        to: null,
        beneficiary_id: null,
        expense_category: null,
        is_sepa: null
      }
    },

    onPaginationTotalItems (totalItems) {
      this.numberOfResults = totalItems
    },

    filters (filters) {
      this.filter = {
        search: filters.search,
        from: filters.from,
        to: filters.to,
        is_sepa: filters.is_sepa,
        beneficiary_id: filters.beneficiary_id,
        expense_category: filters.expense_category
      }
    },

    showExportPanel (type) {
      let persistingFilters = {}

      if (type !== 'transfers') {
        persistingFilters = {
          origin_method: type === 'payslip' ? ['import_payslip'] : ['advance_payment'],
          status: 'done'
        }
      }

      this.appStore.showSidePanel(
        OperationsExport,
        {
          type,
          filter: this.filter,
          isFiltered: this.isFiltered,
          persistingFilters
        },
        { wrapperClass: 'sidepanel-export sidepanel--md', backgroundOverlay: true }
      )
    }
  }
}
</script>
