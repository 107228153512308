<template>
  <div>
    <fieldset v-if="!isAccountSequestre"
              class="form__fieldset">
      <fieldset-title :title="$t('form.title.user_shared_access')"
                      show-number />
      <div class="form__fieldset__group define-user-permission">
        <div class="row">
          <div v-if="isOperationToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.operations"
                             :disabled="isDisabled"
                             :toggle-class="'switch-btn--sm'"
                             @update:model-value="value => onUpdateAccessForm('operations', value)" />
              <span class="switch-btn-label">{{ $t('menu.operations') }}</span>
            </div>
          </div>
          <div v-if="isTransferToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch v-model="transferAccess"
                             :disabled="isDisabled"
                             :toggle-class="'switch-btn--sm'" />
              <span class="switch-btn-label">{{ $t('menu.transfers') }}</span>
              <div v-if="user.access.transfers && !isOwnerMain && !isLoggedUser"
                   class="pull-right text-muted">
                <button data-cy="users.invite.transfer-configuration.open-button"
                        type="button"
                        class="btn-link strong"
                        @click.prevent.stop="showTransferConfigurationSidePanel">
                  {{ $t('button.configure') }}
                </button>
                <span v-tooltip="{ content: transferTooltipMessage, theme: 'tooltip', html: true }"
                      class="ic-helper">
                  <ic-info outline
                           class="ic ic--16 ic--primary" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="isDebitToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.debits"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('debits', value)" />
              <span class="switch-btn-label">{{ $t('menu.debits') }}</span>
            </div>
          </div>
          <div v-if="isCreditToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.credits"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('credits', value)" />
              <span class="switch-btn-label">{{ $t('menu.credits') }}</span>
            </div>
          </div>
          <div v-if="isStatementToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.statements"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('statements', value)" />
              <span class="switch-btn-label">{{ $t('menu.statements') }}</span>
            </div>
          </div>
          <div v-if="isCardToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch v-model="cardAccess"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm" />
              <span class="switch-btn-label">{{ $t('menu.cards') }}</span>
            </div>
          </div>
          <div v-if="isVirtualCardRequestToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.card_settings.virtual.request"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateForm('card_settings', { ...user.card_settings, virtual: { request: value } })" />
              <span class="switch-btn-label">{{ $t('generate_ecard.title_request') }}</span>
            </div>
          </div>
          <div v-if="isRefundToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.refunds"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('refunds', value)" />
              <span class="switch-btn-label">{{ hasBankDetails ? $t('menu.refunds') : $t('menu.payments') }}</span>
              <div v-if="user.access.refunds && !isOwnerUser && !isImportingUser"
                   class="pull-right text-muted">
                <button type="button"
                        class="btn-link strong"
                        @click.prevent.stop="showRefundConfigurationSidePanel">
                  {{ $t('button.configure') }}
                </button>
                <span v-tooltip="{ content: refundTooltipMessage, theme: 'tooltip', html: true }"
                      class="ic-helper">
                  <ic-info outline
                           class="ic ic--16 ic--primary" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="isMileageToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.mileages"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('mileages', value)" />
              <span class="switch-btn-label">{{ $t('menu.mileages') }}</span>
              <div v-if="user.access.mileages && !isOwnerUser && !isImportingUser"
                   class="pull-right text-muted">
                <button class="btn-link strong"
                        type="button"
                        @click.prevent.stop="showMileageConfigurationSidePanel">
                  {{ $t('button.configure') }}
                </button>
                <span v-tooltip="{ content: mileageTooltipMessage, theme: 'tooltip' }"
                      class="ic-helper">
                  <ic-info outline
                           class="ic ic--primary" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="isAccountingToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.accounting"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('accounting', value)" />
              <span class="switch-btn-label">{{ $t('menu.accounting') }}</span>
            </div>
          </div>
          <div v-if="isTreasuryToggleVisible"
               class="col col--sm-6">
            <div class="user-permission-item form-control form-control--noborder">
              <toggle-switch :model-value="user.access.treasury"
                             :disabled="isDisabled"
                             toggle-class="switch-btn--sm"
                             @update:model-value="value => onUpdateAccessForm('treasury', value)" />
              <span class="switch-btn-label">{{ $t('menu.treasury') }}</span>
            </div>
          </div>
          <div v-if="isEditAccessNotificationCheckboxVisible"
               class="col col--sm-12">
            <div class="form-check pt-1">
              <label class="form-check__label checkbox">
                <input :value="user.notify"
                       type="checkbox"
                       @change="onUpdateForm('notify', $event.target.checked)">
                <span class="checkbox__icon" />
                {{ $t('form.checkbox_edit_rights_notification') }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasWritePermission && hasFeature(EFeature.UserInvitationMandate)"
           class="procuration-block bordered-box bordered-box--padding">
        <div class="block-icon">
          <ic-square documents
                     width="52px"
                     height="52px" />
        </div>
        <div class="block-message">
          <h3 class="strong">
            {{ $t("message.user_invitation_mandate.title") }}
          </h3>
          <p>
            {{ $t("message.user_invitation_mandate.content") }}
            <a href=""
               @click.prevent="downloadMandate">
              {{ $t("message.user_invitation_mandate.download") }}
            </a>.
          </p>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import { formatAmount, isNumber } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import UserValidationLimitsConfig from '@/pages/users/sidepanel/UserValidationLimitsConfig.vue'

import FieldsetTitle from '@/components/FieldsetTitle.vue'
import ModalConfirm from '@/components/modals/Confirm.vue'
import DownloadMandate from '@/components/modals/DownloadMandate.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcSquare from '@/components/svg/icons/ic-square.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'

export default {
  components: {
    IcInfo,
    IcSquare,
    ToggleSwitch,
    FieldsetTitle
  },

  props: {
    mode: {
      type: String,
      default: 'create'
    },

    user: {
      type: Object,
      required: true
    }
  },

  emits: ['update:user'],

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { account } = storeToRefs(accountStore)
    const { user: loggedUser } = storeToRefs(authStore)

    return {
      appStore,

      account,
      loggedUser
    }
  },

  data () {
    return {
      initialUser: cloneDeep(this.user)
    }
  },

  computed: {
    EFeature () {
      return EFeature
    },

    alertTransferLimit () {
      return this.user.alert_transfer_limit
    },

    dailyTransferLimit () {
      return this.user.daily_transfer_limit
    },

    hasBankDetails () {
      return ['salarie', 'associe', 'employee', 'associate'].includes(this.user.role)
    },

    isOperationToggleVisible () {
      return this.account.access.operations && hasFeature(EFeature.SectionOperation)
    },

    isTransferToggleVisible () {
      return this.initialUser.access.transfers && this.account.access.transfers && hasFeature(EFeature.SectionTransfer)
    },

    isDebitToggleVisible () {
      return this.account.access.debits && hasFeature(EFeature.SectionDebit)
    },

    isCreditToggleVisible () {
      return this.account.access.credits && hasFeature(EFeature.SectionCredit)
    },

    isStatementToggleVisible () {
      return this.account.access.statements && hasFeature(EFeature.SectionStatement)
    },

    isCardToggleVisible () {
      return this.account.access.cards && this.initialUser.access.cards && hasFeature(EFeature.SectionCard)
    },

    isVirtualCardRequestToggleVisible () {
      return !this.user.access.cards && hasFeature(EFeature.SectionCardVirtual) && hasFeature(EFeature.CardVirtualRequest)
    },

    isRefundToggleVisible () {
      return this.account.access.transfers && hasFeature(EFeature.SectionRefund)
    },

    isMileageToggleVisible () {
      return this.account.access.mileages && hasFeature(EFeature.SectionMileage)
    },

    isAccountingToggleVisible () {
      return this.account.access.accounting && hasFeature(EFeature.SectionAccounting)
    },

    isTreasuryToggleVisible () {
      return this.account.access.treasury && hasFeature(EFeature.SectionTreasury)
    },

    isEditAccessNotificationCheckboxVisible () {
      return this.isEditingUser && this.user &&
        ((!this.user.access.cards && this.user.card_settings.virtual.request !== this.initialUser.card_settings.virtual.request) ||
          Object.keys(this.initialUser.access).some(right => this.user.access[right] !== this.initialUser.access[right]))
    },

    isAccountSequestre () {
      return this.account.status === 'sequestre'
    },

    isEditingUser () {
      return this.mode === 'edit'
    },

    isImportingUser () {
      return this.mode === 'import'
    },

    isLoggedUser () {
      return this.user.uuid === this.loggedUser.uuid
    },

    hasWritePermission () {
      return this.isEditingUser
        ? this.hasPermission(this.$permissions.usersWrite)
        : this.hasPermission(this.$permissions.invitationsWrite)
    },

    isOwnerUser () {
      return this.isEditingUser
        ? this.hasPermission(this.$permissions.accountOwner, this.user)
        : false
    },

    isOwnerMain () {
      return this.isEditingUser
        ? this.hasPermission(this.$permissions.accountOwnerMain, this.user)
        : false
    },

    isDisabled () {
      return this.isOwnerUser || !this.hasWritePermission
    },

    monthlyTransferLimit () {
      return this.user.monthly_transfer_limit
    },

    mileageTooltipMessage () {
      const limit = this.user.monthly_mileage_limit
      if (limit === 0) {
        return this.$i18n.t('tooltip.systematic_validation')
      }

      if (limit) {
        return this.$i18n.t('tooltip.validation_limit.monthly') + formatAmount(limit, this.account.currency)
      }

      return this.$i18n.t('tooltip.no_cap')
    },

    refundTooltipMessage () {
      const limits = [
        this.user.alert_refund_limit,
        this.user.daily_refund_limit,
        this.user.weekly_refund_limit,
        this.user.monthly_refund_limit
      ]

      if (limits.every(i => i === 0)) {
        return this.$i18n.t('tooltip.systematic_validation')
      }

      if (limits.some(i => isNumber(i))) {
        return [
          isNumber(this.user.alert_refund_limit) ? `${this.$i18n.t('tooltip.validation_limit.refund') + formatAmount(this.user.alert_refund_limit, this.account.currency)}<br>` : '',
          isNumber(this.user.daily_refund_limit) ? `${this.$i18n.t('tooltip.validation_limit.daily') + formatAmount(this.user.daily_refund_limit, this.account.currency)}<br>` : '',
          isNumber(this.user.weekly_refund_limit) ? `${this.$i18n.t('tooltip.validation_limit.weekly') + formatAmount(this.user.weekly_refund_limit, this.account.currency)}<br>` : '',
          isNumber(this.user.monthly_refund_limit) ? `${this.$i18n.t('tooltip.validation_limit.monthly') + formatAmount(this.user.monthly_refund_limit, this.account.currency)}<br>` : ''
        ].join('')
      }

      return this.$i18n.t('tooltip.no_cap')
    },

    cardAccess: {
      set (newVal) {
        if (newVal === false) {
          this.warnDisabling('card')
        } else {
          this.onUpdateAccessForm('cards', true)
        }
      },

      get () {
        return this.user.access.cards
      }
    },

    transferAccess: {
      set (newVal) {
        if (newVal === false) {
          this.warnDisabling('transfer')
        } else {
          this.onUpdateAccessForm('transfers', true)
        }
      },

      get () {
        return this.user.access.transfers
      }
    },

    transferTooltipMessage () {
      const limits = [
        this.user.alert_transfer_limit,
        this.user.daily_transfer_limit,
        this.user.weekly_transfer_limit,
        this.user.monthly_transfer_limit
      ]

      if (limits.every(i => i === 0)) {
        return this.$i18n.t('tooltip.systematic_validation')
      }

      if (limits.some(i => isNumber(i))) {
        return [
          isNumber(this.alertTransferLimit) ? `${this.$i18n.t('tooltip.validation_limit.transfer') + formatAmount(this.alertTransferLimit, this.account.currency)}<br>` : '',
          isNumber(this.dailyTransferLimit) ? `${this.$i18n.t('tooltip.validation_limit.daily') + formatAmount(this.dailyTransferLimit, this.account.currency)}<br>` : '',
          isNumber(this.weeklyTransferLimit) ? `${this.$i18n.t('tooltip.validation_limit.weekly') + formatAmount(this.weeklyTransferLimit, this.account.currency)}<br>` : '',
          isNumber(this.monthlyTransferLimit) ? `${this.$i18n.t('tooltip.validation_limit.monthly') + formatAmount(this.monthlyTransferLimit, this.account.currency)}<br>` : ''
        ].join('')
      }

      return this.$i18n.t('tooltip.no_cap')
    },

    weeklyTransferLimit () {
      return this.user.weekly_transfer_limit
    }
  },

  mounted () {
    this.$bus.on('confirm-remove-transfer-access', this.removeTransferAccess)
    this.$bus.on('confirm-remove-card-access', this.removeCardAccess)
  },

  beforeUnmount () {
    this.$bus.off('confirm-remove-transfer-access', this.removeTransferAccess)
    this.$bus.off('confirm-remove-card-access', this.removeCardAccess)
  },

  methods: {
    hasFeature,

    removeTransferAccess () {
      this.onUpdateAccessForm('transfers', false)
    },

    removeCardAccess () {
      this.onUpdateAccessForm('cards', false)
    },

    showMileageConfigurationSidePanel () {
      this.appStore.showSidePanel(
        UserValidationLimitsConfig,
        {
          type: 'mileage',
          invitationData: this.user,
          isEditingUser: this.isEditingUser,
          limits: {
            monthly_limit: this.user.monthly_mileage_limit
          }
        },
        {
          wrapperClass: 'sidepanel--lg',
          backgroundOverlay: true
        }
      )
    },

    showRefundConfigurationSidePanel () {
      this.appStore.showSidePanel(
        UserValidationLimitsConfig,
        {
          type: this.hasBankDetails ? 'refund' : 'payment',
          invitationData: this.user,
          isEditingUser: this.isEditingUser,
          limits: {
            alert_limit: this.user.alert_refund_limit,
            daily_limit: this.user.daily_refund_limit,
            weekly_limit: this.user.weekly_refund_limit,
            monthly_limit: this.user.monthly_refund_limit
          }
        },
        {
          wrapperClass: 'sidepanel--lg',
          backgroundOverlay: true
        }
      )
    },

    showTransferConfigurationSidePanel () {
      this.appStore.showSidePanel(
        UserValidationLimitsConfig,
        {
          type: 'transfer',
          invitationData: this.user,
          isEditingUser: this.isEditingUser,
          limits: {
            alert_limit: this.user.alert_transfer_limit,
            daily_limit: this.user.daily_transfer_limit,
            weekly_limit: this.user.weekly_transfer_limit,
            monthly_limit: this.user.monthly_transfer_limit
          },
          expenseRequestConfig: this.user.expense_request_config
        },
        {
          wrapperClass: 'sidepanel--lg',
          backgroundOverlay: true
        }
      )
    },

    warnDisabling (type) {
      this.appStore.showModal(
        ModalConfirm,
        {
          title: this.$i18n.t(`prompt.user.${type}_access.title`),
          content: this.$i18n.t(`prompt.user.${type}_access.content`),
          event: `remove-${type}-access`
        },
        { wrapperClass: 'modal--xs' }
      )
    },

    onUpdateAccessForm (key, value) {
      this.$emit('update:user', { ...this.user, access: { ...this.user.access, [key]: value } })
    },

    onUpdateForm (key, value) {
      this.$emit('update:user', { ...this.user, ...{ [key]: value } })
    },

    downloadMandate () {
      this.appStore.showModal(
        DownloadMandate,
        {},
        { onBackgroundLeaveAction: false })
    }
  }
}
</script>
