<template>
  <div class="fs-page__content page-newuser-signup">
    <div v-if="loading"
         class="section__loader">
      <loader-spinner />
    </div>
    <template v-else>
      <div v-if="cardHolderInvitation"
           class="img-topright">
        <img src="@/assets/img/logo-card-topright.png">
      </div>
      <h1 class="page-title">
        {{ pageTitle }}
      </h1>
      <template v-if="validToken">
        <p class="page-msg">
          {{ $t(`${userType}.text`) }}
          <template v-if="isInvitationModeChoice">
            {{ company }}.
          </template>
        </p>
        <router-view :token="token"
                     :decoded-token="decodedToken"
                     @success="onSuccess" />
      </template>
      <p v-else
         class="page-msg">
        {{ errorMessage }}. {{ $t('invitation.error.incorrect_link.content') }}
      </p>
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { decodeJwt } from 'jose'
import { storeToRefs } from 'pinia'

import store from '@/config/store'
import { initLogRocketInvitation, trackLogRocket } from '@/helpers/logrocket'
import { useAnalyticStore } from '@/stores/analytics'
import { useAuthStore } from '@/stores/auth'
import { useInvitationStore } from '@/stores/invitation'

import LoaderSpinner from '@/components/LoaderSpinner.vue'

export default {
  name: 'PageInvitations',

  components: {
    LoaderSpinner
  },

  props: {
    token: {
      type: String,
      default: null
    }
  },

  setup () {
    const analyticStore = useAnalyticStore()
    const authStore = useAuthStore()
    const invitationStore = useInvitationStore()

    const { user: loggedUser, userFullName } = storeToRefs(authStore)
    const { cookies } = storeToRefs(analyticStore)

    return {
      invitationStore,

      cookies,
      loggedUser,
      userFullName
    }
  },

  data () {
    return {
      store,
      loading: false,
      company: '',
      validToken: false,
      decodedToken: null,
      expiredToken: false,
      invitationMode: null,
      cardHolderInvitation: false,
      errorMessage: null
    }
  },

  computed: {
    isInvitationModeChoice () {
      return !this.invitationMode
    },

    userType () {
      return this.cardHolderInvitation ? 'invitation.card_holder' : `invitation.${this.invitationMode || 'choice'}`
    },

    pageTitle () {
      return this.validToken ? `${this.$t('title.join')} ${this.company}`.trim() : this.$t('invitation.error.incorrect_link.title')
    }
  },

  async created () {
    if (!this.token) {
      this.errorMessage = this.$i18n.t('invitation.error.expired_token.title')
      return
    }

    this.loading = true

    const { success, error } = await this.invitationStore.checkInvitationStatus(this.token)
    this.validToken = success
    this.errorMessage = error

    const decodedToken = decodeJwt(this.token)
    if (!decodedToken) { this.validToken = false }

    if (dayjs.unix(decodedToken.exp).isBefore(dayjs())) { this.validToken = false }

    this.loading = false

    if (!this.validToken) {
      this.errorMessage = this.errorMessage || this.$i18n.t('invitation.error.expired_token.title')
      return
    }

    this.decodedToken = decodedToken
    this.company = decodedToken.com
    this.cardHolderInvitation = decodedToken.skn === 'card_holder'

    initLogRocketInvitation()
    this.$bus.on('analytics-cookie-consent', initLogRocketInvitation())

    if (this.cardHolderInvitation) {
      await this.$router.push({ name: 'invitation-create-account', query: { token: this.token } })
    }
  },

  beforeUnmount () {
    this.$bus.off('analytics-cookie-consent', initLogRocketInvitation())
  },

  methods: {
    onSuccess () {
      trackLogRocket(this.loggedUser.uuid, this.userFullName, this.loggedUser.email)
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>
