<template>
  <div class="modal__content center modal-edit-pin">
    <modal-header v-if="isStepNewCode || isStepConfirmCode"
                  align-title="center"
                  :title="title"
                  @close="$emit('close')" />
    <div class="modal__body"
         style="min-height: 20rem">
      <template v-if="isStepNewCode || isStepConfirmCode">
        <p>{{ subtitle }}</p>
        <verification-code v-if="isStepNewCode"
                           :key="step"
                           v-model="pin.pin"
                           :has-separator="false"
                           :number-of-digits="4" />
        <verification-code v-else-if="isStepConfirmCode"
                           :key="step"
                           v-model="pin.pin_confirm"
                           :has-separator="false"
                           :number-of-digits="4" />
        <div class="modal__footer modal__footer--centered">
          <button type="button"
                  class="btn btn--gray"
                  @click="onBack">
            {{ isStepNewCode ? $t('button.cancel') : $t('button.previous') }}
          </button>
          <component-button type="button"
                            class="btn btn--primary"
                            :label="isStepNewCode ? $t('button.next') : $t('button.confirm')"
                            :disabled="!canSubmit"
                            @click="isStepNewCode ? onNewCodeSubmit() : onConfirmCodeSubmit()" />
        </div>
      </template>
      <div v-else>
        <component-placeholder v-if="isStepSuccess"
                               :label="$t('prompt.cards.pin.success.title')"
                               :content="isVirtualCard ? '' : $t('prompt.cards.pin.success_reset.content')"
                               :button="$t('button.ok_understood')"
                               button-theme="default"
                               @btn-click="$emit('close')">
          <image-card success />
        </component-placeholder>
        <component-placeholder v-else
                               :label="$t('prompt.cards.pin.failed.title')"
                               :content="warningMessage"
                               :button="$t('button.try_again')"
                               button-theme="default"
                               @btn-click="retryEdit">
          <image-card warning />
        </component-placeholder>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/config/store'
import { isVirtualCard } from '@/helpers/utils/card'
import { useCardStore } from '@/stores/card'
import { ECardProcessor } from '@/types/card.d'

import ComponentButton from '@/components/Button.vue'
import ModalHeader from '@/components/modal-template/ModalHeader.vue'
import ComponentPlaceholder from '@/components/Placeholder.vue'
import ImageCard from '@/components/svg/img/card.vue'
import VerificationCode from '@/components/VerificationCode.vue'

export default {
  components: {
    VerificationCode,
    ComponentButton,
    ComponentPlaceholder,
    ImageCard,
    ModalHeader
  },

  props: {
    card: {
      type: Object,
      required: true
    }
  },

  emits: ['close'],

  setup () {
    const cardStore = useCardStore()

    return { cardStore }
  },

  data () {
    return {
      store,
      pin: {
        control_token: null,
        pin: '',
        pin_confirm: ''
      },

      step: 'pin',
      warningText: ''
    }
  },

  computed: {
    isVirtualCard () {
      return isVirtualCard(this.card)
    },

    isStepNewCode () {
      return this.step === 'pin'
    },

    isStepConfirmCode () {
      return this.step === 'pin_confirm'
    },

    isStepSuccess () {
      return this.step === 'success'
    },

    title () {
      if (this.isStepNewCode) {
        return this.$i18n.t('prompt.cards.pin.new.title')
      } else if (this.isStepConfirmCode) {
        return this.$i18n.t('prompt.cards.pin.confirm_new.title')
      }
      return null
    },

    subtitle () {
      if (this.isStepNewCode) {
        return this.$i18n.t('prompt.cards.pin.new.subtitle')
      } else if (this.isStepConfirmCode) {
        return this.$i18n.t('prompt.cards.pin.confirm_new.subtitle')
      }
      return null
    },

    canSubmit () {
      return this.isStepNewCode ? this.pin.pin.length === 4 : this.pin.pin_confirm.length === 4
    },

    warningMessage () {
      return this.warningText || this.$i18n.t('prompt.cards.pin.failed.content')
    }
  },

  async created () {
    if (this.card.subtype === ECardProcessor.WORLDLINE) return

    const control_token = await this.cardStore.getCardPinAccessToken(this.card.uuid)
    control_token
      ? this.pin.control_token = control_token
      : this.$emit('close')
  },

  mounted () {
    document.addEventListener('keypress', this.onKeyPress)
  },

  beforeUnmount () {
    document.removeEventListener('keypress', this.onKeyPress)
  },

  methods: {
    onKeyPress (e) {
      if (e.key === 'Enter') {
        this.step === 'pin' ? this.onNewCodeSubmit() : this.onConfirmCodeSubmit()
      }
    },

    onBack () {
      this.isStepNewCode
        ? this.$emit('close')
        : this.step = 'pin'
    },

    async onNewCodeSubmit () {
      this.step = 'pin_confirm'
    },

    async onConfirmCodeSubmit () {
      const { success, error } = await this.cardStore.updateCardPin(this.card.uuid, { ...this.pin }, this.card.subtype)
      this.step = success ? 'success' : 'failure'
      this.warningText = error
    },

    retryEdit () {
      this.step = 'pin'
      this.pin.pin = ''
      this.pin.pin_confirm = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.placeholder-action-description
  margin-bottom auto
  margin-top 0
</style>
