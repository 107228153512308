import { ECoreBadgeFill, ECoreBadgeTheme } from '@common/core-ui'

import { isCappedVirtualCard, isClassicVirtualCard, isOneTimeVirtualCard, isRecurringVirtualCard, TCard } from '@/helpers/utils/card'

type TBadgeProps = {
  fill?: ECoreBadgeFill,
  theme: ECoreBadgeTheme
}

export function getBadgeProps (status: string): TBadgeProps {
  switch (status) {
    case 'warning':
    case 'expire_soon':
    case 'pending':
    case 'new':
    case 'processing':
    case 'locked_partner':
      return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.Warning }
    case 'info':
    case 'to_validate':
    case 'to_define':
      return { theme: ECoreBadgeTheme.Info }
    case 'to_validate_by_bank':
    case 'awaiting_validation':
    case 'awaiting':
      return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.Info }
    case 'success':
    case 'done':
    case 'used':
    case 'active':
    case 'accepted' :
      return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.Success }
    case 'red':
    case 'deleted':
    case 'fail':
    case 'rejected':
    case 'blocked':
    case 'error':
    case 'refused':
    case 'locked_proof':
    case 'to_close':
      return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.Danger }
    case 'default':
    case 'cancelled':
    case 'inactive':
    case 'tocome':
    case 'expired':
    case 'locked':
    case 'locked_service':
    case 'ordered':
      return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.GrayLow }
    case 'suspended':
      return { fill: ECoreBadgeFill.Outline, theme: ECoreBadgeTheme.GrayLow }
    case 'sent':
      return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.Purple }
  }

  return { fill: ECoreBadgeFill.Shaded, theme: ECoreBadgeTheme.GrayLow }
}

export function cardBadgeTheme (type: string): ECoreBadgeTheme {
  if (isCappedVirtualCard({ type } as TCard)) return ECoreBadgeTheme.VirtualCardCapped
  if (isOneTimeVirtualCard({ type } as TCard)) return ECoreBadgeTheme.VirtualCardOneTime
  if (isRecurringVirtualCard({ type } as TCard)) return ECoreBadgeTheme.VirtualCardRecurring
  if (isClassicVirtualCard({ type } as TCard)) return ECoreBadgeTheme.VirtualCard
  return ECoreBadgeTheme.GrayLow
}
