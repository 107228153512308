<template>
  <div class="transaction-user">
    <div class="transaction-user__avatars">
      <user-avatar :key="pictures[0]?.url"
                   :picture="pictures[0]"
                   :placeholder-label="userNames[0]"
                   :size="44" />
      <user-avatar v-if="nbUser === 2"
                   :key="pictures[1]?.url"
                   :picture="pictures[1]"
                   :placeholder-label="userNames[1]"
                   :size="44" />
      <span v-else-if="nbUser > 2"
            class="user-avatar user-avatar-multiple user-avatar--rounded"><strong>+{{ nbUser - 1 }}</strong></span>
    </div>
    <div class="transaction-user__desc">
      <span class="text-muted"
            :class="{ 'missing-field': missingField }">{{ text }}</span>
      <div class="transaction-user__name"
           :class="{ 'is-link': isEditable }">
        <span>{{ label }}</span>
        <ic-pencil v-if="isEditable"
                   class="ic ic-pencil ic--18 ic--gray" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import IcPencil from '@/components/svg/icons/ic-pencil.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    IcPencil,
    UserAvatar
  },

  props: {
    pictures: {
      type: Array,
      default: () => []
    },

    text: {
      type: String,
      default: ''
    },

    userNames: {
      type: Array,
      default: () => []
    },

    placeholder: {
      type: String,
      default: ''
    },

    missingField: Boolean,
    isEditable: Boolean
  },

  computed: {
    nbUser (): number {
      return this.userNames.length
    },

    label (): string {
      if (!this.nbUser) {
        return this.placeholder
      }
      if (this.nbUser === 1) {
        return this.userNames[0]
      }
      if (this.nbUser === 2) {
        return `${this.userNames[0]} ${this.$i18n.t('general.and')} ${this.userNames[1]}`
      }

      return this.$i18n.tc('form.operations.attributions.multiple', this.nbUser - 1, {
        name: this.userNames[0],
        number: this.nbUser - 1
      })
    }
  }
}
</script>

<style lang="stylus">
.transaction-user
  display flex
  align-items center
  margin-bottom 4rem

  .transaction-user__avatars
    display flex
    align-items center

    .user-avatar
      width 44px
      height @width
      background-color lighten($colorShinyGray, 50%)
      border 2px solid white
      z-index 2

      + .user-avatar
        z-index 1
        margin-left -2rem

      &.user-avatar-multiple
        text-align center
        line-height 4rem
        background-color $defaultColor
        z-index 3
        color white
        font-size 1.5rem

        > strong
          margin-left -2px

  &__desc
    margin-left 1.2rem
    display flex
    flex-direction column
    line-height 1.5

    .transaction-user__name
      font-weight 500

  .ic-pencil
    margin-left 0.5rem
</style>
