<template>
  <validation-form ref="orderCardForm"
                   v-slot="{ errors, meta, isSubmitting }"
                   :validation-schema="schemas[activeStep]"
                   @keydown.enter.prevent
                   @submit="onSubmit"
                   @invalid-submit="onInvalidSubmit">
    <div class="section__content">
      <component-steps :steps="steps.map(step => step.label)"
                       :active-step="activeStep" />
      <div class="form form--w1000 form--centered">
        <component-alert v-if="Object.keys(errors).length"
                         type="error"
                         :message="$t('message.warning.form_not_valid')" />
        <component-alert v-else-if="displayPhysicalCardFees"
                         type="info"
                         :message="cardFeesLabel"
                         data-cy="cards.create.form.fees"
                         :show-api-messages="false" />
        <keep-alive>
          <component :is="currentStep"
                     v-model:card="card"
                     v-model:address="card.address"
                     v-model:accept="accept"
                     :errors="errors"
                     :virtual="false"
                     :hide-user-select="isOwner"
                     :is-user-owner="isOwner"
                     :invitation="card.invitation"
                     @select-pin-choice="onSelectPinChoice"
                     @change-pin-code="onChangePinCode"
                     @disable-submit="disableSubmit"
                     @is-new-user="toggleIsNewUser"
                     @update-card-settings="updateCardSettings"
                     @update-card-field="updateCardField"
                     @user-selected="toggleUserSelected" />
        </keep-alive>
        <div class="form-buttons">
          <button class="btn btn--gray"
                  type="button"
                  :disabled="isSubmitting"
                  @click="previousStep">
            {{ isFirstStep ? $t('button.cancel') : $t('button.previous') }}
          </button>
          <button class="btn btn--primary"
                  :disabled="!meta.valid || isSubmitting || submitDisabled"
                  data-cy="cards.create.form.submit">
            {{ buttonLabel }}
          </button>
        </div>
      </div>
    </div>
  </validation-form>
</template>

<script>
import { storeToRefs } from 'pinia'
import * as yup from 'yup'

import { EFeature, hasFeature } from '@/config/features'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useApiStore } from '@/stores/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useCardStore } from '@/stores/card'

import BankValidationRequest from '@/pages/cards/modal/BankValidationRequest.vue'
import CardSettings from '@/pages/cards/order/components/CardSettings.vue'
import OwnerInformation from '@/pages/cards/order/components/OwnerInformation.vue'

import ComponentAlert from '@/components/Alert.vue'
import CustomPinInvalid from '@/components/modals/CustomPinInvalid.vue'
import ComponentSteps from '@/components/Steps.vue'
import IcArrow from '@/components/svg/icons/ic-arrow.vue'

import DeliveryAddress from './components/DeliveryAddress.vue'
import PinCode from './components/PinCode.vue'

export default {
  components: {
    IcArrow,
    ComponentAlert,
    ComponentSteps,
    OwnerInformation,
    CardSettings,
    DeliveryAddress,
    PinCode
  },

  props: {
    role: {
      type: String,
      required: true
    },

    type: {
      type: String,
      default: 'card'
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const apiStore = useApiStore()
    const authStore = useAuthStore()
    const cardStore = useCardStore()

    const { user } = storeToRefs(authStore)
    const { error: apiError } = storeToRefs(apiStore)
    const { account, physicalCardTypes } = storeToRefs(accountStore)

    return {
      cardStore,

      account,
      appStore,
      apiError,
      user,
      physicalCardTypes
    }
  },

  data () {
    return {
      hasUserName: false,
      editShippingAddress: false,
      card: {
        type: this.type,
        pin: null,
        fees: null,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        picture: this.user.picture,
        user_uuid: this.user.uuid,
        settings: {
          authorization_range: {
            monday: {
              enabled: true,
              start: null,
              end: null
            },

            tuesday: {
              enabled: true,
              start: null,
              end: null
            },

            wednesday: {
              enabled: true,
              start: null,
              end: null
            },

            thursday: {
              enabled: true,
              start: null,
              end: null
            },

            friday: {
              enabled: true,
              start: null,
              end: null
            },

            saturday: {
              enabled: true,
              start: null,
              end: null
            },

            sunday: {
              enabled: true,
              start: null,
              end: null
            }
          },

          authorization_categories: [
            {
              name: 'retrait',
              enabled: true,
              limit: {
                payment_daily_limit: null,
                payment_monthly_limit: null,
                payment_unit_limit: null,
                payment_weekly_limit: null,
                payment_annually_limit: null
              }
            }
          ],

          country_holidays: this.account.settings.default_card_country_holidays,
          non_working_day: true,
          nfc: true,
          distance_selling: true,
          magnetic_strip: true,
          fx: true,
          proof_required: false,
          proof_required_delay: null,
          proof_required_delay_label: '',
          display_limit: false,
          mobile_payment: true,
          payment: {
            daily: {
              enabled: false,
              limit: 0
            },

            weekly: {
              enabled: false,
              limit: 0
            },

            monthly: {
              enabled: false,
              limit: 0
            },

            annually: {
              enabled: false,
              limit: 0
            }
          },

          payment_transaction: {
            enabled: false,
            limit: 0
          },

          withdrawal: {
            daily: {
              enabled: false,
              limit: 0
            },

            weekly: {
              enabled: false,
              limit: 0
            },

            monthly: {
              enabled: false,
              limit: 0
            },

            annually: {
              enabled: false,
              limit: 0
            }
          },

          withdrawal_transaction: {
            enabled: false,
            limit: 0
          }
        },

        address: {
          add_company_name: true,
          /* QNB Address, TODO: rendre dynamique avec une requete API
          street: this.account.company.address.street,
          details: this.account.company.address.details,
          zip: this.account.company.address.zip,
          city: this.account.company.address.city,
          country: this.account.company.address.country || 'FR'
          */
          street: '65 Avenue d\'Iena',
          details: '',
          zip: '75016',
          city: 'Paris',
          country: 'FR'
        },

        invitation: {
          email: '',
          role: '',
          role_label: '',
          // QNB Address, TODO: rendre dynamique avec une requete API
          // company: this.account.company.name,
          company: 'QNB PARIS',
          affectations: []
        }
      },

      cardCost: '',
      loadingCardCost: true,
      userSelectorIsOpen: false,
      userInput: '',
      isNewUser: false,
      choice: null,
      userSelected: null,

      hasMissingInfos: false,

      activeStep: 0,
      submitDisabled: false,
      accept: false,
      pinChoice: 'random',
      customPinCode: null
    }
  },

  computed: {
    addressSchema () {
      return yup.object({
        add_company_name: yup.boolean(),
        street: yup.string().required(),
        postcode: yup.string().required(),
        city: yup.string().required(),
        country: yup.string().required(),
        accept: yup.boolean().when('add_company_name', {
          is: true,
          then: () => yup.boolean().required().oneOf([true])
        })
      })
    },

    pinSchema () {
      return yup.object({
        pinCodeOption: yup.string().required(),
        digits0: yup.string().when('pinCodeOption', {
          is: 'custom',
          then: () => yup.string().required()
        }),
        digits1: yup.string().when('pinCodeOption', {
          is: 'custom',
          then: () => yup.string().required()
        }),
        digits2: yup.string().when('pinCodeOption', {
          is: 'custom',
          then: () => yup.string().required()
        }),
        digits3: yup.string().when('pinCodeOption', {
          is: 'custom',
          then: () => yup.string().required()
        })
      })
    },

    userSchema () {
      if (this.isOwner) {
        return yup.object({
          first_name: yup.string().required(),
          last_name: yup.string().required()
        })
      }

      if (this.userSelected) {
        return yup.object({
          first_name: yup.string().required(),
          last_name: yup.string().required(),
          email: yup.string().required()
        })
      }

      if (this.isNewUser) {
        return yup.object({
          first_name: yup.string().required(),
          last_name: yup.string().required(),
          email: yup.string().required(),
          phone: yup.string().validPhoneNumber().nullable()
        })
      }

      return {}
    },

    schemas () {
      return [
        this.userSchema,
        this.addressSchema,
        {},
        this.pinSchema
      ]
    },

    steps () {
      const steps = [{
        component: 'owner-information',
        label: this.$i18n.t('order_card.step.owner_infos')
      }, {
        component: 'delivery-address',
        label: this.$i18n.t('order_card.step.delivery_address')
      }, {
        component: 'card-settings',
        label: this.$i18n.t('order_card.step.card_settings')
      }]
      if (this.isOwner && hasFeature(EFeature.CardCreateCustomPin)) {
        steps.push({
          component: 'pin-code',
          label: this.$i18n.t('title.card_pin_code')
        })
      }
      return steps
    },

    buttonLabel () {
      return this.isLastStep
        ? this.$i18n.t('button.order')
        : this.$i18n.t('button.next')
    },

    currentStep () {
      return this.steps[this.activeStep].component
    },

    isFirstStep () {
      return this.activeStep === 0
    },

    isLastStep () {
      return this.activeStep === (this.steps.length - 1)
    },

    isValidCustomPin () {
      return this.customPinCode && this.customPinCode.length === 4 && this.isPinCustomChoice
    },

    isPinCustomChoice () {
      return this.pinChoice === 'custom'
    },

    cardFeesLabel () {
      if (this.card.fees === null) return null
      return `${this.$i18n.t('prompt.cards.order.cost.label')} ${(this.card.fees === 0) ? this.$i18n.t('prompt.cards.order.cost.free') : this.$i18n.t('prompt.cards.order.cost.pay', { price: formatAmount(this.card.fees) })}`
    },

    backRoute () {
      return this.physicalCardTypes.length === 1
        ? { name: 'order-cards-user-choice' }
        : { name: 'order-card-type-choice', params: { role: this.role } }
    },

    isOwner () {
      return this.role === 'owner'
    },

    hasPhysicalCardFeesPermission () {
      return this.hasPermission(this.$permissions.cardsPhysicalFeesRead)
    },

    displayPhysicalCardFees () {
      return this.isFirstStep && !this.loadingCardCost && this.cardFeesLabel && this.hasPhysicalCardFeesPermission
    }
  },

  watch: {
    apiError: function () {
      if (this.apiError?.code === 422) {
        this.apiError.fields?.forEach(item => {
          this.$refs.orderCardForm.setFieldError(item.field, item.message)
        })
      }
    }
  },

  created () {
    this.fetchCardFees()
  },

  mounted () {
    this.$bus.on('account-switched', this.fetchCardFees)
  },

  beforeUnmount () {
    this.$bus.off('account-switched', this.fetchCardFees)
  },

  methods: {
    async fetchCardFees () {
      if (!this.hasPhysicalCardFeesPermission) return
      this.loadingCardCost = true
      this.card.fees = await this.cardStore.getCardOrderFees(this.card.type)
      this.loadingCardCost = false
    },

    formatCard () {
      const card = {
        type: this.card.type,
        phone: this.card.phone,
        pin: this.isValidCustomPin ? this.customPinCode : null,
        address: this.card.address,
        settings: { ...this.card.settings }
      }

      if (this.isOwner || this.userSelected || this.isNewUser) {
        card.user_uuid = this.card.user_uuid
      }

      if (this.isNewUser) {
        card.first_name = this.card.first_name
        card.last_name = this.card.last_name
        card.invitation = this.card.invitation
      }

      return card
    },

    async onSubmit () {
      const card = this.formatCard()

      if (this.isLastStep) {
        await this.orderCard(card)
      } else {
        const success = await this.cardStore.validateOrderCardStep(card, this.activeStep)
        if (success) {
          this.activeStep += 1
          this.scrollTopHandler()
        }
      }
    },

    onInvalidSubmit () {
      return this.scrollTopHandler()
    },

    async orderCard (card) {
      this.disableSubmit(true)

      const orderedCard = await this.cardStore.orderCard(card, this.account.card_partner)
      return orderedCard ? this.onOrderSuccess(orderedCard.custom_pin_is_set, orderedCard) : this.onOrderFailed()
    },

    onOrderSuccess (customPinIsSet, orderedCard) {
      if (orderedCard.status === 'to_validate_by_bank') {
        return this.$router.push({ name: 'cards-physical-to-validate' }).then(() => {
          this.appStore.showModal(BankValidationRequest)
        })
      }

      if (customPinIsSet === false) {
        return this.$router.push({ name: 'cards-detail', params: { uuid: orderedCard.uuid } }).then(() => {
          this.appStore.showModal(CustomPinInvalid)
        })
      }

      showToastSuccess(this.$t('message.success.card.order'))
      return this.$router.push({ name: 'cards-detail', params: { uuid: orderedCard.uuid } })
    },

    onOrderFailed () {
      this.disableSubmit(false)
    },

    previousStep () {
      if (this.isFirstStep) {
        return this.$router.push(this.backRoute)
      }

      this.activeStep -= 1
      this.scrollTopHandler()
    },

    disableSubmit (value) {
      this.submitDisabled = value
    },

    scrollTopHandler () {
      this.$bus.emit('scrollToTop')
    },

    toggleIsNewUser (value) {
      this.isNewUser = value
    },

    toggleUserSelected (value) {
      this.userSelected = value
    },

    onSelectPinChoice (choice) {
      this.pinChoice = choice
    },

    onChangePinCode (pin) {
      this.customPinCode = pin
    },

    updateCardSettings (data) {
      this.card.settings = data
      this.$bus.emit('card-updated')
    },

    updateCardField (data) {
      Object.keys(data).forEach(key => {
        this.card[key] = data[key]
      })
    }
  }
}
</script>
