import permissions from '@/config/permissions'
import { IAffectation } from '@/types/affectation.d'
import { IBeneficiary } from '@/types/beneficiary.d'
import { ICard } from '@/types/card.d'
import { IOperationCategory } from '@/types/category.d'
import { ICurrency } from '@/types/currency.d'
import { Nullable } from '@/types/default.d'
import { EDocumentStatus, IDocument } from '@/types/document.d'
import { IUser, IUserCard } from '@/types/user.d'

export enum EType {
  Debit = 'debit',
  Credit = 'credit'
}

export enum ECategory {
  DepositCash = 'deposit_cash',
  DepositCheck = 'deposit_check',
  Fees = 'fees',
  Other = 'other',
  Payment_check = 'payment_check',
  CreditCard = 'credit_card',
  RefundAtm = 'redraw_atm',
  Refund = 'refund',
  Prelevement = 'prelevement'
}

export interface ICategory {
  default: boolean
  id: number
  label: string
  name: string
}

export enum EStatus {
  Cancelled = 'cancelled',
  Done = 'done',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  Deleted = 'deleted'
}

export interface IDetail { // to verify
  authorization: object
  categories: Array<ICategory>
  charge_back: Nullable<boolean>
  fuel: Nullable<string>
  guest: Nullable<number>
  half_board_included: Nullable<boolean>
  liters: Nullable<number>
  mandate_unique_reference: Nullable<string>
  mileage: Nullable<number>
  nights: Nullable<number>
  service: Nullable<string>
  vat: Nullable<{
    vat_amount: number
    vat_rate: number
  }>
}

export const OperationTypes = {
  change_letter: 'Lettre de change',
  credit_card: 'Carte bancaire',
  deposit_cash: 'Dépôt Cash',
  deposit_check: 'Dépôt Chèque',
  fees: 'Frais Bancaires',
  irregularities: 'Irrégularités',
  other: 'Autre',
  payment_check: 'Paiement Chèque',
  prelevement: 'Prélèvement',
  refund: 'Remboursement',
  transfer: 'Virement'
} as const

type TOperationProps = keyof typeof OperationTypes
export type TOperationTypes = Partial<Record<TOperationProps, string>>

export interface IOperation {
  accounting_date: Nullable<string>
  affectations: Array<IAffectation>
  amount: number
  attributions: Array<IUserCard> // to verify
  bank_reference: Nullable<string> // to verify
  beneficiary: Nullable<IBeneficiary> // to verify
  bill: Nullable<IDocument>
  can_update_compliance: boolean // to verify
  categories: Array<ICategory> // to verify
  category: ECategory
  category_label: string
  comment: Nullable<string>
  compliant_at: Nullable<number> // to verify
  created_at: number
  credit_card: Nullable<ICard> // to verify
  currency: ICurrency // to verify
  detail: IDetail // to verify
  document_required: boolean // to verify
  documents: Array<IDocument>,
  documents_actions: Array<EDocumentStatus> // to verify
  documents_status: EDocumentStatus,
  executed_at: number
  expense_control: {
    control_at: Nullable<number>
    control_user: Nullable<IUserCard>
    status: string,
    status_label: string
  },
  expense_date: Nullable<string>
  fx_amount: Nullable<number> // to verify
  fx_currency: Nullable<number> // to verify
  hint: Nullable<string> // to verify
  is_compliant: Nullable<boolean> // to verify
  is_pre_auth: boolean
  label: string
  lost_receipt_at: Nullable<number> // to verify
  mcc_category: Nullable<IOperationCategory> // to verify
  nb_documents: number
  optional_receipt_at: Nullable<number> // to verify
  permissions: Array<permissions> // to verify
  proof_required_before: Nullable<boolean> // to verify
  reject_reason: Nullable<string>
  reliability: Nullable<object> // to verify
  reliability_status: Nullable<string> // to verify
  settlement_date: Nullable<string>
  short_label: string // to verify
  spender: boolean // to verify
  status: EStatus
  team: Nullable<object> // to verify
  type: EType
  user: Nullable<IUser> // to verify
  uuid: string
  value_date: Nullable<string>
}
