<template>
  <section class="section section-card-order"
           :class="{ 'section--centered': !isLastStep }">
    <div class="section__heading">
      <div class="section__heading__left">
        <h1 class="section-title">
          <button class="btn-link btn-back"
                  @click="goBack">
            <ic-arrow class="ic ic-arrow-prev ic--24 ic--dark" />
          </button>
          {{ titleLabel }}
        </h1>
      </div>
    </div>
    <div class="section__content">
      <component :is="steps[activeStep].component"
                 v-model:type="type"
                 v-model:role="role"
                 v-bind="steps[activeStep].props"
                 :physical-card="true"
                 @update:role="onRoleUpdate"
                 @update:type="goNext"
                 @previous-step="goBack" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import router from '@/router'
import { useAccountStore } from '@/stores/account'
import { EPhysicalCard } from '@/types/card.d'

import CardTypeChoice from '@/pages/cards/order/CardTypeChoice.vue'
import CardUserChoice from '@/pages/cards/order/CardUserChoice.vue'
import OrderCard from '@/pages/cards/order/physical/OrderCard.vue'

import IcArrow from '@/components/svg/icons/ic-arrow.vue'

const { t } = useI18n()

const accountStore = useAccountStore()
const { physicalCardTypes } = storeToRefs(accountStore)

const activeStep = ref(0)
const role = ref<'owner' | 'user' | 'multiple'>()
const type = ref<EPhysicalCard>()

const isFirstStep = computed(() => {
  return activeStep.value === 0
})

const isLastStep = computed(() => {
  return activeStep.value === steps.value.length - 1
})

const isCardRequest = computed(() => {
  return hasPermission(permissions.cardsVirtualRequest)
})

const titleLabel = computed(() => {
  if (role.value === 'user') {
    return t('order_card.title_employee')
  }

  return t('order_card.title')
})

const steps = computed(() => {
  return [
    {
      component: CardUserChoice,
      visible: !isCardRequest.value
    }, {
      component: CardTypeChoice,
      visible: physicalCardTypes.value.length > 1,
      props: {
        title: t('order_card.choice.type.title')
      }
    }, {
      component: OrderCard,
      visible: true
    }
  ].filter(route => route.visible)
})

onMounted(() => {
  if (physicalCardTypes.value.length === 1) {
    type.value = physicalCardTypes.value[0]
  }

  if (isCardRequest.value) {
    role.value = 'owner'
  }
})

function goBack () {
  if (isFirstStep.value) {
    return router.push({ name: 'cards-physical' })
  }

  activeStep.value--
}

function goNext () {
  activeStep.value++
}

function onRoleUpdate (role: string) {
  if (role === 'multiple') {
    return router.push({ name: 'order-multiple-cards' })
  }

  goNext()
}
</script>
