import { defineStore } from 'pinia'

import axiosClient from '@/api'

export const useExpenseStore = defineStore('expense', {
  state: () => {
    return {
      expenses: [],
      expensesPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      expensesTotalAmount: 0
    }
  },

  actions: {
    async enableExpenseFeature () {
      try {
        const { data } = await axiosClient.post('/operations/control/init')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getExpenses (state, page, params = {}) {
      params.status = state === 'pending' ? 'pending' : 'accepted,rejected'
      params.page = page
      params.mode = 'collection'

      try {
        const { data, headers } = await axiosClient.get('/operations/control', { params })
        const collection = this.parseCollection(data, headers)

        collection.items.forEach(expense => { expense.selected = false })
        this.expenses = collection.items
        this.expensesTotalAmount = collection.totalAmount
        this.expensesPagination = collection.pagination
      } catch (e) {
        console.error(e)
        this.expenses = []
        this.expensesTotalAmount = 0
      }
    },

    async acceptExpense (expense) {
      try {
        await axiosClient.put(`/operations/${expense.uuid}/control/accept`)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async acceptAllExpenses (expenses) {
      try {
        await axiosClient.post('/operations/control/accept', { operations: expenses })
      } catch (e) {
        console.error(e)
      }
    },

    async resetExpense (uuid) {
      try {
        await axiosClient.put(`/operations/${uuid}/control/reset`)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async requestMoreInformations (uuid) {
      try {
        await axiosClient.post(`/operations/${uuid}/information-request`)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },

    async getNumberOfPendingExpenses (filter) {
      const params = { ...filter, ...{ status: 'pending' } }
      try {
        const { headers } = await axiosClient.head('/operations/control', { params })
        return this.parsePagination(headers).totalItems
      } catch (e) {
        console.error(e)
        return 0
      }
    },

    async getExpensesCount (params = {}) {
      try {
        const { data } = await axiosClient.get('/operations/control/count', { params })
        return data
      } catch (error) {
        console.error(error)
        return 0
      }
    }
  }
})
