<template>
  <div class="sidepanel-details__entry">
    <span class="entry-label text-muted"
          :class="{ 'missing-field': isMissingField }">{{ affectationLabel }}</span>
    <div class="entry-value right">
      <div v-if="!canEditAffectations && !hasAffectation">
        {{ $t('general.none') }}
      </div>
      <div v-else
           class="select select--inline select--rtl"
           :class="{ 'select--disabled': !canEditAffectations }">
        <component-dropdown label="affectations"
                            :dropdown-width="35.4"
                            data-cy="affectations"
                            multiselect
                            search
                            :search-placeholder="$t('form.operations.new_affectation.placeholder')"
                            :can-add-item="canWriteAffectations"
                            :disabled="!canEditAffectations"
                            async-url="/affectations"
                            :model="affectations"
                            @select="onAffectationsSelected"
                            @new-item="onNewAffectationAdded">
          <template #trigger="slotProps">
            <div v-tooltip="{ content: slotProps.showDropdown ? false : tooltipAffectationsLabel, theme: 'tooltip', html: true }"
                 class="form-control"
                 :class="{ 'text-muted': !hasAffectation }">
              {{ affectationList }}
            </div>
          </template>
        </component-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { arrayInputLabel, arrayInputTooltip } from '@/helpers/utils/text'

import ComponentDropdown from '@/components/Dropdown.vue'

export default {
  components: {
    ComponentDropdown
  },

  props: {
    canEditAffectations: {
      type: Boolean,
      required: true
    },

    canWriteAffectations: {
      type: Boolean,
      required: true
    },

    isMissingField: {
      type: Boolean,
      default: false
    },

    affectations: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'affectations-selected',
    'new-affectation-added'
  ],

  data () {
    return {
    }
  },

  computed: {
    affectationLabel () {
      return this.$i18n.tc('general.affectation', this.affectations?.length || 0)
    },

    hasAffectation () {
      return this.affectations?.length
    },

    tooltipAffectationsLabel () {
      return arrayInputTooltip(this.affectations, 1)
    },

    affectationList () {
      return this.affectations.length
        ? arrayInputLabel(this.affectations, 1)
        : this.$i18n.t('form.common.select')
    }
  },

  methods: {
    onAffectationsSelected (affectations) {
      this.$emit('affectations-selected', affectations)
    },

    onNewAffectationAdded (affectation) {
      this.$emit('new-affectation-added', affectation)
    }
  }
}
</script>
