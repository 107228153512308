export interface IDocument {
  id: number
  url: string
  created_at: number
  updated_at: number
  name: string
  filename: string
  mime_type: string
  size: number
  file: File
}

export enum EDocumentStatus {
  Required = 'required',
  Optional = 'optional',
  Lost = 'lost',
  Available = 'available',
  Invalid = 'invalid'
}

export enum EDocumentActions {
  Required = EDocumentStatus.Required,
  Optional = EDocumentStatus.Optional,
  Lost = EDocumentStatus.Lost,
  Available = EDocumentStatus.Available,
  Invalid = EDocumentStatus.Invalid,
  Archived = 'archived',
  Pending = 'pending'
}
