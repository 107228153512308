<template>
  <div id="default-settings"
       data-cy="cards.settings.section.default-settings"
       class="form__fieldset">
    <group-title :title="t('cards_settings.default_settings.title')"
                 :subtitle="t('cards_settings.default_settings.subtitle')" />
    <div class="bordered-box form__fieldset__group p-2">
      <div class="form__row row">
        <div class="form-group col col--sm-6">
          <label class="label input__label">{{ t("form.category.label") }}</label>
          <component-dropdown :model="categories"
                              label="categories"
                              dropdown-menu-class="dropdown-menu"
                              multiselect
                              dropdown-width="100%"
                              search
                              data-cy="cards.settings.default-settings.categories"
                              :disabled="disabled"
                              :can-add-item="hasPermission(permissions.categoriesWrite)"
                              async-url="/categories"
                              :async-url-params="{ filter: 'card' }"
                              has-icon
                              @select="onCategorySelected"
                              @new-item="onNewCategoryAdded">
            <template #trigger="slotProps">
              <div class="dropdown-toggle select"
                   :class="categories.length ? 'is-clearable' : 'select--off'">
                <div v-tooltip="{ content: slotProps.showDropdown ? false : tooltipCategoriesLabel, theme: 'tooltip', html: true }"
                     class="form-control">
                  <span v-if="!categories.length"
                        class="text-placeholder">{{ t('form.common.select') }}</span>
                  <span v-else>{{ categoriesLabel }}</span>
                </div>
                <button v-if="categories.length"
                        class="form-control-clear-btn"
                        type="button"
                        @click.prevent.stop="resetCategories">
                  <ic-remove class="ic ic--16 ic--gray" />
                </button>
              </div>
            </template>
          </component-dropdown>
        </div>
        <div v-if="isAffectationsInputVisible"
             class="form-group col col--sm-6">
          <label class="label input__label">{{ t("form.affectation.label") }}</label>
          <component-dropdown :model="affectations"
                              async-url="/affectations"
                              :can-add-item="canWriteAffectations"
                              dropdown-width="100%"
                              multiselect
                              search
                              data-cy="cards.settings.default-settings.affectations"
                              dropdown-menu-class="dropdown-menu"
                              emiter="on-affectations-loaded"
                              label="affectations"
                              :disabled="disabled || !canEditAffectations"
                              @select="onAffectationSelected"
                              @new-item="onNewAffectationAdded">
            <template #trigger="slotProps">
              <div class="dropdown-toggle select"
                   :class="affectations.length ? 'is-clearable' : 'select--off'">
                <div v-tooltip="{ content: slotProps.showDropdown ? false : tooltipAffectationsLabel, theme: 'tooltip', html: true }"
                     class="form-control">
                  <span v-if="!affectations.length"
                        class="text-placeholder">{{ t('form.common.select') }}</span>
                  <span v-else>{{ affectationsLabel }}</span>
                </div>
                <button v-if="affectations.length"
                        class="form-control-clear-btn"
                        type="button"
                        @click.prevent.stop="resetAffectations">
                  <ic-remove class="ic ic--16 ic--gray" />
                </button>
              </div>
            </template>
          </component-dropdown>
        </div>
        <div class="form-group col col--sm-6">
          <label class="label input__label">{{ t('form.vat_rate.label') }}</label>
          <component-dropdown :model="vat"
                              data-cy="cards.settings.default-settings.vat"
                              label="vat-rate"
                              :values="vatRates"
                              dropdown-width="100%"
                              dropdown-menu-class="dropdown-menu"
                              :disabled="disabled"
                              @select="onVatSelected">
            <template #trigger>
              <div class="dropdown-toggle select"
                   :class="vat !== null ? 'is-clearable' : 'select--off'">
                <div class="form-control">
                  <span v-if="vat === null"
                        class="text-placeholder">{{ t('form.common.select') }}</span>
                  <span v-else>{{ vatLabel }}</span>
                </div>
                <button v-if="vat !== null"
                        class="form-control-clear-btn"
                        type="button"
                        @click.prevent.stop="resetVat">
                  <ic-remove class="ic ic--16 ic--gray" />
                </button>
              </div>
            </template>
          </component-dropdown>
        </div>
        <div class="form-group col col--sm-6"
             data-cy="cards.settings.default-settings.comment">
          <label class="label input__label">{{ t('form.personal_note.label') }}</label>
          <core-input v-model:model-value="comment"
                      clearable
                      :disabled="disabled"
                      :placeholder="t('form.personal_note.placeholder')"
                      name="comment"
                      @blur="updateDefaultSettings"
                      @clear="updateDefaultSettings" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CoreInput } from '@common/core-ui'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import permissions from '@/config/permissions'
import bus from '@/helpers/eventBus'
import hasPermission from '@/helpers/permissions'
import { arrayInputLabel, arrayInputTooltip } from '@/helpers/utils/text'
import { useAccountStore } from '@/stores/account'
import { useOperationStore } from '@/stores/operation'
import { IAffectation } from '@/types/affectation.d'
import { IDefaultSetting } from '@/types/card-setting.d'
import { IOperationCategory } from '@/types/category.d'
import { EEventAffectation, EEventCategory, EEventDropdown } from '@/types/event-bus.d'
import { IVatRate } from '@/types/vat.d'

import ComponentDropdown from '@/components/Dropdown.vue'
import GroupTitle from '@/components/GroupTitle.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

const { t } = useI18n()
const accountStore = useAccountStore()
const operationStore = useOperationStore()
const { account } = storeToRefs(accountStore)

const props = defineProps<{
  disabled: boolean,
  defaultSettings?: IDefaultSetting,
}>()

const emit = defineEmits([
  'update-card-settings'
])

const vatRates = ref<IVatRate[]>([])

const affectations = ref<IAffectation[]>([])
const categories = ref<IOperationCategory[]>([])
const comment = ref('')
const vat = ref<number | null>()

watch(() => props.defaultSettings, () => {
  initDefaultSettings()
})

const affectationsLabel = computed(() => {
  return arrayInputLabel(affectations.value, 3)
})

const tooltipAffectationsLabel = computed(() => {
  return arrayInputTooltip(affectations.value, 3)
})

const categoriesLabel = computed(() => {
  return arrayInputLabel(categories.value, 3)
})

const tooltipCategoriesLabel = computed(() => {
  return arrayInputTooltip(categories.value, 3)
})

const vatLabel = computed(() => {
  return vatRates.value.find(rate => rate.value === vat.value)?.label
})

const canReadAffectations = computed(() => {
  return hasPermission(permissions.operationsAffectationsRead)
})

const canEditAffectations = computed(() => {
  return canReadAffectations.value && hasPermission(permissions.operationsAffectationsWrite)
})

const canWriteAffectations = computed(() => {
  return canEditAffectations.value && hasPermission(permissions.affectationsWrite)
})

const hasAffectations = computed(() => {
  if (account.value) {
    return account.value.counts.affectations > 0
  }
  return false
})

const isAffectationsInputVisible = computed(() => {
  return canEditAffectations.value || (canReadAffectations.value && hasAffectations.value)
})

onMounted(async () => {
  initDefaultSettings()
  vatRates.value = await operationStore.getVATRates()

  bus.on(EEventAffectation.Delete, onDeleteAffectation)
  bus.on(EEventCategory.Delete, onDeleteCategory)
})

onUnmounted(() => {
  bus.off(EEventAffectation.Delete, onDeleteAffectation)
  bus.on(EEventCategory.Delete, onDeleteCategory)
})

function initDefaultSettings () {
  if (props.defaultSettings) {
    affectations.value = cloneDeep(props.defaultSettings.affectations)
    categories.value = cloneDeep(props.defaultSettings.categories)
    comment.value = cloneDeep(props.defaultSettings.comment)
    vat.value = cloneDeep(props.defaultSettings.vat)
  } else {
    categories.value = []
    affectations.value = []
    vat.value = null
    comment.value = ''
  }
}

function updateDefaultSettings () {
  emit('update-card-settings', {
    default_settings: {
      affectations: affectations.value,
      categories: categories.value,
      comment: comment.value,
      vat: vat.value
    }
  })
}

async function onNewAffectationAdded (itemLabel: string) {
  const newAffectation = await operationStore.addAffectation(itemLabel)
  if (newAffectation) {
    affectations.value.push(newAffectation)
    bus.emit(EEventDropdown.NewItemAdded, newAffectation)
    updateDefaultSettings()

    if (account.value) {
      account.value.counts.affectations += 1
    }
  }
}

async function onNewCategoryAdded (itemLabel: string) {
  const newCategory = await operationStore.addCategory(itemLabel)
  if (newCategory) {
    categories.value.push(newCategory)
    bus.emit(EEventDropdown.NewItemAdded, newCategory)
    updateDefaultSettings()
  }
}

function onDeleteAffectation (itemId: number) {
  if (account.value) {
    account.value.counts.affectations -= 1
  }

  const index = affectations.value.findIndex(item => item.id === itemId)
  if (index !== -1) {
    affectations.value.splice(index, 1)
  }
  updateDefaultSettings()
}

function onDeleteCategory (itemId: number) {
  const index = categories.value.findIndex(item => item.id === itemId)
  if (index !== -1) {
    categories.value.splice(index, 1)
  }
  updateDefaultSettings()
}

function onAffectationSelected (items: IAffectation[]) {
  affectations.value = items
  updateDefaultSettings()
}

function onCategorySelected (items: IOperationCategory[]) {
  categories.value = items
  updateDefaultSettings()
}

function onVatSelected (item: IVatRate) {
  vat.value = item.value
  updateDefaultSettings()
}

function resetAffectations () {
  affectations.value = []
  updateDefaultSettings()
}

function resetCategories () {
  categories.value = []
  updateDefaultSettings()
}

function resetVat () {
  vat.value = null
  updateDefaultSettings()
}
</script>
