import { EFilter } from '@/types/filter.d'

const config = {
  features: {
    login: {
      loginAnimations: {
        enabled: false
      },
      configureScaModal: {
        enabled: true
      }
    },

    app: {
      enabled: true
    },

    dashboard: {
      blocks: {
        owner: [
          'last_operations',
          'last_statements',
          'category_expenses'
        ],
        cardHolder: [
          'last_operations',
          'category_expenses',
          'category_expenses_chart'
        ]
      }
    },

    accountOverview: {
      enabled: true,
      contract: {
        enabled: true
      },
      estimated_interest_earned: {
        enabled: true
      },
      tax_type: {
        enabled: true
      },
      anniversary_date: {
        enabled: false
      }
    },

    operation: {
      enabled: true,

      incomingTransfer: {
        enabled: true
      },

      detail: {
        accountingDate: {
          enabled: false
        },
        valueDate: {
          enabled: false
        },
        settlementDate: {
          enabled: true
        },
        vat: {
          enabled: true,
          edition: {
            enabled: true
          }
        }
      }
    },

    team: {
      enabled: true
    },

    transfer: {
      enabled: false,
      batchValidation: {
        enabled: true
      },
      unique: {
        document: {
          enabled: true,
          upload: {
            enabled: true
          }
        },
        category: {
          enabled: true,
          edition: {
            enabled: true
          },
          detail: {
            enabled: true
          }
        },
        affectation: {
          enabled: true,
          edition: {
            enabled: true
          }
        },
        comment: {
          enabled: true,
          edition: {
            enabled: true
          }
        }
      },
      advancedPayment: {
        enabled: true
      },
      periodic: {
        enabled: true,
        upcomingTransfers: {
          enabled: true
        }
      },
      segment: {
        all: {
          table: {
            action: {
              enabled: true,
              cancellation: {
                enabled: true
              },
              edition: {
                enabled: true
              }
            }
          },
          detail: {
            initiator: {
              enabled: true
            },
            creationDate: {
              enabled: true,
              format: 'lll'
            },
            executionDate: {
              enabled: true,
              format: 'll'
            },
            expenseDate: {
              enabled: true,
              format: 'll',
              edition: {
                enabled: true
              }
            },
            approvalDetail: {
              enabled: true
            },
            reference: {
              enabled: false
            },
            certificateDownload: {
              enabled: true
            },
            action: {
              validation: {
                enabled: true
              },
              cancellation: {
                enabled: true
              }
            }
          }
        },
        periodic: {
          table: {
            action: {
              suspension: {
                enabled: true
              },
              cancellation: {
                enabled: true
              },
              edition: {
                enabled: true
              }
            }
          },
          detail: {
            initiator: {
              enabled: true
            },
            creationDate: {
              format: 'lll'
            },
            startDate: {
              format: 'll'
            },
            endDate: {
              format: 'll'
            },
            lastPastDeadlines: {
              enabled: true
            }
          }
        },
        salary: {
          enabled: true
        },
        cancelled: {
          enabled: true
        }
      },
      filter: [
        EFilter.Search,
        EFilter.DateRange,
        EFilter.Beneficiary,
        EFilter.IsSepa,
        EFilter.ExpenseCategory
      ],
      creation: {
        enabled: true,
        unique: {
          amount: {
            edition: {
              enabled: true
            }
          },
          label: {
            edition: {
              enabled: true
            }
          },
          delayed: {
            enabled: true
          },
          periodic: {
            periods: [
              'weekly',
              'monthly',
              'quarterly'
            ]
          },
          international: {
            modeFees: {
              enabled: true
            }
          }
        },

        multiple: {
          sepa: {
            enabled: true
          },
          payslips: {
            enabled: true
          }
        }
      }
    },

    beneficiary: {
      enabled: true,
      international: {
        enabled: true
      }
    },

    request: {
      enabled: false
    },

    debit: {
      enabled: false
    },

    card: {
      physical: {
        enabled: true,
        smart_card: {
          enabled: true
        },
        edition: {
          enabled: true
        },
        activation: {
          enabled: true
        },
        detail: {
          withdrawal: {
            alwaysVisible: {
              enabled: false
            }
          }
        }
      },
      virtual: {
        enabled: true,
        edition: {
          enabled: true
        },
        cancelAutomaticDeletion: {
          enabled: true
        },
        request: {
          enabled: true
        },
        settings: {
          edition: {
            enabled: true
          },
          read: {
            enabled: true
          }
        }
      },
      create: {
        multiple: {
          enabled: true
        },
        customPin: {
          enabled: false
        }
      },
      block: {
        enabled: true
      },
      lock: {
        enabled: true
      },
      pin: {
        read: {
          enabled: true
        },
        edition: {
          enabled: true
        },
        mailer: {
          enabled: true
        }
      },
      requestIncrease: {
        enabled: true
      },
      webWallet: {
        enabled: true
      },
      automatic_reset_initial_amount: {
        enabled: false
      },
      controlRule: {
        enabled: true
      },
      defaultSettings: {
        enabled: true
      }
    },

    credit: {
      enabled: false,
      document: {
        enabled: true
      }
    },

    refund: {
      enabled: false
    },

    mileage: {
      enabled: false
    },

    statement: {
      enabled: true
    },

    accounting: {
      enabled: true
    },

    treasury: {
      enabled: false
    },

    loyalty: {
      enabled: true
    },

    user: {
      enabled: true,
      identity_proof_required: true
    },

    invitation: {
      multiple: {
        enabled: true
      },

      mandate: {
        enabled: true
      }
    },

    inbox: {
      enabled: true
    },

    footer: {
      contact: {
        enabled: true
      },
      cookies: {
        enabled: true
      },
      legal: {
        enabled: true
      },
      terms: {
        enabled: true
      },
      pricing: {
        enabled: true
      }
    },

    account: {
      sponsorship: {
        enabled: false
      },

      rib: {
        enabled: false
      },

      company: {
        enabled: false,
        creation: {
          enabled: false
        },

        account: {
          closing: {
            enabled: true
          }
        }
      },

      checkRemittanceSlip: {
        enabled: true
      },

      service: {
        enabled: false
      },

      sca: {
        methods: ['sms', 'app', 'email']
      }
    },

    signup: {
      linkOnLogin: {
        enabled: false
      },

      companyCreation: {
        enabled: false
      }
    }
  },

  COOKIES: {
    CookiesPolicyType: 'link'
  },

  SVG: {
    COLOR: {
      PRIMARY: import.meta.env.VITE_CONFIG_SVG_COLOR_PRIMARY || '#fc366e',
      PRIMARY_VIVID: import.meta.env.VITE_CONFIG_SVG_COLOR_PRIMARY_VIVID || '#fb044a',
      GREY: import.meta.env.VITE_CONFIG_SVG_COLOR_GREY || '#bcc0c5',
      WHITE: import.meta.env.VITE_CONFIG_SVG_COLOR_WHITE || '#ffffff',
      DARK: import.meta.env.VITE_CONFIG_SVG_COLOR_DARK || '#424853',
      BORDER_COLOR: import.meta.env.VITE_CONFIG_SVG_COLOR_BORDER || '#E4E6E7',
      GREEN: import.meta.env.VITE_CONFIG_SVG_COLOR_GREEN || '#0ccc7d',
      RED: import.meta.env.VITE_CONFIG_SVG_COLOR_RED || '#ff3e3e'
    }
  },

  CHART: {
    COLOR: {
      PRIMARY: import.meta.env.VITE_CONFIG_CHART_COLOR_PRIMARY_VIVID || '#FF2361',
      GREEN_400: import.meta.env.VITE_CONFIG_CHART_COLOR_GREEN_400 || '#47E7A8',
      GREEN_300: import.meta.env.VITE_CONFIG_CHART_COLOR_GREEN_300 || '#47E7A8',
      RED_300: import.meta.env.VITE_CONFIG_CHART_COLOR_RED_300 || '#FF8981',
      RED_200: import.meta.env.VITE_CONFIG_CHART_COLOR_RED_200 || '#FF8981',
      GREEN: import.meta.env.VITE_CONFIG_CHART_COLOR_GREEN || '#0ccc7d',
      RED: import.meta.env.VITE_CONFIG_CHART_COLOR_RED || '#ff3e3e'
    }
  },

  LOTTIE: {
    MAIN_COLOR: import.meta.env.VITE_CONFIG_LOTTIE_COLOR || ''
  },

  TRANSLATIONS: {
    AVAILABLE_LANGUAGES: ['fr', 'en']
  },

  isCookiePolicyIframeType: function () {
    return this.COOKIES.CookiesPolicyType === 'iframe'
  },

  isCookiePolicyLinkType: function () {
    return this.COOKIES.CookiesPolicyType === 'link'
  }
}

export default config
export const features = config.features
