<template>
  <sidepanel-block :title="$t('general.vat')"
                   :warning="isVatHigherThanAmount"
                   :warning-text="$t('tooltip.invalid_vat_amount')"
                   :is-missing-field="isMissingField">
    <template #header>
      <button v-if="!allRatesSelected && editable"
              class="sidepanel-block-header-button"
              type="button"
              @click="addVat">
        <span>{{ $t('sidepanel.accounting_category.vat.add') }}</span>
        <core-icon :name="ECoreIconList.Plus"
                   :size="18"
                   :theme="ECoreIconTheme.GrayLow" />
      </button>
    </template>
    <template #body>
      <field-array name="vat">
        <div v-for="(vatItem, index) in vat"
             :key="index"
             class="sidepanel-details__entry vat">
          <validation-field v-slot="{ errors }"
                            :name="`vat[${index}].rate`"
                            class="vat__rate"
                            :model-value="vatItem.rate">
            <component-dropdown label="vat-rate"
                                dropdown-width="12"
                                dropdown-position="right"
                                can-reset-value
                                :values="rates"
                                :model="vatItem.rate"
                                :disabled="!editable"
                                @reset-value="onVatRateSelected(index, null)"
                                @select="(item) => onVatRateSelected(index, item.value)">
              <template #trigger>
                <div class="select form-control form-control--sm vat__rate"
                     :class="{ error: formSubmitted && errors.length, 'text-placeholder': vatItem.rate === null && !(formSubmitted && errors.length) }">
                  {{ vatRateLabel(vatItem) }}
                </div>
              </template>
            </component-dropdown>
          </validation-field>
          <div class="input-group vat__amount">
            <span v-if="invalidTotalWarning(index)"
                  v-tooltip="{ content: $t('tooltip.invalid_total'), theme: 'tooltip' }"
                  class="input-group__addon">
              <ic-warning outline
                          class="ic ic--warning ic--16" />
            </span>
            <number-input id="vat-amount"
                          :model-value="vatItem.amount"
                          decimal
                          :default-zero="false"
                          :disabled="!editable"
                          :display-error="formSubmitted"
                          :minimum-fraction-digits="2"
                          :placeholder="$t('form.vat_amount.placeholder')"
                          class="form-control form-control--sm"
                          :name="`vat[${index}].amount`"
                          @blur="value => onAmountUpdate(value, index)" />
            <span v-if="vatItem.amount !== null"
                  class="input-group__addon">{{ getCurrencySymbol(account.currency) }}</span>
          </div>
          <button v-if="canRemoveVat(index)"
                  type="button"
                  @click="removeVat(index)">
            <core-icon :name="ECoreIconList.Delete"
                       :size="16"
                       :theme="ECoreIconTheme.GrayLower" />
          </button>
        </div>
      </field-array>
    </template>
  </sidepanel-block>
</template>

<script>
import { CoreIcon, ECoreIconList, ECoreIconTheme } from '@common/core-ui'
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'
import { FieldArray } from 'vee-validate'

import { getCurrencySymbol } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'

import Dropdown from '@/components/Dropdown.vue'
import NumberInput from '@/components/NumberInput.vue'
import SidepanelBlock from '@/components/SidepanelBlock.vue'
import IcWarning from '@/components/svg/icons/ic-warning.vue'

export default {
  components: {
    IcWarning,
    FieldArray,
    ComponentDropdown: Dropdown,
    CoreIcon,
    NumberInput,
    SidepanelBlock
  },

  props: {
    editable: {
      type: Boolean,
      required: true
    },

    formSubmitted: {
      type: Boolean,
      required: true
    },

    initialVat: {
      type: [Array, null],
      required: true
    },

    isMissingField: {
      type: Boolean,
      required: true
    },

    operationAmount: {
      type: Number,
      required: true
    },

    vatRates: {
      type: Array,
      required: true
    }
  },

  emits: [
    'update:initial-vat'
  ],

  setup () {
    const accountStore = useAccountStore()
    const { account } = storeToRefs(accountStore)

    return {
      account
    }
  },

  data: () => {
    return {
      vat: [{ rate: null, amount: null }]
    }
  },

  computed: {
    allRatesSelected () {
      return this.vatRates.every(rate => this.vat.find(item => item.rate === rate.value))
    },

    isMultiVat () {
      return this.vat.length > 1
    },

    vatRateSum () {
      const vatWithRate = this.vat.filter(vatItem => vatItem.rate !== -1 && vatItem.rate !== null && vatItem.amount !== null)
      if (!vatWithRate.length) {
        return 0
      }

      return vatWithRate.reduce((accumulator, vatItem) => accumulator + vatItem.amount, 0)
    },

    isVatHigherThanAmount () {
      const total = this.vat.find(vatItem => vatItem.rate === -1)
      if (total && total.amount > this.operationAmount) {
        return true
      }

      return this.vatRateSum > this.operationAmount
    },

    ECoreIconTheme () {
      return ECoreIconTheme
    },

    ECoreIconList () {
      return ECoreIconList
    },

    rates () {
      return this.vatRates.map(item => {
        return { ...item, isDisabled: this.vat.find(vatItem => (vatItem.rate === item.value)) }
      })
    }
  },

  watch: {
    initialVat: function () {
      this.formatVatArray()
    }
  },

  mounted () {
    this.formatVatArray()
  },

  methods: {
    getCurrencySymbol,

    addVat () {
      this.vat.push({ rate: null, amount: null })
      this.updateInitialValue()
    },

    autoCompleteRate () {
      if (!this.isMultiVat) {
        if (this.vat[0].rate !== null) {
          return
        }

        const amount = parseFloat(this.vat[0].amount)
        if (isNaN(amount) || amount === -1) {
          this.vat[0].rate = null
          return
        }

        const searchRate = this.vatRates.findIndex(item => this.getVatAmount(item.value).toFixed(2) === amount.toFixed(2))
        this.vat[0].rate = searchRate === -1 ? -1 : this.vatRates[searchRate].value
      }
    },

    autoCompleteAmount () {
      if (!this.isMultiVat) {
        if (this.vat[0].rate !== -1 && this.vat[0].rate !== null) {
          this.vat[0].amount = this.getVatAmount(this.vat[0].rate)
        }
      }
    },

    autoCompleteZero () {
      this.vat = this.vat.map(item => {
        if (item.rate === 0) {
          return { ...item, amount: 0 }
        } else if (item.amount === 0) {
          return { ...item, rate: 0 }
        } else {
          return item
        }
      })
    },

    canRemoveVat (index) {
      if (!this.editable) {
        return false
      }

      if (this.isMultiVat) {
        return true
      }

      return this.vat[index].rate !== null || this.vat[index].amount !== null
    },

    formatVatArray () {
      if (this.initialVat?.length) {
        this.vat = cloneDeep(this.initialVat)
        this.vat = this.vat.map(vat => {
          return { ...vat, rate: vat.rate === null ? -1 : vat.rate }
        })
      } else {
        this.vat = [{ rate: null, amount: null }]
      }
    },

    getVatAmount (rate) {
      if (rate <= 0) {
        return 0
      }

      return parseFloat((this.operationAmount - (this.operationAmount / (1 + (rate / 100)))).toFixed(2))
    },

    invalidTotalWarning (index) {
      if (this.vat[index].rate !== -1) {
        return false
      }

      if (this.vat[index].amount === null) {
        return false
      }

      return this.vat[index].amount < this.vatRateSum
    },

    onAmountUpdate (value, index) {
      this.vat[index].amount = value
      this.autoCompleteRate()
      this.autoCompleteZero()
      this.updateInitialValue()
    },

    onVatRateSelected (index, value) {
      this.vat[index].rate = value

      this.autoCompleteAmount()
      this.autoCompleteZero()
      this.updateInitialValue()
    },

    removeVat (index) {
      if (!this.isMultiVat) {
        this.vat[0].amount = null
        this.vat[0].rate = null
      } else {
        this.vat.splice(index, 1)
        if (this.vat[0].amount === null) {
          this.autoCompleteAmount()
        }
      }

      this.updateInitialValue()
    },

    updateInitialValue () {
      const value = this.vat
        .map(item => {
          return {
            amount: item.amount === '' ? null : item.amount,
            rate: item.rate === -1 ? null : item.rate
          }
        })
        .filter(item => item.amount !== null || item.rate !== null)

      if (!value.length) {
        return this.$emit('update:initial-vat', null)
      }

      return this.$emit('update:initial-vat', value)
    },

    vatRateLabel (vat) {
      if (vat.rate === null) {
        return this.$i18n.t('form.vat_rate.placeholder')
      }

      return this.vatRates.find(item => item.value === vat.rate).label
    }
  }
}
</script>

<style lang="stylus" scoped>
.vat
  gap 1rem
  margin-bottom 1rem

  &:last-child
    margin-bottom 0

  &__rate
    width 12rem

  &__amount
    width 100%

  button
    display flex
    align-items center
    justify-content center
</style>
