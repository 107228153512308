<template>
  <div class="dashboard__block dashboard__block--main">
    <div class="top-left-label bank-account-label">
      <button class="btn-link btn-menu"
              @click="onToggleSidebar">
        <ic-menu class="ic ic--24 ic--white" />
      </button>

      <div v-if="isAccountNumberVisible">
        {{ $t("label.account_number") }}{{ account.rib?.number }}
      </div>

      <router-link v-else
                   :to="{ name: 'account' }"
                   class="user">
        <user-avatar :key="`${user.picture?.url}-${user.picture?.updated_at}`"
                     class="user__picture"
                     :size="42"
                     :picture="user.picture"
                     :placeholder-label="userFullName" />
        <div class="user-name">
          {{ userFullName }}
        </div>
      </router-link>
    </div>
    <span v-if="isRibVisible"
          class="top-right-label">
      <component-popover trigger="click"
                         data-cy="dashboard.menu-dropdown.popover"
                         popover-class="popover-actions-menu popover-actions-menu--dark"
                         :arrow="false">
        <template #trigger>
          <div class="dropdown">
            <button class="btn-link btn-dl-rib dropdown-toggle dropdown-toggle--white">
              <span class="dropdown-toggle-value">{{ $t("button.rib") }}</span>
            </button>
          </div>
        </template>
        <a href="#"
           class="popover__item pr-2"
           @click.prevent="downloadFileFromUrl(account.rib.pdf_url)">
          <ic-download-sm class="popover__item__icon ic ic--16 ic--white" />
          {{ $t("button.download") }}
        </a>
        <a id="iban-copy"
           href="#"
           class="popover__item pr-2"
           :data-clipboard-text="formatIban(account.rib.iban)">
          <ic-clipboard class="popover__item__icon ic ic--16 ic--white" />
          {{ $t("button.copy_iban") }}
        </a>
        <a href="#"
           class="popover__item pr-2"
           @click.prevent="onSendEmail">
          <ic-mail-sm class="popover__item__icon ic ic--16 ic--white" />
          {{ $t("button.email_send") }}
        </a>
        <template v-if="isPermissionGranted">
          <hr class="divider">
          <router-link class="popover__item pr-2"
                       data-cy="dashboard.menu-dropdown.button"
                       :to="{ name: 'create-sub-account' }">
            <ic-plus-sm class="popover__item__icon ic ic--16 ic--white" />
            {{ $t('button.create_bank_account') }}
          </router-link>
        </template>
      </component-popover>
    </span>
    <div class="dashboard-main-content">
      <loader-spinner v-if="loading"
                      color="white" />

      <template v-else-if="isCardOwnerDashboardVisible">
        <h2 class="number-label">
          <span class="number-label__int">{{ monthExpense[0] }}</span>
          <span class="number-label__dec">{{ monthExpense[1] }}</span>
        </h2>
        <span class="dashboard-main-content__bottom">{{ $t('label.spent_on') }} {{ currentMonth }}</span>
        <span v-if="account.settlement_date"
              class="dashboard-main-content__badge">{{ $t('label.balance_debited_on') }} {{ settlementDate }}</span>
      </template>

      <template v-else-if="isDeferredDashboardVisible">
        <span v-if="currentMonth"
              class="dashboard-main-content__top">{{ $t('label.total_outstandings') }} {{ currentMonth }}</span>
        <div class="dashboard-main-content__gauge">
          <amount-gauge white
                        :percentage="expenseGaugePercentage" />
          <div class="amount-gauge-text">
            <h2 class="number-label">
              <span class="number-label__int">{{ monthExpense[0] }}</span>
              <span class="number-label__dec">{{ monthExpense[1] }}</span>
            </h2>
            <div>
              <span class="dashboard-main-content__bottom">{{ $tc('label.expenses_limit', transactionConsumed, { amount: monthLimit }) }}</span>
              <span v-if="hasTemporaryLimit"> *</span>
            </div>
          </div>
        </div>
        <span v-if="account.settlement_date || account"
              class="dashboard-main-content__badge">{{ $t('label.balance_debited_on') }} {{ settlementDate }}</span>
        <span v-if="hasTemporaryLimit"
              class="dashboard-main-content__asterisk">{{ $t('label.temporary_limit_availability', { date: temporaryLimitEndDate }) }}</span>
      </template>

      <template v-else>
        <h2 class="number-label">
          <span class="number-label__int">{{ balance[0] }}</span>
          <span class="number-label__dec">{{ balance[1] }}</span>
        </h2>
        <span v-if="dashboard.account_updated_at"
              class="sub-label">
          {{ $t("label.balance") }} {{ formatDate(dashboard.account_updated_at) }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { storeToRefs } from 'pinia'

import { EFeature, hasFeature } from '@/config/features'
import permissions from '@/config/permissions'
import hasPermission from '@/helpers/permissions'
import { downloadFileFromUrl } from '@/helpers/utils'
import { formatDate, formatDateText } from '@/helpers/utils/date'
import { formatIban } from '@/helpers/utils/iban'
import { showToastSuccess } from '@/helpers/utils/notification'
import { formatAmount, formatPartAmount } from '@/helpers/utils/number'
import { useAccountStore } from '@/stores/account'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

import AmountGauge from '@/pages/cards/details/components/AmountGauge.vue'

import LoaderSpinner from '@/components/LoaderSpinner.vue'
import SendEmail from '@/components/modals/SendEmail.vue'
import ComponentPopover from '@/components/Popover.vue'
import IcClipboard from '@/components/svg/icons/ic-clipboard.vue'
import IcDownloadSm from '@/components/svg/icons/ic-download-sm.vue'
import IcMailSm from '@/components/svg/icons/ic-mail-sm.vue'
import IcMenu from '@/components/svg/icons/ic-menu.vue'
import IcPlusSm from '@/components/svg/icons/ic-plus-sm.vue'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    LoaderSpinner,
    AmountGauge,
    UserAvatar,
    ComponentPopover,
    IcPlusSm,
    IcDownloadSm,
    IcMailSm,
    IcMenu,
    IcClipboard
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    dashboard: {
      type: Object,
      required: true
    }
  },

  setup () {
    const accountStore = useAccountStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()

    const { account, isDeferredAccount } = storeToRefs(accountStore)
    const { user, userFullName } = storeToRefs(authStore)

    return { account, accountStore, appStore, user, userFullName, isDeferredAccount }
  },

  data () {
    return {
      clipboard: null
    }
  },

  computed: {
    isAccountNumberVisible () {
      return hasFeature(EFeature.DashboardCountsBlock) && this.account.rib?.number
    },

    isRibVisible () {
      return hasFeature(EFeature.AccountRIB)
    },

    isPermissionGranted () {
      return hasFeature(EFeature.AccountCompany) && this.accountStore.hasAnyCompanyPermission(this.account.company)
    },

    isCardOwnerDashboardVisible () {
      return !hasPermission(permissions.dashboardRead)
    },

    isDeferredDashboardVisible () {
      return this.isDeferredAccount
    },

    transactionConsumed () {
      if (this.hasTemporaryLimit) {
        return this.dashboard.transaction_amount_monthly_limit.transaction_amount_temporary.consumed
      }
      return this.dashboard.transaction_amount_monthly_limit?.consumed
    },

    transactionLimit () {
      if (this.hasTemporaryLimit) {
        return this.dashboard.transaction_amount_monthly_limit.transaction_amount_temporary.limit
      }
      return this.dashboard.transaction_amount_monthly_limit?.limit
    },

    expenseGaugePercentage () {
      return this.transactionConsumed / this.transactionLimit * 100
    },

    monthExpense () {
      return formatPartAmount(this.transactionConsumed || 0)
    },

    monthLimit () {
      return formatAmount(this.transactionLimit || 0, this.account.currency, { trailingZeroDisplay: 'stripIfInteger' })
    },

    currentMonth () {
      return formatDateText(this.dashboard.account_updated_at, 'MMM YYYY')
    },

    settlementDate () {
      return formatDateText(this.account.settlement_date, 'DD MMMM')
    },

    balance () {
      return formatPartAmount(this.account.balance)
    },

    hasTemporaryLimit () {
      return this.dashboard.transaction_amount_monthly_limit.transaction_amount_temporary
    },

    temporaryLimitEndDate () {
      return formatDateText(this.dashboard.transaction_amount_monthly_limit.transaction_amount_temporary.end_date, 'll')
    }
  },

  mounted () {
    this.clipboard = new Clipboard('#iban-copy')
    this.clipboard.on('success', this.onCopySuccess)
  },

  beforeUnmount () {
    this.clipboard.off('success', this.onCopySuccess)
  },

  methods: {
    downloadFileFromUrl,

    formatIban,

    formatAmount,

    formatPartAmount,

    formatDate,

    onCopySuccess () {
      showToastSuccess(this.$i18n.t('message.success.copy_iban'))
    },

    onToggleSidebar () {
      document.getElementsByClassName('sidebar')[0].classList.toggle('is-open')
    },

    onSendEmail () {
      this.appStore.showModal(SendEmail, { callback: this.accountStore.sendRibByEmail }, { wrapperClass: 'modal--top modal--xs' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.dashboard__block--main
  background-image linear-gradient(220deg, #990C64 10%, #F60D3E 80%)
  background-size cover
  color white

  .btn-menu
    margin-right 1.6rem
    vertical-align 1px
    +min-screen($xl-screen)
      display none

  .bank-account-label
  .btn-dl-rib
    color white
    font-weight bold
    text-transform uppercase

  .bank-account-label
    display flex

  .top-left-label
  .top-right-label
    position absolute
    top $dashboard-block-padding-top

  .top-right-label
    right $dashboard-block-padding-right

  .top-left-label
    left $dashboard-block-padding-right

  .user
    display flex
    align-items center
    color $colorWhite
    &-name
      margin-left 1.2rem
    &-avatar
      margin-top -2rem
      transform translateY(1rem)

  .dashboard-main-content
    display flex
    flex-direction column
    width 100%
    margin auto
    text-align center

    &__gauge
      position relative

      &:deep(.amount-gauge)
        width 32rem

      .amount-gauge-text
        position absolute
        bottom 0
        width 100%

        .number-label
          font-size 4rem
          margin-bottom 1.2rem

    &__top
      margin 3rem 0 1.2rem

    &__bottom
      font-size 1.5rem

    &__badge
      background-color rgba($colorWhite, 0.2)
      font-size 1.3rem
      font-weight 600
      opacity 0.8
      width max-content
      margin 4rem auto 0
      padding 8px 16px
      border-radius 24px

    &__asterisk
      margin-top 3.3rem
      font-size 1.2rem
      color rgba($colorWhite, 0.7)

</style>
