<template>
  <div class="form">
    <fieldset v-if="!hideUserSelect"
              class="form__fieldset">
      <fieldset-title :title="$t('form.title.select_user')"
                      show-number />
      <div class="form__fieldset__group">
        <div class="form__row row row--sm-bottom">
          <div class="form-group col col--sm-7">
            <component-dropdown v-if="!userSelected && !isNewUser"
                                id="dropdown-select-user"
                                label="user"
                                dropdown-width="100%"
                                :dropdown-height="22"
                                dropdown-position="right"
                                search
                                async-url="/users"
                                :async-url-params="{ status: 'active', exclude: 'me', scenario: 'light' }"
                                :label-filter="userFilter"
                                has-item-picture
                                :model="null"
                                data-cy="cards.create.form.employee"
                                @select="selectUser">
              <template #trigger>
                <div class="dropdown-toggle select">
                  <div class="form-control form-control--noborder">
                    <span class="text-placeholder">{{ $t('form.common.select') }}</span>
                  </div>
                </div>
              </template>
            </component-dropdown>
            <div v-else
                 class="input-group">
              <div class="form-control form-control--noborder input__field">
                <template v-if="isNewUser">
                  <span>{{ $t('prompt.cards.order.choice.new_user') }}</span>
                </template>
                <template v-else>
                  <span>{{ `${userSelected.first_name} ${userSelected.last_name} ` }}
                    <template v-if="userSelected.owner">
                      ({{ $t('general.you') }})
                    </template>
                    <template v-if="userSelected.role">
                      - <span class="text-placeholder">{{ userSelected.role_label }}</span>
                    </template>
                  </span>
                </template>
              </div>
              <a href="#"
                 class="input-group__addon"
                 @click.prevent.stop="resetUser">
                <ic-close class="ic--14 ic--gray" />
              </a>
            </div>
          </div>
          <div class="form-group col col--sm-5">
            <button class="btn btn--default pull-right"
                    @click.prevent.stop="selectUser(null, true)">
              <i class="btn-icon btn-icon--left">
                <ic-plus class="ic ic-plus ic--white" />
              </i>
              {{ $t('button.new_user') }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
    <template v-if="userSelected || isNewUser || hideUserSelect">
      <fieldset class="form__fieldset">
        <fieldset-title :title="$t('form.title.personal_informations')"
                        show-number />
        <div class="form__fieldset__group">
          <div class="form__row row row--sm-bottom">
            <div class="form-group col col--sm-6"
                 :class="{ error: errors.first_name }">
              <label class="label input__label"
                     for="first_name">{{ $t('form.first_name.label') }}</label>
              <lock-input id="first_name"
                          v-model="cardForm.first_name"
                          type="text"
                          input-class="form-control form-control--noborder"
                          :placeholder="$t('form.first_name.placeholder')"
                          name="first_name"
                          :disabled="userSelected || isUserOwner" />
            </div>
            <div class="form-group col col--sm-6"
                 :class="{ error: errors.last_name }">
              <label class="label input__label"
                     for="last_name">{{ $t('form.last_name.label') }}</label>
              <lock-input id="last_name"
                          v-model="cardForm.last_name"
                          type="text"
                          input-class="form-control form-control--noborder"
                          :placeholder="$t('form.last_name.placeholder')"
                          name="last_name"
                          :disabled="userSelected || isUserOwner" />
            </div>
            <div v-if="!isUserOwner && !selectedUserIsOwner"
                 :class="{ error: errors.email }"
                 class="form-group col col--sm-6">
              <label class="label input__label">{{ $t('form.email.label') }}</label>
              <lock-input id="email"
                          v-model="cardForm.invitation.email"
                          type="text"
                          input-class="form-control form-control--noborder"
                          :placeholder="$t('form.email.placeholder')"
                          name="email"
                          :disabled="userSelected" />
            </div>
            <div v-if="isNewUser"
                 :class="{ error: errors.phone }"
                 class="form-group col col--sm-6">
              <label class="label input__label"
                     for="phone">{{ $t('form.mobile_phone.label') }}</label>
              <phone-input id="phone"
                           v-model="cardForm.phone"
                           :disabled="!canEditPhone || isUserOwner || selectedUserIsOwner"
                           name="phone"
                           wrapper-class="input-group--noborder">
                <template v-if="!canEditPhone || isUserOwner || selectedUserIsOwner"
                          #addon>
                  <span class="input-group__addon">
                    <ic-cadenas-sm class="ic ic--16 ic--off" />
                  </span>
                </template>
              </phone-input>
            </div>
          </div>
          <user-language v-if="isNewUser && hasMultipleLanguages"
                         :model="cardForm.invitation.language"
                         @update-model="updateCardInvitation" />
        </div>
      </fieldset>
      <user-team v-if="isNewUser"
                 :errors="errors"
                 :model="userTeam"
                 @update-model="updateCardInvitation" />
      <fieldset v-if="!isUserOwner && !selectedUserIsOwner && isNewUser"
                class="form__fieldset">
        <fieldset-title :title="`${$t('form.title.professional_informations')} (${$t('general.optional')})`"
                        show-number />
        <div class="form__fieldset__group">
          <div class="form__row row row--sm-bottom">
            <div class="form-group col col--sm-4"
                 :class="{ error: errors.role }">
              <label class="label input__label"
                     for="role">{{ $t('form.role.label') }}</label>
              <div class="input-group">
                <div v-if="!isRoleOther"
                     class="select">
                  <component-dropdown id="role"
                                      dropdown-width="100%"
                                      name="card.invitation.role"
                                      :model="cardForm.invitation.role"
                                      :values="rolesOptions"
                                      @select="selectRole">
                    <template #trigger>
                      <div class="form-control form-control--noborder">
                        <span v-if="!cardForm.invitation.role"
                              class="text-placeholder">{{ $t('form.roles.placeholder.default') }}</span>
                        <span v-else>{{ getRoleLabel(cardForm.invitation.role) }}</span>
                      </div>
                    </template>
                  </component-dropdown>
                </div>
                <template v-else>
                  <input v-model="cardForm.invitation.role_label"
                         class="form-control form-control--noborder input__field"
                         type="text"
                         :placeholder="$t('form.roles.placeholder.other')"
                         @input="onRoleLabelInput">
                  <a href="#"
                     class="input-group__addon"
                     @click.prevent.stop="resetInvitationRole">
                    <ic-remove class="ic--16 ic--gray" />
                  </a>
                </template>
              </div>
            </div>
            <div class="form-group col col--sm-4">
              <label class="label input__label"
                     for="employee_number">{{ $t('form.registration_number.label') }}</label>
              <div class="input-group">
                <input id="employee_number"
                       v-model="cardForm.invitation.employee_number"
                       type="text"
                       class="form-control form-control--noborder"
                       :placeholder="$t('form.registration_number.placeholder')"
                       name="employee_number">
              </div>
            </div>
            <div class="form-group col col--sm-4"
                 :class="{ error: errors.affectations }">
              <label class="label input__label">
                {{ $t("form.affectation.label") }}
                <span v-tooltip="{
                        content: account.settings.map_access_affectations_to_operations ? $t('tooltip.affectation_mapped') : $t('tooltip.affectation_not_mapped'),
                        theme: 'tooltip'
                      }"
                      class="ic-helper small vertical-align-bottom">
                  <ic-info outline
                           class="ic" />
                </span>

              </label>
              <component-dropdown label="affectations"
                                  dropdown-menu-class="dropdown-menu--noborder"
                                  multiselect
                                  dropdown-width="100%"
                                  search
                                  :can-add-item="canWriteAffectations"
                                  async-url="/affectations"
                                  :model="cardForm.invitation.affectations"
                                  @select="onAffectationsSelected"
                                  @new-item="onNewAffectationAdded">
                <template #trigger>
                  <div class="dropdown-toggle select">
                    <div class="form-control form-control--noborder">
                      <span v-if="!cardForm.invitation.affectations.length"
                            class="text-placeholder">{{ $t('form.common.select') }}</span>
                      <span v-else>{{ affectationsLabel }}</span>
                    </div>
                  </div>
                </template>
              </component-dropdown>
            </div>
          </div>
        </div>
      </fieldset>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeToRefs } from 'pinia'

import config from '@/config/config'
import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'
import { useOperationStore } from '@/stores/operation'
import { useUserStore } from '@/stores/user'

import UserLanguage from '@/pages/users/components/form/UserLanguage.vue'
import UserTeam from '@/pages/users/components/form/UserTeam.vue'

import ComponentDropdown from '@/components/Dropdown.vue'
import FieldsetTitle from '@/components/FieldsetTitle.vue'
import LockInput from '@/components/LockInput.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import IcCadenasSm from '@/components/svg/icons/ic-cadenas-sm.vue'
import IcClose from '@/components/svg/icons/ic-close.vue'
import IcInfo from '@/components/svg/icons/ic-info.vue'
import IcPlus from '@/components/svg/icons/ic-plus.vue'
import IcRemove from '@/components/svg/icons/ic-remove.vue'

export default {
  components: {
    UserLanguage,
    UserTeam,
    IcClose,
    IcCadenasSm,
    IcInfo,
    IcRemove,
    IcPlus,
    ComponentDropdown,
    LockInput,
    PhoneInput,
    FieldsetTitle
  },

  props: {
    card: {
      type: Object,
      required: true
    },

    hideUserSelect: {
      type: Boolean,
      default: false
    },

    isImportedUser: {
      type: Boolean,
      default: false
    },

    isUserOwner: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object,
      required: true
    }
  },

  emits: ['is-new-user', 'update:card', 'user-selected', 'disable-submit'],

  setup () {
    const accountStore = useAccountStore()
    const operationStore = useOperationStore()
    const authStore = useAuthStore()
    const userStore = useUserStore()

    const { operationAffectations } = storeToRefs(operationStore)
    const { user } = storeToRefs(authStore)
    const { account } = storeToRefs(accountStore)

    return {
      account,
      accountStore,
      operationAffectations,
      operationStore,
      user,
      userStore
    }
  },

  data () {
    return {
      editShippingAddress: false,
      accept: false,
      userSelectorIsOpen: false,
      userInput: '',
      isNewUser: cloneDeep(this.isImportedUser),
      userSelected: null,
      cardForm: cloneDeep(this.card),
      roles: [],

      roleOther: this.$i18n.t('form.roles.other'),
      canEditPhone: true
    }
  },

  computed: {
    canReadAffectations () {
      return this.hasPermission(this.$permissions.usersAffectationsRead)
    },

    canEditAffectations () {
      return this.canReadAffectations && this.hasPermission(this.$permissions.usersAffectationsWrite)
    },

    canWriteAffectations () {
      return this.canEditAffectations && this.hasPermission(this.$permissions.affectationsWrite)
    },

    isOwner () {
      return this.hasPermission(this.$permissions.accountOwner)
    },

    selectedUserIsOwner () {
      return this.userSelected?.owner
    },

    hasUserSelected () {
      return this.userSelected || this.isNewUser
    },

    isRoleOther () {
      return this.cardForm.invitation.role === 'other'
    },

    affectationsLabel () {
      if (!this.cardForm.invitation.affectations.length) { return '' }

      return this.cardForm.invitation.affectations.map(affectation => affectation.label).join(', ')
    },

    userTeam () {
      return {
        team: {
          id: this.cardForm.invitation?.team_id,
          name: this.cardForm.invitation?.team_name
        },

        teamRole: this.cardForm.invitation?.team_role
      }
    },

    rolesOptions () {
      const options = this.roles.map(role => {
        return { value: role.role, label: role.role_label }
      })
      options.push({ value: 'other', label: this.roleOther })
      return options
    },

    hasMultipleLanguages () {
      return config.TRANSLATIONS.AVAILABLE_LANGUAGES.length > 1
    }
  },

  watch: {
    cardForm: {
      handler (newCardForm) {
        this.$emit('update:card', newCardForm)
      },

      deep: true
    },

    hasUserSelected: {
      handler () {
        this.$emit('disable-submit', !this.hasUserSelected && !this.isUserOwner)
      },

      immediate: true
    },

    isNewUser () {
      this.$emit('is-new-user', this.isNewUser)
    },

    userSelected () {
      this.$emit('user-selected', this.userSelected)
    }
  },

  async mounted () {
    this.$bus.on('delete-affectation', this.onDeleteAffectation)
    this.roles = await this.userStore.getRoles()
  },

  beforeUnmount () {
    this.$bus.off('delete-affectation', this.onDeleteAffectation)
  },

  methods: {
    selectUser (user, isNewUser = false) {
      this.resetUser()

      this.userSelectorIsOpen = false
      this.isNewUser = isNewUser
      this.canEditPhone = isNewUser
      this.cardForm.picture = user?.picture || null

      if (!user) {
        this.cardForm.invitation.language = this.user.language
        return
      }

      this.canEditPhone = !user.accepted_at
      this.userSelected = user
      this.cardForm.user_uuid = user.uuid
      this.cardForm.invitation.email = user.email
      this.cardForm.invitation.employee_number = user.employee_number || null
      this.cardForm.invitation.affectations = user.affectations
      this.cardForm.invitation.language = user.language
      Object.assign(this.cardForm, user)
      this.userInput = `${user.first_name} ${user.last_name}`
      if (user.role) {
        this.userInput += ` - ${user.role}`
        this.cardForm.invitation.role = user.role
      }
      if (!this.cardForm.phone) {
        this.cardForm.phone = ''
      }
    },

    resetUser () {
      this.userSelected = null
      this.userInput = ''
      this.cardForm.first_name = ''
      this.cardForm.last_name = ''
      this.cardForm.phone = ''
      this.cardForm.user_uuid = null
      this.cardForm.invitation.role = 'employee'
      this.cardForm.invitation.email = ''
      this.cardForm.invitation.employee_number = ''
      this.cardForm.invitation.team_id = null
      this.cardForm.invitation.team_name = null
      this.cardForm.invitation.team_role = null
      this.cardForm.invitation.language = null
      this.cardForm.invitation.affectations = []
      this.isNewUser = false
    },

    resetInvitationRole () {
      this.cardForm.invitation.role = null
      this.cardForm.invitation.role_label = ''
    },

    userFilter (user) {
      let label = `${user.first_name} ${user.last_name}`
      if (user.role) {
        label += ` - ${user.role_label}`
      }
      return label
    },

    onRoleLabelInput () {
      const found = Object.entries(this.roles).find(el => el[1] === this.cardForm.invitation.role_label)
      if (found) {
        this.cardForm.invitation.role = found[0]
        this.cardForm.invitation.role_label = ''
      }
    },

    onAffectationsSelected (affectations) {
      this.cardForm.invitation.affectations = [...affectations]
    },

    async onNewAffectationAdded (itemLabel) {
      const newAffectation = await this.operationStore.addAffectation(itemLabel)
      if (newAffectation) {
        this.cardForm.invitation.affectations.push(newAffectation)
        this.$bus.emit('on-new-item-added', newAffectation)
      }
    },

    onDeleteAffectation (itemId) {
      const index = this.cardForm.invitation.affectations.findIndex(item => item.id === itemId)
      if (index !== -1) {
        this.cardForm.invitation.affectations.splice(index, 1)
      }
    },

    updateCardInvitation (object) {
      this.cardForm.invitation = { ...this.cardForm.invitation, ...object }
    },

    selectRole (role) {
      this.cardForm.invitation.role = role.value
      this.isRoleOther = role.value === 'other'
    },

    getRoleLabel (roleValue) {
      const roleOption = this.rolesOptions.find(option => option.value === roleValue)
      return roleOption ? roleOption.label : null
    }
  }
}
</script>
