import { defineStore } from 'pinia'

import axiosClient from '@/api'
import bus from '@/helpers/eventBus'
import { ModelOperation } from '@/models/Operation/ModelOperation'
import { IAffectation } from '@/types/affectation.d'
import { IOperationCategory } from '@/types/category.d'
import { ICurrency } from '@/types/currency.d'
import { IDetail, IOperation, TOperationTypes } from '@/types/operation.d'
import { Pagination } from '@/types/pagination.d'
import { IUserLight } from '@/types/user.d'

type Params = {
  page?: number
  mode?: string
}

interface State {
  operation: ModelOperation | null
  operations: Array<ModelOperation>
  operationsPagination: Pagination
  operationsTotalCredit: number
  operationsTotalDebit: number
  operationTypes: TOperationTypes | null
  operationAffectations: Array<IAffectation>
  operationCategories: Array<IOperationCategory>
  operationCategoriesCard: Array<IOperationCategory>
  incomingTransfers: Array<{
    amount: number
    created_at: number
    currency: ICurrency
    debtor_name: string
    label: string
    settlement_date: string
    status: string
    status_label: string
  }>
  incomingTransfersPagination: Pagination
  dashboard: {
    account_updated_at: number | null
    pending_transfers_count: number
    pending_operations_count: number
    message_count: number
    last_operations_positive?: ModelOperation[]
    last_operations_negative?: ModelOperation[]
    last_operations?: ModelOperation[]
    expenses_by_users: Array<{
      operations_amount: number
      operations_count: number
      user: IUserLight
    }>
    expenses_by_categories: {
      details: Array<{
        amount: number
        count: number
        name: string
        label: string
        color: string
      }>
      total: {
        amount: number
        count: number
      }
    }
  }
  processingAnalyze: boolean
}

export const useOperationStore = defineStore('operation', {
  state: (): State => {
    return {
      operation: null,
      operations: [],
      operationsPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      operationsTotalCredit: 0,
      operationsTotalDebit: 0,
      operationTypes: null,
      operationAffectations: [],
      operationCategories: [],
      operationCategoriesCard: [],
      incomingTransfers: [],
      incomingTransfersPagination: {
        current: 1,
        count: 1,
        perPage: 50,
        totalItems: 0
      },
      dashboard: {
        account_updated_at: null,
        pending_transfers_count: 0,
        pending_operations_count: 0,
        message_count: 0,
        last_operations_positive: [],
        last_operations_negative: [],
        last_operations: [],
        expenses_by_users: [],
        expenses_by_categories: {
          details: [],
          total: { amount: 0, count: 0 }
        }
      },
      processingAnalyze: false
    }
  },

  actions: {
    async getOperationCategories () {
      try {
        const { data } = await axiosClient.get('/categories')
        this.operationCategories = data
      } catch (e) {
        console.error(e)
      }

      try {
        const { data } = await axiosClient.get('/categories', { params: { filter: 'card' } })
        this.operationCategoriesCard = data
      } catch (e) {
        console.error(e)
      }
    },

    async getOperationAffectations () {
      try {
        const { data } = await axiosClient.get('/affectations')
        this.operationAffectations = data
      } catch (e) {
        console.error(e)
      }
    },

    async getOperationDetail (uuid: string) {
      try {
        const { data } = await axiosClient.get(`/operations/${uuid}`, { params: { expand: 'required_fields,documents_histories' } })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getOperationVATRates (uuid: string) {
      try {
        const { data } = await axiosClient.get(`/operations/${uuid}/vat`)
        return data
      } catch (e) {
        console.error(e)
        return []
      }
    },

    async getVATRates () {
      try {
        const { data } = await axiosClient.get('/vat')
        return data
      } catch (e) {
        console.error(e)
        return []
      }
    },

    async updateOperationDetails (uuid: string, details: { expense_date?: string | null, comment: string, affectations: Array<IAffectation>, detail: IDetail, attributions?: Array<IUserLight> }) {
      try {
        const { data } = await axiosClient.put(`/operations/${uuid}`, details, { params: { expand: 'required_fields,documents_histories' } })
        bus.emit('operation-update-success')
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getOperationTypes () {
      try {
        const { data } = await axiosClient.get('/list/operation/category')
        this.operationTypes = data
      } catch (e) {
        console.error(e)
      }
    },

    async analyzeOperationDocument (operation: { operationUuid: string, documentId: string }, abortSignal = null) {
      this.processingAnalyze = true
      try {
        const { data } = await axiosClient.get(`/operations/${operation.operationUuid}/documents/${operation.documentId}/analyze`, { signal: abortSignal })
        bus.emit('document-analyze-success', data)
      } catch (e) {
        console.error(e)
      } finally {
        this.processingAnalyze = false
      }
    },

    /**
     * @param operationId
     * @param {FileList} files
     */
    async sendDocuments (operationId: string, files: Blob[]) {
      const payload = new FormData()

      Array.from(files).forEach(file => payload.append('files[]', file))

      try {
        const { data } = await axiosClient.post(`/operations/${operationId}/documents`, payload)
        return data.documents
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async updateOperationCompliance (operation: { uuid: string, compliance: boolean, comment?: string }) {
      try {
        const { data } = await axiosClient.put(`/operations/${operation.uuid}/compliance`, { is_compliant: operation.compliance, reason: operation.comment })
        return data
      } catch (e) {
        console.error(e)
        return null
      }
    },

    async getNumberOfOperations (params = {}) {
      try {
        const { headers } = await axiosClient.head('/operations', { params })
        return parseInt(headers['x-total-operations-count'])
      } catch (e) {
        console.error(e)
        return 0
      }
    },

    async getOperations (page: number, params: Params = {}) {
      params.page = page
      params.mode = 'collection'

      try {
        const { data, headers } = await axiosClient.get('/operations', { params })
        const collection = this.parseCollection(data, headers)

        this.operations = collection.items.map((operation: IOperation) => ModelOperation.create(operation))
        this.operationsTotalCredit = collection.totalCredit
        this.operationsTotalDebit = collection.totalDebit
        this.operationsPagination = collection.pagination
      } catch (error) {
        console.error(error)
        this.operations = []
        this.operationsTotalCredit = 0
        this.operationsTotalDebit = 0
      }
    },

    async getClaimedOperations (page: number, params: Params = {}) {
      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/operations/claimed', { params })
        this.operationsPagination = this.parsePagination(headers)
        this.operations = data.map((operation: IOperation) => ModelOperation.create(operation))
      } catch (error) {
        console.error(error)
        this.operations = []
      }

      return this.operations
    },

    async updateOperationProof (uuid: string, action: string) {
      try {
        const { data } = await axiosClient.put(`/operations/${uuid}/document-management/${action}`)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getIncomingTransfers (page: number, params: Params = {}) {
      let transfers = []

      params.page = page

      try {
        const { data, headers } = await axiosClient.get('/incoming-transfers', { params })
        this.incomingTransfersPagination = this.parsePagination(headers)

        transfers = data
      } catch (error) {
        console.error(error)
      }
      this.incomingTransfers = transfers
    },

    // to move
    async addCategory (label: string) {
      try {
        const { data } = await axiosClient.post('/categories', { label })
        this.operationCategoriesCard.unshift(data)
        this.operationCategories.unshift(data)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async addAffectation (label: string) {
      try {
        const { data } = await axiosClient.post('/affectations', { label })
        this.operationAffectations.unshift(data)
        return data
      } catch (error) {
        console.error(error)
        return null
      }
    },

    async getOperationsCount (params = {}) {
      try {
        const { data } = await axiosClient.get('/operations/count', { params })
        return data
      } catch (error) {
        console.error(error)
        return { count: 0, proof_claimed_count: 0, export_delayed: false }
      }
    },

    async refreshDashboard (items = []) {
      const params = items.length
        ? { items }
        : null
      try {
        const { data } = await axiosClient.get('/dashboard', { params })
        this.dashboard = data
        this.dashboard.last_operations_positive = data.last_operations_positive?.map((operation: IOperation) => ModelOperation.create(operation))
        this.dashboard.last_operations_negative = data.last_operations_negative?.map((operation: IOperation) => ModelOperation.create(operation))
        this.dashboard.last_operations = data.last_operations?.map((operation: IOperation) => ModelOperation.create(operation))
      } catch (e) {
        console.error(e)
      }
    }
  }
})
