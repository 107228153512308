import { Model } from '@/models/Model'
import { IAffectation } from '@/types/affectation.d'
import { IBeneficiary } from '@/types/beneficiary.d'
import { ICard } from '@/types/card.d'
import { IOperationCategory } from '@/types/category.d'
import { ICurrency } from '@/types/currency.d'
import { Nullable } from '@/types/default.d'
import { EDocumentStatus, IDocument } from '@/types/document.d'
import { ECategory, EStatus, EType, ICategory, IDetail, IOperation } from '@/types/operation.d'
import { IUser, IUserCard } from '@/types/user.d'

export class ModelOperation extends Model {
  readonly uuid: string
  public accounting_date: Nullable<string>
  public affectations: Array<IAffectation>
  readonly amount: number
  public attributions: Array<IUserCard>
  readonly bank_reference: Nullable<string>
  readonly beneficiary: Nullable<IBeneficiary>
  readonly bill: Nullable<IDocument>
  readonly can_update_compliance: boolean
  public categories: Array<ICategory>
  readonly category: ECategory
  readonly category_label: string
  public comment: Nullable<string>
  readonly compliant_at: Nullable<number>
  readonly created_at: number
  readonly credit_card: Nullable<ICard>
  readonly currency?: Nullable<ICurrency>
  public detail: IDetail
  readonly document_required: boolean
  public documents: Array<IDocument>
  readonly documents_actions: Array<EDocumentStatus>
  readonly documents_status: EDocumentStatus
  readonly executed_at: number
  readonly expense_control: {
    control_at: Nullable<number>
    control_user: Nullable<IUserCard>
    status: string
    status_label: string
  }

  public expense_date: Nullable<string>
  readonly fx_amount: Nullable<number>
  readonly fx_currency: Nullable<number>
  readonly hint: Nullable<string>
  readonly is_compliant: Nullable<boolean>
  readonly is_pre_auth: boolean
  readonly label: string
  readonly lost_receipt_at: Nullable<number>
  readonly mcc_category: Nullable<IOperationCategory>
  readonly nb_documents: number
  readonly optional_receipt_at: Nullable<number>
  readonly permissions: Array<string>
  readonly proof_required_before: Nullable<boolean>
  public reject_reason: Nullable<string>
  readonly reliability: Nullable<object>
  readonly reliability_status: Nullable<string>
  public settlement_date: Nullable<string>
  readonly short_label: string
  readonly spender: boolean
  readonly status: EStatus
  readonly team: Nullable<object>
  readonly type: EType
  readonly user: Nullable<IUser>
  readonly value_date: Nullable<string>

  private constructor (properties: IOperation) {
    super()

    this.uuid = properties.uuid
    this.accounting_date = properties.accounting_date || null
    this.affectations = properties.affectations
    this.amount = properties.amount
    this.attributions = properties.attributions
    this.bank_reference = properties.bank_reference || null
    this.beneficiary = properties.beneficiary || null
    this.bill = properties.bill || null
    this.can_update_compliance = properties.can_update_compliance
    this.categories = properties.categories
    this.category = properties.category
    this.category_label = properties.category_label
    this.comment = properties.comment || null
    this.compliant_at = properties.compliant_at || null
    this.created_at = properties.created_at
    this.credit_card = properties.credit_card || null
    this.currency = properties?.currency || null
    this.detail = properties.detail
    this.document_required = properties.document_required
    this.documents = properties.documents
    this.documents_actions = properties.documents_actions
    this.documents_status = properties.documents_status
    this.executed_at = properties.executed_at
    this.expense_control = properties.expense_control
    this.expense_date = properties.expense_date || null
    this.fx_amount = properties.fx_amount || null
    this.fx_currency = properties.fx_currency || null
    this.hint = properties.hint || null
    this.is_compliant = properties.is_compliant || null
    this.is_pre_auth = properties.is_pre_auth
    this.label = properties.label
    this.lost_receipt_at = properties.lost_receipt_at || null
    this.mcc_category = properties.mcc_category || null
    this.nb_documents = properties.nb_documents
    this.optional_receipt_at = properties.optional_receipt_at || null
    this.permissions = properties.permissions
    this.proof_required_before = properties.proof_required_before || null
    this.reject_reason = properties.reject_reason || null
    this.reliability = properties.reliability || null
    this.reliability_status = properties.reliability_status || null
    this.settlement_date = properties.settlement_date || null
    this.short_label = properties.short_label
    this.spender = properties.spender
    this.status = properties.status
    this.team = properties.team || null
    this.type = properties.type
    this.user = properties.user || null
    this.value_date = properties.value_date || null

    return this.onConstructed()
  }

  static create (properties: IOperation): ModelOperation {
    return new ModelOperation(
      properties
    )
  }

  get hasDocuments (): boolean {
    return this.documents.length > 0
  }

  get isPending (): boolean {
    return this.status === EStatus.Pending
  }

  get isRejected (): boolean {
    return this.status === EStatus.Rejected
  }

  get isProofInvalid (): boolean {
    return this.documents_status === EDocumentStatus.Invalid
  }

  get isProofLost (): boolean {
    return this.documents_status === EDocumentStatus.Lost
  }

  get isProofOptional (): boolean {
    return this.documents_status === EDocumentStatus.Optional
  }
}
